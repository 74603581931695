import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api/api-config'
import { HTTP_METHODS } from 'api/constants'
import {
  IAppDownloadLinksResponse,
  IContacts,
  IPublicDocs,
  IPublicDocsInput,
  ISendFeedback,
  LicenseTypeResponse,
} from 'api/entities/app/models'
import { LicenseType } from 'modules/app/models'
import { normalizeLicenseTypes } from 'modules/app/utils'

export const appQuery = createApi({
  reducerPath: 'appQuery',
  baseQuery,
  endpoints: (build) => ({
    getCountries: build.query<string[], void>({
      query: () => ({ url: 'countries' }),
      transformResponse: (response: { scope: string[] }) => response.scope,
    }),

    getLicenseTypes: build.query<LicenseType[], void>({
      query: () => ({ url: 'admin/contracts/licenses/types' }),
      transformResponse: (response: { scope: { license_types: LicenseTypeResponse[] } }) => normalizeLicenseTypes(response.scope),
    }),

    getAppLinks: build.query<IAppDownloadLinksResponse[], void>({
      query: () => ({ url: 'apps/download-links' }),
      transformResponse: (response: { scope: IAppDownloadLinksResponse[] }) => response.scope,
    }),

    sendFeedback: build.mutation<ISendFeedback, ISendFeedback>({
      query: (body) => ({ url: 'public/feedback', method: HTTP_METHODS.POST, body }),
      transformResponse: (response: { scope: ISendFeedback }) => response.scope,
    }),
    getContacts: build.query<IContacts, void>({
      query: () => ({ url: 'public/contact' }),
      transformResponse: (response: { scope: IContacts }) => response.scope,
    }),
    getPublicDocs: build.query<IPublicDocs, IPublicDocsInput | void>({
      query: (params) => ({ url: '/public/contracts/public-offer', params: params ? params : {} }),
      transformResponse: (response: { scope: IPublicDocs }) => response.scope,
    }),
  }),
})

export const {
  useGetPublicDocsQuery,
  useLazyGetContactsQuery,
  useLazyGetPublicDocsQuery,
  useGetContactsQuery,
  useSendFeedbackMutation,
  useGetCountriesQuery,
  useLazyGetCountriesQuery,
  useGetAppLinksQuery,
  useLazyGetAppLinksQuery,
  useGetLicenseTypesQuery,
  useLazyGetLicenseTypesQuery,
} = appQuery
