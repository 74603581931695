import { IAccessTokenInput, IToken, ITokenRefreshResponse, ITokenResponse } from 'api'
import { ROLE } from 'api/entities/user'

export const normalizeToken = (data: ITokenResponse): IToken => {
  return {
    access: data.access_token,
    expiresTime: data.expires_in,
    refresh: data.refresh_token,
  }
}
export const normalizeRefreshTokenResponse = (data: ITokenRefreshResponse, refresh: string): IToken => {
  return {
    access: data.access_token,
    expiresTime: data.expires_in,
    refresh,
  }
}

export const createBasicToken = (cred: IAccessTokenInput) => {
  const { email, password } = cred
  return window.btoa(`${email}:${password}`)
}

export const getMyConsole = (portalAccess: boolean, orgAccess: boolean) => {
  if (portalAccess) {
    return ROLE.ADMIN
  }
  if (orgAccess) {
    return ROLE.ORG_ADMIN
  }

  return ROLE.USER
}
