import { Box, Link, Typography } from '@mui/material'
import { ROUTE } from 'modules/common/routes'
import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const ERR = TEXT.ERRORS[locale as Locale]

export const validationSchema = yup.object({
  email: yup.string().when('step', { is: 1, then: yup.string().required(ERR.required).email(ERR.invalidEmail) }),
  phone: yup.string().when('step', { is: 1, then: yup.string().required(ERR.required) }),

  password: yup.string().when('step',
    {
      is: 1,
      then: yup.string()
        .required(ERR.required)
        .min(8, ERR.min8Letters)
        .matches(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g, ERR.passwordRules),
    }),

  repeatPassword: yup.string().when('step',
    {
      is: 1,
      then: yup.string()
        .required(ERR.required)
        .oneOf([yup.ref('password'), null], ERR.doesNotMatch),
    }),

  name: yup.string().when('step', { is: 2, then: yup.string().required(ERR.required) }),
  surname: yup.string().when('step', { is: 2, then: yup.string().required(ERR.required) }),
  company: yup.string().when('step', { is: 2, then: yup.string().required(ERR.required) }),
  position: yup.string().when('step', { is: 2, then: yup.string().required(ERR.required) }),

  agree: yup.bool().when('step', { is: 2, then: yup.bool().oneOf([true], ERR.agreeIsRequired) }),
})

export const privacyTerms = (text: { [key: string]: string }) => {
  return (
    <Box display='flex' alignItems='center' gap='1ch' pt='2px'>
      <Typography variant='body1' color='inherit'>{text.agree}</Typography>
      <Link component={RouterLink} to={ROUTE.CONFIDENTIALITY} variant='body1' color='inherit' >
        {text.privacyTerms}
      </Link>
    </Box>
  )
}
