import { Box, Typography } from '@mui/material'
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers'
import { FormikErrors } from 'formik'
import { DateTime } from 'luxon'
import { TextField } from 'modules/common/components/TextField'
import React, { FC, FocusEvent } from 'react'

export type DatePickerProps =
  Partial<DesktopDatePickerProps<any, any>>
  & {
  labelColor?: string | 'primary' | 'secondary',
  labelWeight?: number | 'normal',
  labelVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption',
  fullWidth?: boolean,
  name: string,
  onDateChange: (field: string, value: DateTime | null) => void
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  error?: boolean
  helperText?: string | false | FormikErrors<DateTime>
}

export const DATE_FORMAT = 'dd/MM/yyyy'

export const DatePicker: FC<DatePickerProps> = ({
  label,
  value,
  labelColor,
  labelWeight,
  fullWidth,
  name,
  error,
  onDateChange,
  helperText,
  labelVariant,
  onBlur,
  ...props
}) => {
  return (
    <Box width={fullWidth ? '100%' : 'auto'} height='auto'>
      <Typography
        hidden={!label}
        mb='5px'
        color={labelColor || 'secondary'}
        variant={labelVariant ?? 'body2'}
        fontWeight={labelWeight}
      >
        {label}
      </Typography>

      <DesktopDatePicker
        {...props}
        value={value}
        inputFormat={DATE_FORMAT}
        renderInput={(params) => {
          return (
            <TextField error={error} helperText={helperText} fullWidth={fullWidth} onBlur={onBlur}{...params} />
          )
        }}
        onChange={(date) => onDateChange(name, date as DateTime | null)}
      />
    </Box>
  )
}
