import { CLASSIFIER_TYPES } from 'modules/classifiers/constants'
import { LOCALE } from '../constants'

export const CLASSIFIERS = {
  [LOCALE.RUSSIAN]: {
    classifiers: 'Классификаторы',
    classifier: 'Классификатор',
    [CLASSIFIER_TYPES.WORLD]: 'Международные',
    [CLASSIFIER_TYPES.COUNTRY]: 'По странам',
    [CLASSIFIER_TYPES.CORPORATE]: 'Корпоративные',
    [CLASSIFIER_TYPES.PERSONAL]: 'Персональные',
    [CLASSIFIER_TYPES.SHARED]: 'Поделились со мной',
    classifiersDataBase: 'База классификаторов',
    userMemo: 'Памятка пользователя',
    classifierId: 'Id классификатора',
    version: 'Версия',
    updated: 'Обновлено:',
    recognizesClasses: 'ИИ распознает классов:',
    add: 'Добавить',
    addClassifier: 'Добавить классификатор',
    typeGroup: 'Раздел',
    route: 'Перейти',
    noSearchResults: 'К сожалению по Вашему запросу не найдено результатов. Попробуйте изменить параметры поиска',
    successFileUploaded: 'Файл успешно загружен',
    uploadError: 'Ой, не удалось загрузить файл :(',
    upload: 'Загрузить',
    refuseSharing: 'Отказаться от доступа',

    actions: 'Действия',
    changeName: 'Изменить название',
    share: 'Поделиться',
    delete: 'Удалить',
    download: 'Скачать',
    copy: 'Копировать',
    update: 'Обновить',
    change: 'Изменить',
    successCopy: 'Классификатор успешно скопирован в раздел "Персональные"',
    user: 'Пользователь',
    company: 'Компания',
    noSharedUsers: 'Вы еще ни с кем не делились этим классификатором',
    getSharedError: 'К сожалению, не удалось загрузить список пользователей с кем Вы поделились данным классификатором',

    tableCode: 'Код таблицы',
    access: 'Доступ',
    name: 'Название',
    prepared: 'ИИ подготовлен',
    classifierName: 'Название классификатора',
    save: 'Сохранить',
    cancel: 'Отмена',
    deleteClassifier: 'Удалить классификатор',
    updateClassifier: 'Обновить классификатор',
    assignDeleting: 'Подтвердите удаление классификатора:',
    shareClassifier: 'Поделиться классификатором',
    userEmail: 'E-mail пользователя',
    userEmailOrId: 'E-mail или Id пользователя (организации)',
    readOnly: 'Просмотр',
    using: 'использование',
    accessRules: 'Укажите уровень доступа :',
    changeOwner: 'Изменить владельца классификатора ',
    inTable: 'Внутри таблицы',
    inBase: 'По всей базе',
    searchResults: 'Результаты поиска',
    code: 'Код',

    noCountriesMessage: 'Список стран пуст',
    countriesErrorMessage: 'Не удалось загрузить список стран',
    updateDate: 'Дата обновления',
    parent_group: 'Название группы классификаторов',
    codes_info_ref: 'Ссылка на стандарт и/или описание классификатора',
    description_parameter_name: 'Имя параметра для описания',
    code_parameter_name: 'Имя параметра кода',
    country: 'Страна',
    changeOwnerSuccess: 'Владелец классификатора успешно изменен',
    refuseClassifier: 'Отказаться от доступа к предоставленному классификатору',
    assignRefuse: 'Вам дали доступ к классификатору, вы действительно хотите отказаться от него?',
    refuse: 'Отказаться',

    userMemoText: `Слева представлены классификаторы и классификационные таблицы структутрированы по их принадлежности к странам/пользователям. Вы можете выполнить поиск по классификаторам в поисковом окне сверху, используя в том числе и идентификаторы организаций. Здесь вы не найдете персональные классификаторы пользователей. Более подробно об администрировании читайте на портале notion.io`,
  },
  [LOCALE.ENGLISH]: {
    classifiers: 'Классификаторы',
    classifier: 'Классификатор',
    [CLASSIFIER_TYPES.WORLD]: 'Международные',
    [CLASSIFIER_TYPES.COUNTRY]: 'По странам',
    [CLASSIFIER_TYPES.CORPORATE]: 'Корпоративные',
    [CLASSIFIER_TYPES.PERSONAL]: 'Персональные',
    [CLASSIFIER_TYPES.SHARED]: 'Поделились со мной',
    classifiersDataBase: 'База классификаторов',
    userMemo: 'Памятка пользователя',
    classifierId: 'Id классификатора',
    version: 'Версия',
    updated: 'Обновлено:',
    recognizesClasses: 'ИИ распознает классов:',
    add: 'Добавить',
    addClassifier: 'Добавить классификатор',
    typeGroup: 'Раздел',
    route: 'Перейти',
    noSearchResults: 'К сожалению по Вашему запросу не найдено результатов. Попробуйте изменить параметры поиска',
    successFileUploaded: 'Файл успешно загружен',
    uploadError: 'Ой, не удалось загрузить файл :(',
    upload: 'Загрузить',
    refuseSharing: 'Отказаться от доступа',

    actions: 'Действия',
    changeName: 'Изменить название',
    share: 'Поделиться',
    delete: 'Удалить',
    download: 'Скачать',
    copy: 'Копировать',
    update: 'Обновить',
    change: 'Изменить',
    successCopy: 'Классификатор успешно скопирован в раздел "Персональные"',
    user: 'Пользователь',
    company: 'Компания',
    noSharedUsers: 'Вы еще ни с кем не делились этим классификатором',
    getSharedError: 'К сожалению, не удалось загрузить список пользователей с кем Вы поделились данным классификатором',

    tableCode: 'Код таблицы',
    access: 'Доступ',
    name: 'Название',
    prepared: 'ИИ подготовлен',
    classifierName: 'Название классификатора',
    save: 'Сохранить',
    cancel: 'Отмена',
    deleteClassifier: 'Удалить классификатор',
    updateClassifier: 'Обновить классификатор',
    assignDeleting: 'Подтвердите удаление классификатора:',
    shareClassifier: 'Поделиться классификатором',
    userEmail: 'E-mail пользователя',
    userEmailOrId: 'E-mail или Id пользователя (организации)',
    readOnly: 'Просмотр',
    using: 'использование',
    accessRules: 'Укажите уровень доступа :',
    changeOwner: 'Изменить владельца классификатора ',
    inTable: 'Внутри таблицы',
    inBase: 'По всей базе',
    searchResults: 'Результаты поиска',
    code: 'Код',

    noCountriesMessage: 'Список стран пуст',
    countriesErrorMessage: 'Не удалось загрузить список стран',
    updateDate: 'Дата обновления',
    parent_group: 'Название группы классификаторов',
    codes_info_ref: 'Ссылка на стандарт и/или описание классификатора',
    description_parameter_name: 'Имя параметра для описания',
    code_parameter_name: 'Имя параметра кода',
    country: 'Страна',
    changeOwnerSuccess: 'Владелец классификатора успешно изменен',
    refuseClassifier: 'Отказаться от доступа к предоставленному классификатору',
    assignRefuse: 'Вам дали доступ к классификатору, вы действительно хотите отказаться от него?',
    refuse: 'Отказаться',

    userMemoText: `Слева представлены классификаторы и классификационные таблицы структутрированы по их принадлежности к странам/пользователям. Вы можете выполнить поиск по классификаторам в поисковом окне сверху, используя в том числе и идентификаторы организаций. Здесь вы не найдете персональные классификаторы пользователей. Более подробно об администрировании читайте на портале notion.io`,
  },
  [LOCALE.KAZAKH]: {
    classifiers: 'Классификаторы',
    classifier: 'Классификатор',
    [CLASSIFIER_TYPES.WORLD]: 'Международные',
    [CLASSIFIER_TYPES.COUNTRY]: 'По странам',
    [CLASSIFIER_TYPES.CORPORATE]: 'Корпоративные',
    [CLASSIFIER_TYPES.PERSONAL]: 'Персональные',
    [CLASSIFIER_TYPES.SHARED]: 'Поделились со мной',
    classifiersDataBase: 'База классификаторов',
    userMemo: 'Памятка пользователя',
    classifierId: 'Id классификатора',
    version: 'Версия',
    updated: 'Обновлено:',
    recognizesClasses: 'ИИ распознает классов:',
    add: 'Добавить',
    addClassifier: 'Добавить классификатор',
    typeGroup: 'Раздел',
    route: 'Перейти',
    noSearchResults: 'К сожалению по Вашему запросу не найдено результатов. Попробуйте изменить параметры поиска',
    successFileUploaded: 'Файл успешно загружен',
    uploadError: 'Ой, не удалось загрузить файл :(',
    upload: 'Загрузить',
    refuseSharing: 'Отказаться от доступа',

    actions: 'Действия',
    changeName: 'Изменить название',
    share: 'Поделиться',
    delete: 'Удалить',
    download: 'Скачать',
    copy: 'Копировать',
    update: 'Обновить',
    change: 'Изменить',
    successCopy: 'Классификатор успешно скопирован в раздел "Персональные"',
    user: 'Пользователь',
    company: 'Компания',
    noSharedUsers: 'Вы еще ни с кем не делились этим классификатором',
    getSharedError: 'К сожалению, не удалось загрузить список пользователей с кем Вы поделились данным классификатором',

    tableCode: 'Код таблицы',
    access: 'Доступ',
    name: 'Название',
    prepared: 'ИИ подготовлен',
    classifierName: 'Название классификатора',
    save: 'Сохранить',
    cancel: 'Отмена',
    deleteClassifier: 'Удалить классификатор',
    updateClassifier: 'Обновить классификатор',
    assignDeleting: 'Подтвердите удаление классификатора:',
    shareClassifier: 'Поделиться классификатором',
    userEmail: 'E-mail пользователя',
    userEmailOrId: 'E-mail или Id пользователя (организации)',
    readOnly: 'Просмотр',
    using: 'использование',
    accessRules: 'Укажите уровень доступа :',
    changeOwner: 'Изменить владельца классификатора ',
    inTable: 'Внутри таблицы',
    inBase: 'По всей базе',
    searchResults: 'Результаты поиска',
    code: 'Код',

    noCountriesMessage: 'Список стран пуст',
    countriesErrorMessage: 'Не удалось загрузить список стран',
    updateDate: 'Дата обновления',
    parent_group: 'Название группы классификаторов',
    codes_info_ref: 'Ссылка на стандарт и/или описание классификатора',
    description_parameter_name: 'Имя параметра для описания',
    code_parameter_name: 'Имя параметра кода',
    country: 'Страна',
    changeOwnerSuccess: 'Владелец классификатора успешно изменен',
    refuseClassifier: 'Отказаться от доступа к предоставленному классификатору',
    assignRefuse: 'Вам дали доступ к классификатору, вы действительно хотите отказаться от него?',
    refuse: 'Отказаться',

    userMemoText: `Слева представлены классификаторы и классификационные таблицы структутрированы по их принадлежности к странам/пользователям. Вы можете выполнить поиск по классификаторам в поисковом окне сверху, используя в том числе и идентификаторы организаций. Здесь вы не найдете персональные классификаторы пользователей. Более подробно об администрировании читайте на портале notion.io`,
  },
}

