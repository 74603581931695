import { IUpdatePasswordInput, IUpdateUserInput, IUserResponse } from 'api/entities/user'
import { IUser, UpdatePasswordValues, UpdateProfileValues } from 'modules/me/models'

export const NormalizeUserInfo = (data: IUserResponse): IUser => {
  return {
    id: data.user.user_id,
    name: data.user.contact_info.name,
    surname: data.user.contact_info.surname,
    phone: data.user.contact_info.phone_number,
    position: data.user.position,
    role: data.user.role,
    email: data.email_login,
    photo: null,

    organizationId: data.organisation.organization_id,
    organizationCountries: data.organisation.organization_countries,
    organizationName: data.organisation.organization_name,
    organizationAdmins: data.organisation.company_admins.map(user => ({ ...user, id: user.user_id })),

    timestamp: data.timestamp, // 1634159277.7914057

    accessRights: {
      classificationRequestAccess: data.access_rights.classification_request_access,
      personalWriteAccess: data.access_rights.personal_write_access,
      globalWriteAccess: data.access_rights.global_write_access,
      organizationWriteAccess: data.access_rights.organization_write_access,
      organizationConsoleAccess: data.access_rights.organization_console_access,
      portalConsoleAccess: data.access_rights.portal_console_access,
    },
  }
}

export const updateProfileValuesNormalize = (body: UpdateProfileValues): IUpdateUserInput => {
  return {
    contact_info: {
      surname: body.surname,
      name: body.name,
      phone_number: body.phone,
    },
    organisation: {
      organization_countries: body.organizationCountries,
      organization_name: body.organizationName,
    },
    position: body.position,
  }
}

export const updatePasswordValuesNormalize = (body: UpdatePasswordValues): IUpdatePasswordInput => {
  const { currentPassword, password } = body
  return {
    current_password: currentPassword,
    new_password: password,
  }
}
