import { Box, styled } from '@mui/material'
import { useGetClassifiersListQuery } from 'api/entities/classifer'
import {
  ClassifiersNav,
  ClassifiersSearch,
  ClassifiersSearchResults,
  ClassifiersTable,
  ClassifiersUserMemo,
} from 'modules/classifiers'
import { ClassifierType } from 'modules/classifiers/models'
import { clearOpenedItems } from 'modules/classifiers/slice'
import { TableSkeleton } from 'modules/common/components'
import { SEARCH_PARAM } from 'pages/classifiers/constants'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

export const ClassifiersPage: FC = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const { group, type, country, search, owner } = useMemo(() => ({
    group: searchParams.get(SEARCH_PARAM.GROUP),
    type: searchParams.get(SEARCH_PARAM.TYPE) as ClassifierType,
    country: searchParams.get(SEARCH_PARAM.COUNTRY),
    search: searchParams.get(SEARCH_PARAM.SEARCH),
    owner: searchParams.get(SEARCH_PARAM.OWNER),
  }), [searchParams])
  const { data, isLoading } = useGetClassifiersListQuery()
  const dispatch = useDispatch()

  const navParams = useMemo(() => ({ id, group, type, country, owner }), [country, group, id, type, owner])

  useEffect(() => {
    return () => {
      dispatch(clearOpenedItems)
    }
  }, [dispatch])

  return (
    <Wrapper>
      <div>
        <ClassifiersNav data={data} isLoading={isLoading} params={navParams} />
      </div>

      <Box display='flex' flexDirection='column' rowGap='40px'>
        <ClassifiersSearch id={id} />
        {search
          ? <ClassifiersSearchResults search={search} navData={data} />
          : (
            <>
              {!group
                ? <ClassifiersUserMemo />
                : (
                  <>
                    {isLoading ? <TableSkeleton columns={4} rows={4} /> : <ClassifiersTable group={group} type={type} />}
                  </>
                )
              }
            </>
          )
        }

      </Box>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  padding: 80px 100px;
  display: grid;
  grid-column-gap: 80px;
  grid-template-columns: 305px 1fr;
  grid-auto-rows: min-content;
`
