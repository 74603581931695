import { CssBaseline, ThemeProvider } from '@mui/material'
import { App } from 'App'
import { Verification } from 'modules/auth/components'
import { ROUTE } from 'modules/common/routes'
import { NotistackProvider } from 'modules/errorHandler/Notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { store } from 'store/store'
import { theme } from 'styles/theme'
import './index.css'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <NotistackProvider>
        <BrowserRouter>
          <CssBaseline />
          <Routes>
            <Route path={ROUTE.VERIFICATION} element={<Verification />} />
            <Route path='*' element={<App />} />
          </Routes>
        </BrowserRouter>
      </NotistackProvider>
    </ThemeProvider>
  </Provider>
  ,
  document.getElementById('root'),
)
