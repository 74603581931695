import { Box, Dialog, IconButton, styled, Typography } from '@mui/material'
import { useCreateOrganizationMutation } from 'api'
import { CloseIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { Button, PhoneField, TextField } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useEffect, useState } from 'react'
import { initialValues, validationSchema } from './constants'

export const AddOrganization: FC = () => {
  const [open, setOpen] = useState(false)
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [createOrganization, { isLoading, isSuccess, error }] = useCreateOrganizationMutation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.organizationAddedSuccess, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      handleClose()
    }
  }, [isSuccess, closeSnackbar, enqueueSnackbar, text.organizationAddedSuccess])
  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await createOrganization(values).unwrap()
    },
  })
  const { handleSubmit, values, setFieldValue, handleChange, handleBlur, touched, errors } = formik
  const handlePhoneChange = (value: any) => setFieldValue('phone', value)

  return (
    <>
      <Button sx={{ px: '40px' }} variant='outlined' onClick={handleOpen}>{text.add}</Button>

      <Dialog open={open} onClose={isLoading ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={isLoading} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography variant='h2'>{text.editContract}</Typography>

        <Box component='form' mt='20px' onSubmit={handleSubmit}>

          <TextField
            name='name'
            value={values.name}
            label={text.organizationName}
            fullWidth
            disabled={isLoading}
            error={touched.name && !!errors.name}
            helperText={(touched.name && errors.name) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <TextField
            name='address'
            value={values.address}
            label={text.address}
            fullWidth
            disabled={isLoading}
            error={touched.address && !!errors.address}
            helperText={(touched.address && errors.address) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <TextField
            name='email'
            value={values.email}
            label={text.email}
            fullWidth
            disabled={isLoading}
            error={touched.email && !!errors.email}
            helperText={(touched.email && errors.email) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <PhoneField
            fullWidth
            name='phone'
            value={values.phone}
            label={text.phone}
            disabled={isLoading}
            error={touched.phone && !!errors.phone}
            helperText={(touched.phone && errors.phone) ?? ' '}
            variant='outlined'
            onChange={handlePhoneChange}
            onBlur={handleBlur}
          />

          <TextField
            name='comment'
            value={values.comment}
            label={text.comment}
            fullWidth
            disabled={isLoading}
            error={touched.comment && !!errors.comment}
            helperText={(touched.comment && errors.comment) ?? ' '}
            multiline
            rows={5}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Box display='flex' gap='20px' mt='20px'>
            <Button fullWidth loading={isLoading} variant='contained' type='submit'>{text.add}</Button>
            <Button fullWidth disabled={isLoading} variant='outlined' onClick={handleClose}>{text.cancel}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
