import { AuthCover } from 'assets/img'
import { Tagline } from 'assets/svg'
import { HEIGHT } from 'modules/common/constants'
import React, { FC } from 'react'
import styled from 'styled-components'

export const Cover: FC = () => {
  return (
    <Wrapper>
      <Container>
        <Image />
        <TagString />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-width: 50%;
  min-height: 650px;
  background: #00042A;
  height: calc(100vh - ${HEIGHT.FOOTER});
  padding-top: 70px;
`

const Container = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Image = styled.div`
  background: url('${AuthCover}') top no-repeat;
  background-size: contain;
  height: 500px;
  width: 700px;
`

const TagString = styled(Tagline)`
  margin-top: -30px;
`
