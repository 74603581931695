export const USER_TABS = {
  MAIN: 'main',
  LICENSES: 'licenses',
  CLASSIFIERS: 'classifiers',
  KNOWLEDGE_BASE: 'knowledgeBase',
}
export const ADMIN_TABS = {
  MAIN: 'main',
  CLIENTS: 'clients',
  CLASSIFIERS: 'classifiers',
}

export const RIGHT_TABS = {
  PROFILE: 'profile',
} as const

export const KNOWLEDGE_LINK = 'https://im-pulse.notion.site'
