import { HTTP_METHODS } from 'api/constants'

export const fileApi = {
  uploadS3File: async (url: string, data: FormData) => {
    let res: Response | null

    try {
      res = await fetch(url, { method: HTTP_METHODS.POST, body: data })
    } catch (e) {
      res = null
      console.error(e)
    }

    return res?.ok
  },

  deleteS3File: async (url: string) => {
    let res: Response | null

    try {
      res = await fetch(url, { method: HTTP_METHODS.DELETE })
    } catch (e) {
      res = null
      console.error(e)
    }

    return res?.ok
  },
}
