import { Box, Paper, Typography } from '@mui/material'
import { ILicensesStatisticResponse } from 'api'
import { useText } from 'modules/locale'
import React, { FC } from 'react'

type Props = { statistic: ILicensesStatisticResponse | undefined }

export const LicensesStatisticCard: FC<Props> = ({ statistic }) => {
  const { text } = useText('USER')

  return (
    <Paper elevation={1} sx={{ p: '20px', width: '500px' }}>
      <Typography variant='h2'>{text.licensesUsage}</Typography>

      <Box mt='20px' display='grid' gridTemplateColumns='1fr 1fr'>
        <div>
          <Box display='flex' alignItems='center' gap='10px'>
            <Typography variant='body1' color='secondary'>{text.availableForAppointment}</Typography>
            <Typography variant='body1' color='secondary' fontWeight={500}>
              {statistic?.free_licenses_for_attachment ?? ''}
            </Typography>
          </Box>

          <Box pt='10px' display='flex' alignItems='center' gap='10px'>
            <Typography variant='body2' color='secondary'>{text.full}</Typography>
            <Typography variant='body2'>
              {statistic ? (statistic.total_commercial_lics_count - statistic.commercial_lics_in_use) : ''}
            </Typography>
          </Box>

          <Box pt='5px' display='flex' alignItems='center' gap='10px'>
            <Typography variant='body2' color='secondary'>{text.trial}</Typography>
            <Typography variant='body2'>
              {statistic ? (statistic.total_evaluation_lics_count - statistic.evaluation_lics_in_use) : ''}
            </Typography>
          </Box>
        </div>

        <div>
          <Box display='flex' alignItems='center' gap='10px'>
            <Typography variant='body1' color='secondary'>{text.inUse}</Typography>
            <Typography variant='body1' color='secondary' fontWeight={500}>
              {statistic?.licenses_in_use ?? ''}
            </Typography>
          </Box>

          <Box pt='10px' display='flex' alignItems='center' gap='10px'>
            <Typography variant='body2' color='secondary'>{text.full}</Typography>
            <Typography variant='body2'>
              {statistic?.commercial_lics_in_use ?? ''}
            </Typography>
          </Box>

          <Box pt='5px' display='flex' alignItems='center' gap='10px'>
            <Typography variant='body2' color='secondary'>{text.trial}</Typography>
            <Typography variant='body2'>
              {statistic?.evaluation_lics_in_use ?? ''}
            </Typography>
          </Box>
        </div>

      </Box>
    </Paper>
  )
}
