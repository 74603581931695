import { Box, CircularProgress, IconButton, styled, Tooltip } from '@mui/material'
import { useLazyGetClassifierLinksQuery, useMakeClassifierCopyMutation } from 'api'
import { CopyIcon, DownloadIcon } from 'assets/svg'
import { useMessage } from 'modules/errorHandler/useMessage'
import { useText } from 'modules/locale'
import React, { FC, useRef } from 'react'
import { UploadActionButton } from './UploadActionButton'

type Props = { id: string, reFetch: () => void }

export const ClassifierTableActions: FC<Props> = ({ id, reFetch }) => {
  const { text } = useText('CLASSIFIERS')
  const ref = useRef<HTMLAnchorElement>(null)
  const [makeCopy, { isLoading: isCopying, isSuccess }] = useMakeClassifierCopyMutation()
  const [getLinks, { isLoading }] = useLazyGetClassifierLinksQuery()
  const handleDownload = async () => {
    await getLinks({ id }).unwrap()
      .then(({ download_tree_link, download_template_tree_link }) => {
        ref.current && (ref.current.href = download_tree_link || download_template_tree_link)
      })
      .then(() => ref?.current?.click())
  }
  const handleCopy = async () => await makeCopy({ id }).unwrap()

  useMessage(isSuccess && text.successCopy)

  return (
    <>
      <Box display='flex' columnGap='4px'>
        <Tooltip title={text.download}>
          <IconButton size='small' onClick={handleDownload}>
            {isLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
          </IconButton>
        </Tooltip>

        <UploadActionButton id={id} reFetch={reFetch} />

        <Tooltip title={text.copy}>
          <IconButton size='small' onClick={handleCopy}>
            {isCopying ? <CircularProgress size={24} /> : <CopyIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      <HiddenLink ref={ref} download />
    </>
  )
}
const HiddenLink = styled('a')`
  height: 0;
  width: 0;
`
