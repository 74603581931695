import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { userApi } from 'api'
import { ERRORS, SUCCESS } from 'modules/me/constants'
import { UpdatePasswordValues, UpdateProfileValues } from 'modules/me/models'
import { NormalizeUserInfo, updatePasswordValuesNormalize, updateProfileValuesNormalize } from 'modules/me/utils'

/**
 *
 * Получение информации о пользователе
 */
export const getUser = createAsyncThunk(
  'me/getUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userApi.requestUser()
      if (response) {
        sessionStorage.setItem('userId', response.user.user_id)
        return NormalizeUserInfo(response)
      }

      return false
    } catch (e) {
      const status = Object.getOwnPropertyDescriptor(e, 'status')?.value
      console.error(e)
      if (status === 403) {
        return rejectWithValue('Ошибка входа')
      }

      return rejectWithValue('Упс что-то пошло не так')
    }
  },
)

/**
 *
 * обновление основной информации в профиле пользователя
 */
export const updateUserProfile = createAsyncThunk(
  'me/updateUserProfile',
  async (body: UpdateProfileValues, { rejectWithValue }) => {
    try {
      const response = await userApi.updateUser(updateProfileValuesNormalize(body))
      if (response) {
        return body
      }

      return false
    } catch (e) {
      const message = Object.getOwnPropertyDescriptor(e, 'message')?.value
      console.error(e)
      return rejectWithValue(message)
    }
  },
)

/**
 *
 * обновление пароля пользователя
 */
export const updatePassword = createAsyncThunk(
  'me/updatePassword',
  async (body: UpdatePasswordValues, { rejectWithValue }) => {
    try {
      const response = await userApi.updatePassword(updatePasswordValuesNormalize(body))
      return !!response
    } catch (e) {
      const message = Object.getOwnPropertyDescriptor(e, 'message')?.value
      console.error(e)
      return rejectWithValue(message)
    }
  },
)

export const clearSuccessField = createAction<typeof SUCCESS[keyof typeof SUCCESS]>('me/clearSuccessField')
export const clearErrorField = createAction<typeof ERRORS[keyof typeof ERRORS]>('me/clearErrorField')
export const updateEmailAction = createAction<string>('me/updateEmailAction')
export const clearMe = createAction('me/clearMe')

