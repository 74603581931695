import { LOCALE } from '../constants'

export const AUTH = {
  [LOCALE.RUSSIAN]: {
    title: 'Вход в IMPulse',
    description: 'Введите e-mail и пароль. В случае отстутсвия аккаунта необходимо зарегистрироваться.',
    login: 'E-mail',
    password: 'Пароль',
    repeatPassword: 'Повторите пароль',
    remember: 'Запомнить меня',
    forgotPassword: 'Забыли пароль?',
    signIn: 'Вход',
    signUp: 'Регистрация',
    name: 'Имя',
    surname: 'Фамилия',
    createAccount: 'Создать аккаунт',
    backToMain: 'Вернуться на главную',
    backToSignUp: 'Вернуться на форму регистрации',
    agree: 'Я принимаю',
    privacyTerms: 'условия конфиденциальности',
    allFieldsRequired: 'Все поля обязательны для заполнения.',
    restoreDescription: 'Для получения инструкции для восстановления пароля, введите e-mail, на который зарегистрирован Ваш аккаунт.',
    restore: 'Восстановить',
    sendAgain: 'Отправить повторно',
    timerMessage: 'Выслать код повторно можно через ',
    manualForRestore: 'На указанный e-mail была направлена инструкция для восстановления пароля.',
    manualForSignUp: 'На указанный e-mail была направлена ссылка для подтверждения регистрации. Пройдите по ней, чтобы войти в аккаунт.',
    contactSupport: 'Связаться с техподдержкой',
    manualResent: 'Инструкция направлена повторно.',
    manualNotCame: 'Не пришло письмо с инструкцией?',
    linkResent: 'Ссылка направлена повторно.',
    emailWithLinkNotCame: 'Не пришло письмо со ссылкой?',
    closeToTarget: 'Вы почти у цели!',
    successVerified: 'Ваша почта успешно подтверждена',
    putNewPassword: 'Введите новый пароль',
    newPasswordDesc: 'Для восстановления доступа к IMPulse придумайте новый пароль.',
    changePassword: 'Изменить пароль',
    min8Sym: 'Минимум 8 символов',
    upCaseContains: 'Содержит заглавную букву',
    numContains: 'Содержит цифру',
    newPassword: 'Новый пароль',
    step: 'Шаг',
    position: 'Должность',
    company: 'Компания',
    next: 'Далее',
    phone: 'Номер телефона',
    back: 'Назад',
    successEntryToCompany: 'Вы успешно втупили в компанию по приглашению',
    successRegistered: 'Вы успешно зарегистрированы',
    invitationIsExpired: 'Выше приглашение устарело, запросите новое приглашение у администратора организации',
  },
  [LOCALE.ENGLISH]: {
    title: 'Вход в IMPulse',
    description: 'Введите e-mail и пароль. В случае отстутсвия аккаунта необходимо зарегистрироваться.',
    login: 'E-mail',
    password: 'Пароль',
    repeatPassword: 'Повторите пароль',
    remember: 'Запомнить меня',
    forgotPassword: 'Забыли пароль?',
    signIn: 'Вход',
    signUp: 'Регистрация',
    name: 'Имя',
    surname: 'Фамилия',
    createAccount: 'Создать аккаунт',
    backToMain: 'Вернуться на главную',
    backToSignUp: 'Вернуться на форму регистрации',
    agree: 'Я принимаю',
    privacyTerms: 'условия конфиденциальности',
    allFieldsRequired: 'Все поля обязательны для заполнения.',
    restoreDescription: 'Для получения инструкции для восстановления пароля, введите e-mail, на который зарегистрирован Ваш аккаунт.',
    restore: 'Восстановить',
    sendAgain: 'Отправить повторно',
    timerMessage: 'Выслать код повторно можно через ',
    manualForRestore: 'На указанный e-mail была направлена инструкция для восстановления пароля.',
    manualForSignUp: 'На указанный e-mail была направлена ссылка для подтверждения регистрации. Пройдите по ней, чтобы войти в аккаунт.',
    contactSupport: 'Связаться с техподдержкой',
    manualResent: 'Инструкция направлена повторно.',
    manualNotCame: 'Не пришло письмо с инструкцией?',
    linkResent: 'Ссылка направлена повторно.',
    emailWithLinkNotCame: 'Не пришло письмо со ссылкой?',
    closeToTarget: 'Вы почти у цели!',
    successVerified: 'Ваша почта успешно подтверждена',
    putNewPassword: 'Введите новый пароль',
    newPasswordDesc: 'Для восстановления доступа к IMPulse придумайте новый пароль.',
    changePassword: 'Изменить пароль',
    min8Sym: 'Минимум 8 символов',
    upCaseContains: 'Содержит заглавную букву',
    numContains: 'Содержит цифру',
    newPassword: 'Новый пароль',
    step: 'Шаг',
    position: 'Должность',
    company: 'Компания',
    next: 'Далее',
    phone: 'Номер телефона',
    back: 'Назад',
    successEntryToCompany: 'Вы успешно втупили в компанию по приглашению',
    successRegistered: 'Вы успешно зарегистрированы',
    invitationIsExpired: 'Выше приглашение устарело, запросите новое приглашение у администратора организации',
  },
  [LOCALE.KAZAKH]: {
    title: 'Вход в IMPulse',
    description: 'Введите e-mail и пароль. В случае отстутсвия аккаунта необходимо зарегистрироваться.',
    login: 'E-mail',
    password: 'Пароль',
    repeatPassword: 'Повторите пароль',
    remember: 'Запомнить меня',
    forgotPassword: 'Забыли пароль?',
    signIn: 'Вход',
    signUp: 'Регистрация',
    name: 'Имя',
    surname: 'Фамилия',
    createAccount: 'Создать аккаунт',
    backToMain: 'Вернуться на главную',
    backToSignUp: 'Вернуться на форму регистрации',
    agree: 'Я принимаю',
    privacyTerms: 'условия конфиденциальности',
    allFieldsRequired: 'Все поля обязательны для заполнения.',
    restoreDescription: 'Для получения инструкции для восстановления пароля, введите e-mail, на который зарегистрирован Ваш аккаунт.',
    restore: 'Восстановить',
    sendAgain: 'Отправить повторно',
    timerMessage: 'Выслать код повторно можно через ',
    manualForRestore: 'На указанный e-mail была направлена инструкция для восстановления пароля.',
    manualForSignUp: 'На указанный e-mail была направлена ссылка для подтверждения регистрации. Пройдите по ней, чтобы войти в аккаунт.',
    contactSupport: 'Связаться с техподдержкой',
    manualResent: 'Инструкция направлена повторно.',
    manualNotCame: 'Не пришло письмо с инструкцией?',
    linkResent: 'Ссылка направлена повторно.',
    emailWithLinkNotCame: 'Не пришло письмо со ссылкой?',
    closeToTarget: 'Вы почти у цели!',
    successVerified: 'Ваша почта успешно подтверждена',
    putNewPassword: 'Введите новый пароль',
    newPasswordDesc: 'Для восстановления доступа к IMPulse придумайте новый пароль.',
    changePassword: 'Изменить пароль',
    min8Sym: 'Минимум 8 символов',
    upCaseContains: 'Содержит заглавную букву',
    numContains: 'Содержит цифру',
    newPassword: 'Новый пароль',
    step: 'Шаг',
    position: 'Должность',
    company: 'Компания',
    next: 'Далее',
    phone: 'Номер телефона',
    back: 'Назад',
    successEntryToCompany: 'Вы успешно втупили в компанию по приглашению',
    successRegistered: 'Вы успешно зарегистрированы',
    invitationIsExpired: 'Выше приглашение устарело, запросите новое приглашение у администратора организации',
  },
} as const
