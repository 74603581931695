import { IFormDataParams } from 'api/entities/files/models'

export enum ROLE {
  USER = 'user',
  USER_PRO = 'user_pro',
  ORG_ADMIN = 'org_admin',
  ADMIN = 'admin',
}

export interface IUserShortResponse {
  email: string,
  name: string,
  surname: string,
  user_id: string,
}

export interface IUserInfoResponse {
  role: ROLE,
  user_id: string,
  position: string
  contact_info: {
    surname: string,
    name: string,
    phone_number: string,
  },
}

export interface IUserResponse {
  organisation: {
    organization_id: string,
    organization_countries: string[],
    organization_name: string,
    company_admins: IUserShortResponse[],
  },
  access_rights: {
    global_write_access: boolean,
    organization_write_access: boolean,
    personal_write_access: boolean,
    classification_request_access: boolean,
    organization_console_access: boolean,
    portal_console_access: boolean
  },
  email_login: string,
  user: IUserInfoResponse,
  timestamp: string, // 1634159277.7914057
}

export interface IUpdateUserInput {
  contact_info: {
    surname: string
    name: string
    phone_number: string
  },
  'organisation': {
    'organization_countries': string[],
    'organization_name': string,
  },
  'position': string
}

export interface IUpdatePasswordInput {
  current_password: string;
  new_password: string;
}

export interface IUserLicenseResponse {
  contract_id: string,
  company_id: string,
  sub_end_date: string,
  sub_start_date: string,
  payment_date: string,
  sign_date: string,
  company_name: string,
  is_active: boolean,
  days_before_end_date: number,
  license_id: string,
  product: string,
  product_code: string,
}

export interface IAcceptInvitationResponse {
  guid: string,
  issued_by: IUserInfoResponse,
  email_login: string,
  organization_name: string,
  organization_id: string,
  date_of_issue: string, // '2022-02-08T00:08:56.728000',
  date_of_expiration: string, // '2022-02-15T00:08:56.728000',
  user_exists: boolean,
  is_active: boolean
}

export interface IUserPhotoResponse {
  download_image: string,
  delete_image: string,
  upload_image: {
    url: string,
    fields: IFormDataParams
  }

}

