import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import React, { FC } from 'react'
import './Button.css'

export type ButtonProps = MuiButtonProps & { loading?: boolean }

export const Button: FC<ButtonProps> = ({ loading, ...props }) => {
  return (
    <MuiButton className={loading ? 'fetching_button' : ''} disabled={loading || props.disabled} {...props} />
  )
}

