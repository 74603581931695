import { Box, Link } from '@mui/material'
import { ROLE } from 'api'
import { SmallSelect } from 'modules/common/components'
import { useText } from 'modules/locale'
import React, { FC, MouseEvent, useMemo, useState } from 'react'

type Props = {
  role: ROLE,
  userId: string,
  rolesList?: 'portal' | 'org'
  onRoleChange?: (userId: string, role: ROLE) => void
  isLoading: boolean
}
const parseRole = (role: ROLE, rolesList?: 'portal' | 'org'): ROLE => {
  if (rolesList === 'portal' && role !== ROLE.ADMIN) {
    return ROLE.USER
  }

  return role
}

export const ChangeRoleSelect: FC<Props> = ({ role, userId, rolesList, onRoleChange, isLoading }) => {
  const [value, setValue] = useState<ROLE>(parseRole(role, rolesList))
  const { text } = useText('USER')
  const options = useMemo(() => {
    if (rolesList === 'portal') {
      return [
        { value: ROLE.ADMIN, label: text.administrator },
        { value: ROLE.USER, label: text[ROLE.USER] },
      ]
    }

    return [
      { value: ROLE.ORG_ADMIN, label: text.administrator },
      { value: ROLE.USER, label: text[ROLE.USER] },
      { value: ROLE.USER_PRO, label: text[ROLE.USER_PRO] },
    ]
  }, [rolesList, text])

  const handleChangeRole = async (e: MouseEvent<HTMLLIElement>) => {
    const role = e.currentTarget.id as ROLE
    if (onRoleChange) {
      onRoleChange(userId, role)
    }
    setValue(role as ROLE)
  }
  if (!onRoleChange) {
    return (
      <Link
        underline='none'
        variant='body1'
        color='secondary'
        display='flex'
        alignItems='center'
        gap='24px'
      >
        {options.find(it => it.value === role)?.label || ''}
      </Link>
    )
  }

  return (
    <Box py='21px'>
      <SmallSelect value={value} disabled={isLoading} options={options} onSelect={handleChangeRole} />
    </Box>
  )
}
