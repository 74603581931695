import { Box, CircularProgress, Collapse, Divider, IconButton, Paper, styled, Typography } from '@mui/material'
import { CloseRowIcon, OpenRowIcon } from 'assets/svg'
import { CollapsibleRow, Column } from 'modules/common/components'
import React, { FC, MouseEvent, ReactNode, ReactText, useCallback, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { blue, color } from 'styles/theme'

type Props = {
  columns: Column[],
  rows: CollapsibleRow[],
  iconAlign?: string,
  height?: string,
  maxHeight?: string,
  total?: number | undefined
  fetchData?: () => void
  fetching?: boolean
}

export const CollapsibleTable: FC<Props> = ({
  columns,
  rows,
  fetching,
  fetchData,
  total,
  iconAlign,
  height,
  maxHeight,
}) => {
  const [open, setOpen] = useState<string>()
  const toggleOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const { id } = e.currentTarget
    setOpen(prev => prev === id ? undefined : id)
  }, [])

  return (
    <Paper elevation={1}>
      <Box
        bgcolor={blue[800]}
        p='10px 20px'
        display='grid'
        alignItems='center'
        gridTemplateColumns={`min-content repeat(${columns.length}, 1fr)`}
        sx={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}
      >
        <Box width='50px' />

        {columns.map((column) => {
          return (
            <Typography
              key={column.id}
              color={color.surface}
              variant='subtitle1'
              minWidth={column.minWidth}
              width={column.width}
              sx={{ textTransform: 'upperCase' }}
            >
              {column.label}
            </Typography>
          )
        })}
      </Box>

      <Wrapper
        height={height}
        maxHeight={maxHeight}
        dataLength={rows.length}
        next={fetchData || (() => null)}
        hasMore={!!(total && total > rows.length)}
        loader={fetching && (
          <Box
            py='20px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            gridColumn={`1 / ${columns.length + 1}`}
            borderTop='1px solid #D1D1D1'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      >
        {rows.map((row, i) => {
          return (
            <Row
              key={row.id}
              iconAlign={iconAlign}
              isFirst={i === 0}
              row={row}
              columns={columns}
              subRow={row.subRow}
              toggleOpen={toggleOpen}
              open={open}
            />
          )
        })}
      </Wrapper>
    </Paper>
  )
}

type RowProps = {
  row: { [key: string]: ReactText | ReactNode | null }
  columns: Column[]
  subRow: ReactNode | ReactText | null
  isFirst?: boolean
  toggleOpen: (e: MouseEvent<HTMLButtonElement>) => void
  open?: string
  iconAlign?: string
}
const Row: FC<RowProps> = ({ row, iconAlign, open, columns, subRow, toggleOpen, isFirst }) => {
  return (
    <>
      <RowWrapper opened={open} rowId={row.id as string} isFirst={isFirst}>
        <Box
          px='20px'
          display='grid'
          gridTemplateColumns={`min-content repeat(${columns.length}, 1fr)`}
        >
          <Box pr='16px' alignSelf={iconAlign ?? 'center'} py='12px'>
            <IconButton id={String(row.id)} size='small' onClick={toggleOpen}>
              {open === row.id ? <CloseRowIcon /> : <OpenRowIcon />}
            </IconButton>
          </Box>

          <>
            {columns.map((column) => {
              if (column.noTypography) {
                return (
                  <Box key={column.id} display='flex' justifyContent={column.justify} alignItems={column.align}>
                    {row[column.id]}
                  </Box>
                )
              }

              return (
                <Typography
                  key={column.id}
                  py='20px'
                  variant='body1'
                  color={(!open || open === row.id) ? 'primary' : 'secondary'}
                  display='flex'
                  justifyContent={column.justify}
                  alignItems={column.align}
                >
                  {row[column.id]}
                </Typography>
              )
            })}
          </>
        </Box>

        <Collapse in={open === row.id} timeout='auto' unmountOnExit>
          <Box p='0 20px 20px'>
            <Divider sx={{ mb: '20px', mx: '-10px' }} />
            {subRow}
          </Box>
        </Collapse>

      </RowWrapper>

    </>
  )
}

const RowWrapper = styled(Box)<{ opened?: string, rowId: string, isFirst?: boolean }>`
  position: relative;
  transition: all 0.2ms ease;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ opened, rowId }) => opened === rowId ? blue[200] : 'transparent'};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    background: ${color.disabled};
    width: calc(100% - 20px);
    height: ${({ opened, rowId, isFirst }) => {
      if (isFirst || opened === rowId || opened === String(Number(rowId) + 1) || opened === String(Number(rowId) - 1)) {
        return 0
      }

      return '1px'
    }}
  }
`
const Wrapper = styled(InfiniteScroll)<{ height?: string, maxHeight?: string }>`
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};

`
