import { ClassifierOwner, IClassifierResponse, IClassifierTreeElement } from 'api/entities/classifer'
import { DateTime } from 'luxon'
import { ClassifierFormValues, ElementRowProps } from 'modules/classifiers/components'
import { CLASSIFIER_TYPES } from 'modules/classifiers/constants'
import { ClassifierType, IClassifier, IClassifierBase, IClassifierNavRow } from 'modules/classifiers/models'
import { LOCALE, TEXT } from 'modules/locale'

const getClassifierType = (owner: ClassifierOwner, myId: string, isSharedToUser: boolean) => {
  if (isSharedToUser) {
    return CLASSIFIER_TYPES.SHARED
  }

  switch (owner) {
    case 'World':
      return CLASSIFIER_TYPES.WORLD
    case 'Country':
      return CLASSIFIER_TYPES.COUNTRY
    case myId:
      return CLASSIFIER_TYPES.PERSONAL
    default:
      return CLASSIFIER_TYPES.CORPORATE
  }
}

export const normalizeClassifier = (data: IClassifierBase, myId: string): IClassifier => {
  const { owner, isSharedToUser, ...classifier } = data
  return { ...classifier, type: getClassifierType(owner, myId, isSharedToUser) }
}

export const normalizeClassifierBase = (cl: IClassifierResponse): IClassifierBase => {
  const date = DateTime.fromISO(cl.date)
  return {
    owner: cl.owner,
    parentGroup: cl.parent_group,
    countries: Array.isArray(cl.country) ? cl.country : [cl.country],
    name: cl.name,
    id: cl._id,
    codesInfoRef: cl.codes_info_ref,
    observers: Array.isArray(cl.observers) ? cl.observers : [cl.observers],
    date: date.isValid ? cl.date : DateTime.fromFormat(cl.date, 'dd.mm.yyyy').toISO(),
    codeParameter: cl.code_parameter_name,
    version: cl.version,
    descriptionParameter: cl.description_parameter_name,
    ownerName: cl.owner_name,
    userCanEdit: cl.user_can_edit,
    isSharedToUser: cl.is_shared_to_user,
  }
}

export const normalizeClassifiers = (data: IClassifierResponse[]): { [key: string]: IClassifierBase } => {
  return data.reduce((acc, it) => {
    acc[it._id] = normalizeClassifierBase(it)
    return acc
  }, {} as { [key: string]: IClassifierBase })
}

export const normalizeInitialValues = (classifier: IClassifierBase): ClassifierFormValues => {
  const date = DateTime.fromISO(classifier.date)
  return {
    name: classifier.name,
    version: classifier.version,
    codes_info_ref: classifier.codesInfoRef,
    parent_group: classifier.parentGroup,
    description_parameter_name: classifier.descriptionParameter,
    code_parameter_name: classifier.codeParameter,
    country: classifier.countries,
    date: date.isValid ? date : DateTime.fromFormat(classifier.date, 'dd.MM.yyyy'),
  }
}

export const normalizeClassifierTreeElement = (data: IClassifierTreeElement[], parent: string | null = null, parents: string[] = []): ElementRowProps[] => {
  const result = data
    .filter(it => it.parent === parent)
    .map(item => ({
      element: { ...item, parents: item.parents ? [...item.parents, ...parents] : parents },
      rows: normalizeClassifierTreeElement(data, item.class, [...parents, item.class]),
    }))

  return result as ElementRowProps[]
}

export const makeClassifierTree = (data: IClassifierTreeElement[], parent: string | null = null): ElementRowProps[] => {
  const result = data
    .filter(it => it.parent === parent)
    .map(item => ({ element: item, rows: makeClassifierTree(data, item.class) }))

  return result as ElementRowProps[]
}

export const getParents = (tree: IClassifierTreeElement[], element: IClassifierTreeElement): string[] => {
  const parents = [] as string[]
  const rec = (element: IClassifierTreeElement) => {
    const found = tree.find(it => it.class === element.parent)
    if (found) {
      parents.push(found.class)
      rec(found)
    }
  }
  rec(element)

  return [element.class, ...parents]
}

export const getNavParents = (data: { [p: string]: IClassifierBase } | undefined, id: string | undefined, key: string): string[] => {
  if (!data || !id) {
    return []
  }

  const normalizedData: IClassifier[] = Object.values(data).map(it => normalizeClassifier(it, id))

  const countries = makeCountriesArray(normalizedData)
  const groups = makeGroupsArray(normalizedData)
  const classifiers = makeClassifiersArray(normalizedData)
  const owners = makeOwnersArray(normalizedData)

  const tree = [...typesArray, ...countries, ...owners, ...groups, ...classifiers]
  const parents = [] as string[]

  const rec = (element: IClassifierNavRow) => {
    const found = tree.find(it => it.key === element.parent)
    if (found) {
      parents.push(found.key)
      rec(found)
    }
  }

  const element = tree.find(it => it.key === key)
  element && rec(element)

  return [key, ...parents]
}

//  ------------ Навигация --------------

// функция - для создания дерева из массива
const makeNavTree = (data: IClassifierNavRow[], parent: string | null = null): IClassifierNavRow[] => {
  const result = data
    .filter(it => it.parent === parent)
    .map(item => ({ ...item, rows: makeNavTree(data, item.key) }))

  return result as IClassifierNavRow[]
}

const locale = LOCALE.RUSSIAN
// массив элементов верхнего уровня навигации - тип классификатора
const typesArray: IClassifierNavRow[] = [
  {
    name: TEXT.CLASSIFIERS[locale][CLASSIFIER_TYPES.WORLD],
    type: CLASSIFIER_TYPES.WORLD,
    key: CLASSIFIER_TYPES.WORLD,
    parent: null,
  },
  {
    name: TEXT.CLASSIFIERS[locale][CLASSIFIER_TYPES.COUNTRY],
    type: CLASSIFIER_TYPES.COUNTRY,
    key: CLASSIFIER_TYPES.COUNTRY,
    parent: null,
  },
  {
    name: TEXT.CLASSIFIERS[locale][CLASSIFIER_TYPES.CORPORATE],
    type: CLASSIFIER_TYPES.CORPORATE,
    key: CLASSIFIER_TYPES.CORPORATE,
    parent: null,
  },
  {
    name: TEXT.CLASSIFIERS[locale][CLASSIFIER_TYPES.PERSONAL],
    type: CLASSIFIER_TYPES.PERSONAL,
    key: CLASSIFIER_TYPES.PERSONAL,
    parent: null,
  },
  {
    name: TEXT.CLASSIFIERS[locale][CLASSIFIER_TYPES.SHARED],
    type: CLASSIFIER_TYPES.SHARED,
    key: CLASSIFIER_TYPES.SHARED,
    parent: null,
  },
]

// TODO объединить в один проход по массиву
// функция для получения массива элементов второго уровня - страна классификатора (только для типа по странам)
const makeCountriesArray = (classifiers: IClassifier[]): IClassifierNavRow[] => {
  return classifiers.reduce((acc, it) => {
    if (it.type === CLASSIFIER_TYPES.COUNTRY) {
      it.countries.forEach(country => {
        if (!acc.find(el => el.name === country)) {
          acc.push({
            name: country,
            type: it.type,
            country: country,
            key: country,
            parent: CLASSIFIER_TYPES.COUNTRY,
          })
        }
      })
    }

    return acc
  }, [] as IClassifierNavRow[])
}

// функция для получения массива элементов второго уровня - пользователь который поделился со мной (только для типа поделились со мной)
const makeOwnersArray = (classifiers: IClassifier[]): IClassifierNavRow[] => {
  return classifiers.reduce((acc, it) => {
    if (it.type === CLASSIFIER_TYPES.SHARED) {
      if (!acc.find(el => el.name === it.ownerName)) {
        acc.push({
          name: it.ownerName,
          type: it.type,
          owner: it.ownerName,
          key: it.ownerName,
          parent: CLASSIFIER_TYPES.SHARED,
        })
      }
    }

    return acc
  }, [] as IClassifierNavRow[])
}

// функция для получения массива элементов второго/третьего  уровня - группа классификатора
const makeGroupsArray = (classifiers: IClassifier[]): IClassifierNavRow[] => {
  return classifiers.reduce((acc, it) => {
    if (!acc.find(group => (String(group.name) === String(it.parentGroup)) && (group.type === it.type))) {
      it.countries.forEach(country => {
        const findParent = (type: ClassifierType) => {
          switch (type) {
            case CLASSIFIER_TYPES.COUNTRY:
              return country
            case CLASSIFIER_TYPES.SHARED:
              return it.ownerName
            default:
              return it.type
          }
        }
        acc.push({
          name: it.parentGroup,
          type: it.type,
          group: it.parentGroup,
          country: country,
          owner: it.ownerName,
          key: `${it.type}_${it.parentGroup}`,
          parent: findParent(it.type),
        })
      })
    }

    return acc
  }, [] as IClassifierNavRow[])
}

const makeClassifiersArray = (classifiers: IClassifier[]): IClassifierNavRow[] => {
  return classifiers.map(it => ({
    id: it.id,
    group: it.parentGroup,
    name: it.name,
    type: it.type,
    // country: it.countries[0],
    key: it.id,
    parent: `${it.type}_${it.parentGroup}`,
  }),
  )
}

export const createNavTree = (data: { [key: string]: IClassifierBase }, id: string): IClassifierNavRow[] => {
  const normalizedData: IClassifier[] = Object.values(data).map(it => normalizeClassifier(it, id))

  const countries = makeCountriesArray(normalizedData)
  const groups = makeGroupsArray(normalizedData)
  const classifiers = makeClassifiersArray(normalizedData)
  const owners = makeOwnersArray(normalizedData)

  return makeNavTree([...typesArray, ...owners, ...countries, ...groups, ...classifiers])
}
