import { IOrganizationUser } from 'api'
import { ITableUser } from 'modules/organization/components/EmployeesTable'

export const normalizeTableUsers = (data: IOrganizationUser[] | undefined): ITableUser[] => {
  if (!data) {
    return []
  }

  return data.map(user => ({
    role: user.role,
    user_id: user.user_id,
    position: user.position,
    contact_info: user.contact_info,
    email_login: user.email_login,
  }))
}
