import { Box, CircularProgress, Paper, styled, Typography } from '@mui/material'
import { Column, Row } from 'modules/common/components'
import React, { FC } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { blue, color } from 'styles/theme'

type Props = {
  columns: Column[];
  rows: Row[],
  height?: string,
  maxHeight?: string,
  total?: number | undefined
  fetchData?: () => void
  fetching?: boolean
}

export const SimpleTable: FC<Props> = ({ rows, columns, height, maxHeight, fetching, fetchData, total }) => {
  return (
    <Paper elevation={1} sx={{ minHeight: '158px' }}>
      <Box
        bgcolor={blue[800]}
        p='10px 20px'
        display='grid'
        alignItems='center'
        gridTemplateColumns={`repeat(${columns.length}, 1fr)`}
        sx={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}
      >
        {columns.map((column) => {
          return (
            <Typography
              key={column.id}
              color={color.surface}
              variant='subtitle1'
              minWidth={column.minWidth}
              width={column.width}
              sx={{ textTransform: 'upperCase' }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              align={column.justify}
            >
              {column.label}
            </Typography>
          )
        })}
      </Box>
      <Wrapper
        columns={columns.length}
        height={height}
        maxHeight={maxHeight}
        dataLength={total || 0}
        next={fetchData || (() => null)}
        hasMore
        loader={fetching && (
          <Box
            py='20px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            gridColumn={`1 / ${columns.length + 1}`}
            borderTop='1px solid #D1D1D1'
          >
            <CircularProgress size={25} />
          </Box>
        )}
      >

        {rows.map((row, i) => {
          return (
            <Box
              key={i}
              borderBottom={(rows.length > 1 && i === rows.length - 1) ? '' : '1px solid #D1D1D1'}
              display='grid'
              alignItems='center'
              gridTemplateColumns={`repeat(${columns.length}, 1fr)`}
            >
              {columns.map((column) => {
                if (column.noTypography) {
                  return (
                    <Box
                      key={column.id}
                      p='20px 10px'
                      display='flex'
                      alignItems={column.align}
                      justifyContent={column.justify}
                    >
                      {row[column.id]}
                    </Box>
                  )
                }

                return (
                  <Box key={column.id} px='10px'>
                    <Typography py='20px' variant='body1' noWrap={column.noWrap}>
                      {row[column.id]}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          )
        })}
      </Wrapper>

    </Paper>
  )
}
const Wrapper = styled(InfiniteScroll)<{ columns?: number, height?: string, maxHeight?: string }>`
  padding-left: 10px;
  padding-right: 10px;
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};

`
