import { Box, Checkbox, Dialog, FormControlLabel, IconButton, styled, Typography } from '@mui/material'
import { IContractShortResponse, IEditContractInput, useEditContractMutation } from 'api'
import { CloseIcon, EditIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { DateTime } from 'luxon'
import { Button, DatePicker, DatePickerProps, TextField, TextFieldProps } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { validationSchema } from './constants'

type Props = { contract: IContractShortResponse, companyId: string }

const commonProps: Partial<DatePickerProps> = {
  fullWidth: true,
  labelWeight: 500,
  labelVariant: 'body1',
  labelColor: 'primary',
}

export const ContractEdit: FC<Props> = ({ contract, companyId }) => {
  const [open, setOpen] = useState(false)
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [editContract, { isLoading, isSuccess, error }] = useEditContractMutation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const initialValues = useMemo(() => ({
    sign_date: DateTime.fromSQL(contract.sign_date),
    payment_date: DateTime.fromSQL(contract.payment_date),
    sub_start_date: DateTime.fromSQL(contract.sub_start_date),
    sub_end_date: DateTime.fromSQL(contract.sub_end_date),
  }), [contract])

  const formik = useFormik({
    initialValues: { ...initialValues, is_approved: true, contractId: contract.contract_id },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body: IEditContractInput = {
        _id: contract._id,
        company_id: companyId,
        contract_id: values.contractId,
        sub_start_date: values.sub_start_date.toISO(),
        sub_end_date: values.sub_end_date.toISO(),
        sign_date: values.sign_date.toISO(),
        payment_date: values.payment_date.toISO(),
        is_approved: values.is_approved,
      }
      await editContract(body).unwrap()
    },
  })
  const { handleSubmit, values, setFieldValue, handleChange, handleBlur, touched, errors } = formik
  const handleDateChange = (field: string, date: DateTime | null) => {
    setFieldValue(field, date)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.contractEditSuccess, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      handleClose()
    }
  }, [isSuccess, closeSnackbar, enqueueSnackbar, text.contractEditSuccess])
  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <EditIcon />
      </IconButton>

      <Dialog open={open} onClose={isLoading ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={isLoading} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography variant='h2'>{text.editContract}</Typography>

        <Box component='form' mt='20px' onSubmit={handleSubmit}>

          <Box display='grid' gap='5px'>

            <TextField
              {...commonProps as TextFieldProps}
              name='contractId'
              value={values.contractId}
              label={text.contractId}
              disabled={isLoading}
              required
              error={touched.contractId && !!errors.contractId}
              helperText={(touched.contractId && errors.contractId) ?? ' '}
              sx={{ mb: '-5px' }}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {Object.keys(initialValues).map(key => {
              return (
                <DatePicker
                  key={key}
                  {...commonProps}
                  label={text[key]}
                  name={key}
                  value={values[key as keyof typeof values]}
                  error={touched.contractId && !!errors.contractId}
                  helperText={(touched.contractId && errors.contractId) ?? ' '}
                  onDateChange={handleDateChange}
                  onBlur={handleBlur}
                />
              )
            })}

            <FormControlLabel
              name='is_approved'
              control={<Checkbox checked={values.is_approved} onChange={handleChange} />}
              label={text.openLicenses}
              disabled={isLoading}
            />
          </Box>

          <Box display='flex' gap='20px' mt='40px'>
            <Button
              loading={isLoading}
              fullWidth
              variant='contained'
              type='submit'
            >
              {text.save}
            </Button>
            <Button
              fullWidth
              disabled={isLoading}
              variant='outlined'
              onClick={handleClose}
            >
              {text.cancel}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
