import { Box, Typography } from '@mui/material'
import { CheckedIcon } from 'assets/svg'
import { useText } from 'modules/locale'
import React, { FC } from 'react'
import { color } from 'styles/theme'

type Props = { password: string }
const rules = [
  { isSuccess: (value: string) => value.length >= 8, text: 'min8Sym' },
  { isSuccess: (value: string) => value.match(/[A-Z]/), text: 'upCaseContains' },
  { isSuccess: (value: string) => value.match(/[0-9]/), text: 'numContains' },
]
export const PasswordHelpers: FC<Props> = ({ password }) => {
  const { text } = useText('AUTH')
  return (
    <>
      {rules.map((r) => {
        return (
          <Box key={r.text} display='flex' alignItems='center'>
            <CheckedIcon color={r.isSuccess(password) ? color.success : color.disabled} />
            <Typography color='secondary' variant='body2'>{text[r.text]}</Typography>
          </Box>
        )
      })}
    </>
  )
}

