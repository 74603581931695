import { Box } from '@mui/material'
import { useInviteUserFromAdminMutation, useInviteUserMutation } from 'api'
import { useFormik } from 'formik'
import { Button, TextField } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useEffect } from 'react'
import { validationSchema } from './constants'

type Props = { fromAdmin?: boolean }

export const SendInvitationForm: FC<Props> = ({ fromAdmin }) => {
  const [inviteUser, { isLoading, error, isSuccess }] = useInviteUserMutation()
  const [inviteUserFromAdmin, {
    isLoading: isLoading2,
    error: error2,
    isSuccess: isSuccess2,
  }] = useInviteUserFromAdminMutation()
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const formik = useFormik({
    initialValues: { email: '' }, validationSchema,
    onSubmit: async (values) => {
      if (values) {
        if (fromAdmin) {
          await inviteUserFromAdmin({ email: values.email }).unwrap()
        } else {
          await inviteUser({ email: values.email }).unwrap()
        }
      }
    },
  })

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } = formik

  useEffect(() => {
    if (error || error2) {
      console.error(error || error2)
      enqueueSnackbar(getError(error).message || getError(error2).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
    if (isSuccess || isSuccess2) {
      const successMessage = `${text.successUserInvitedFirstPart} ${values.email} ${text.successUserInvitedLastPart}`
      enqueueSnackbar(successMessage, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error, error2, isSuccess, isSuccess2, text.successUserInvitedFirstPart, text.successUserInvitedLastPart, values.email])

  return (
    <Box component='form' display='flex' gap='20px' width='480px' onSubmit={handleSubmit}>
      <TextField
        fullWidth
        name='email'
        required
        disabled={isLoading}
        error={touched.email && !!errors.email}
        helperText={(touched.email && errors.email) ?? ' '}
        placeholder={text.putEmployeeEmail}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Button sx={{ px: '50px' }} variant='outlined' loading={isLoading || isLoading2} type='submit'>
        {text.invite}
      </Button>
    </Box>
  )
}
