import { Box, Dialog, IconButton, InputAdornment, Link, styled, Typography } from '@mui/material'
import { CloseIcon, EditIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { Button, Password, TextField } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { newArr } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { clearErrorField, clearSuccessField, updatePassword } from 'modules/me/actions'
import { UpdatePasswordValues } from 'modules/me/models'
import { selectMe } from 'modules/me/slice'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { color } from 'styles/theme'
import { validationSchema, VALUES } from './constants'

const initialValues = {
  currentPassword: '',
  password: '',
  repeatPassword: '',
}

export const PasswordUpdate: FC = () => {
  const { isFetching, errors: { updatePassword: error }, success: { updatePassword: success } } = useSelector(selectMe)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { text } = useText('USER')
  const [open, setOpen] = useState(false)

  const formik = useFormik({
    initialValues, validationSchema,
    onSubmit: async (values: UpdatePasswordValues) => {
      await dispatch(updatePassword(values))
    },
  })

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  const { values, touched, handleBlur, handleChange, handleSubmit, errors, resetForm } = formik

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    dispatch(clearSuccessField('updatePassword'))
    dispatch(clearErrorField('updatePassword'))
    resetForm()
    setOpen(false)
  }

  const endAdornment = (
    <InputAdornment position='end'>
      <IconButton
        size='small'
        edge='end'
        onClick={handleOpen}
        onMouseDown={handleClose}
      >
        <EditIcon />
      </IconButton>
    </InputAdornment>
  )

  return (
    <>
      <Box position='relative'>
        <TextField label={text.password} placeholder='' fullWidth InputProps={{ endAdornment, readOnly: true }} />
        <Box position='absolute' display='flex' alignItems='center' gap='10px' top='42px' left='20px'>
          {newArr(8).map(it => <Circle key={it} />)}
        </Box>
      </Box>

      <Dialog open={open} onClose={!isFetching ? handleClose : undefined}>
        <StyledIconButton size='small' onClick={!isFetching ? handleClose : undefined}>
          <CloseIcon />
        </StyledIconButton>
        <Typography variant='h2'>{text.editPassword}</Typography>

        {success ? (
          <>
            <Typography my='20px' variant='body1' color='secondary'>{text.changePasswordManualSent}</Typography>
            <Typography variant='body1'>{text.manualNotCame}</Typography>
            <Link mt='10px' component={RouterLink} to='/#' color='secondary'>{text.contactSupport}</Link>
          </>
        ) : (
          <Box component='form' mt='24px' display='grid' gap='5px' onSubmit={handleSubmit}>

            {Object.values(VALUES).map((v) => {
              return (
                <Password
                  key={v}
                  autoComplete='new-password'
                  fullWidth
                  name={v}
                  label={v === VALUES.PASSWORD ? text.newPassword : text[v]}
                  value={values[v]}
                  disabled={isFetching}
                  error={touched[v] && !!errors[v]}
                  helperText={(touched[v] && errors[v]) ?? ' '}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )
            })}

            <Box display='flex' gap='20px' mt='20px'>
              <Button autoFocus loading={isFetching} type='submit' fullWidth variant='contained'>{text.confirm}</Button>
              <Button fullWidth disabled={isFetching} variant='outlined' onClick={handleClose}>{text.cancel}</Button>
            </Box>
          </Box>

        )}
      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const Circle = styled('div')`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${color.medium};
`

