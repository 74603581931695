import { IUserLicenseResponse } from 'api'
import { Row } from 'modules/common/components'
import { dateFormat } from 'modules/common/utils'

export const createRows = (data: IUserLicenseResponse[] | undefined): Row[] => {
  if (!data) {
    return []
  }

  return data.map(it => ({
    license: it.product_code,
    validity: dateFormat(it.sub_end_date),
  }))
}
