import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setLanguage } from 'modules/locale/actions'
import { RootState } from 'store/models'
import { LOCALE } from './constants'
import { Locale } from './models'

export type IState = {
  locale: Locale;
}
export const initialState: IState = {
  locale: localStorage.getItem('locale') as Locale || LOCALE.RUSSIAN,
}

export const slice = createSlice({
  name: 'locale',
  initialState,
  reducers: {},
  extraReducers: {
    [setLanguage.type]: (state, { payload }: PayloadAction<Locale>) => {
      state.locale = payload
    },
  },
})

export const selectLocale = (state: RootState) => state.locale
export default slice.reducer
