import { Checkbox, FormGroup } from '@mui/material'
import {
  IContractResponse,
  IContractShortResponse,
  ROLE,
  useAssignLicenseToUserMutation,
  useAttachLicenseMutation,
} from 'api'
import { selectAuth } from 'modules/auth/slice'
import { FormControlLabel } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import React, { ChangeEvent, FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { normalizeLicenses } from './utils'

type Props = { userId: string, contracts: IContractShortResponse[] | IContractResponse[], companyId?: string }

export const AvailableLicensesList: FC<Props> = ({ userId, contracts, companyId }) => {
  const { consoleFor } = useSelector(selectAuth)
  const [assignLicense, { isLoading, error }] = useAssignLicenseToUserMutation()
  const [assignLicenseFromAdmin, { isLoading: isLoading2, error: error2 }] = useAttachLicenseMutation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const licenses = useMemo(() => normalizeLicenses(contracts, userId), [contracts, userId])

  const handleAssignLicense = async (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    const licenseId = licenses[e.currentTarget.name].availableIds[0]
    const assignToUser = checked ? { userId } : {}
    if (consoleFor === ROLE.ADMIN && companyId) {
      await assignLicenseFromAdmin({ licenseId, companyId, ...assignToUser })
      return
    }

    await assignLicense({ licenseId, ...assignToUser }).unwrap()
  }

  useEffect(() => {
    if (error || error2) {
      console.error(error || error2)
      enqueueSnackbar(getError(error || error2).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error, error2])

  return (
    <div>
      <FormGroup>
        {Object.keys(licenses).map(license => {
          return (
            <FormControlLabel
              key={license}
              control={
                <Checkbox
                  name={license}
                  checked={licenses[license].attachedToUser}
                  onChange={handleAssignLicense}
                />
              }
              label={license.split('_')[0]}
              disabled={!licenses[license].availableIds.length || isLoading || isLoading2}
            />
          )
        })}
      </FormGroup>
    </div>
  )
}
