import { Box, IconButton, styled, Typography } from '@mui/material'
import { BackIcon } from 'assets/svg'
import React, { FC, ReactNode } from 'react'
import { color } from 'styles/theme'

type Props = { children: ReactNode; onClick?: () => void }

export const BackBtn: FC<Props> = ({ onClick, children }) => {
  return (
    <Box display='flex' alignItems='center' mt='auto' mb='80px' pt='20px' onClick={onClick}>
      <IconButton color='secondary' size='small'>
        <BackIcon />
      </IconButton>
      <Link variant='body1' color='secondary'>{children}</Link>
    </Box>
  )
}

const Link = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2ms ease;

  &:hover {
   color: ${color.high}
  }
`
