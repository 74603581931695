import { Box, styled, Typography } from '@mui/material'
import { NotFoundEmoji } from 'assets/img'
import { NotFoundIcon } from 'assets/svg'
import { Button } from 'modules/common/components/Button'
import { ROUTE } from 'modules/common/routes'
import { useText } from 'modules/locale'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = { dev?: boolean }

export const NotFound: FC<Props> = ({ dev }) => {
  const { text } = useText('NOT_FOUND')
  const navigate = useNavigate()

  const handleClick = () => navigate(ROUTE.MAIN)

  return (

    <Wrapper>
      <NotFoundIcon width='500px' />

      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Box display='flex' mb='10px' gap='10px' alignItems='center'>
          <Typography align='left' variant='h1' noWrap>{text.pageNotFound}</Typography>
          <Img src={NotFoundEmoji} alt='emoji' />
        </Box>

        <Typography align='left' mb='40px' variant='h2' color='secondary'>{dev ? text.pageInDev : text.pageIsNotExist}</Typography>
        <Button variant='contained' onClick={handleClick}>{text.mainPage}</Button>
      </Box>

    </Wrapper>

  )
}
const Wrapper = styled('div')`
  display: flex;
  gap: 110px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Img = styled('img')`
  height: 60px;
  width: 60px;
`
