import { Link } from '@mui/material'
import React, { FC } from 'react'
import { LogoIcon } from '../../../../assets/svg'

type Props = { onClick?: () => void }

export const Logo: FC<Props> = ({ onClick }) => {
  return (
    <Link href='/' onClick={onClick}>
      <LogoIcon />
    </Link>
  )
}
