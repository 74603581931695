import { Box, Checkbox, Typography } from '@mui/material'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query'
import { ISendFeedback } from 'api'
import { useFormik } from 'formik'
import { BackBtn } from 'modules/auth/components'
import { privacyTerms } from 'modules/auth/components/SignUp/constants'
import { Button, FormControlLabel, TextField } from 'modules/common/components'
import { useText } from 'modules/locale'
import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { initialValues, validationSchema } from './constants'

const textFields = ['name', 'sender_email', 'theme', 'message']

type Props = {
  isLoading?: boolean
  isSuccess?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSubmit: MutationTrigger<MutationDefinition<ISendFeedback, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, never, ISendFeedback, 'appQuery'>>
}

export const ContactsForm: FC<Props> = ({ onSubmit, isLoading, isSuccess }) => {
  const { text: authText } = useText('AUTH')
  const { text } = useText('USER')
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues, validationSchema,
    onSubmit: async ({ agree, ...values }) => {
      await onSubmit(values).unwrap()
    },
  })
  const { values, handleChange, handleSubmit, touched, errors, handleBlur, resetForm } = formik

  useEffect(() => {
    if (isSuccess) {
      resetForm()
    }
  }, [isSuccess, resetForm])

  const handleBack = () => navigate(-1)

  if (isSuccess) {
    return (
      <div>
        <Typography mb='20px' variant='body1' color='secondary'>{text.weWillContactYou}</Typography>
        <BackBtn onClick={handleBack}>{text.back}</BackBtn>
      </div>
    )
  }

  return (
    <>
      <Box component='form' display='grid' gap='5px' onSubmit={handleSubmit}>
        {textFields.map(it => {
          return (
            <TextField
              key={it}
              value={values[it as keyof typeof values]}
              multiline={it === 'message'}
              rows={6}
              fullWidth
              name={it}
              placeholder={text[`contacts_${it}`]}
              disabled={isLoading}
              required
              error={touched[it as keyof typeof touched] && !!errors[it as keyof typeof errors]}
              helperText={(touched[it as keyof typeof touched] && errors[it as keyof typeof errors]) ?? ' '}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )
        })}

        <FormControlLabel
          error={touched.agree && !!errors.agree}
          name='agree'
          control={<Checkbox checked={values.agree} onChange={handleChange} />}
          label={privacyTerms(authText)}
          disabled={isLoading}
          onBlur={handleBlur}
        />

        <Box mt='20px' width='min-content'>
          <Button sx={{ p: '0 40px' }} type='submit' loading={isLoading} variant='contained'>
            {text.send}
          </Button>
        </Box>

      </Box>

    </>
  )
}
