import { SignUpInput } from 'api/entities/auth'
import { SignUpValues } from 'modules/auth/components/SignUp/models'

export const normalizeValues = (values: SignUpValues): SignUpInput => {
  return {
    email_login: values.email,
    password: values.password,
    contact_info: {
      surname: values.surname,
      name: values.name,
      phone_number: values.phone,
    },
    position: values.position,
    company: {
      name: values.company,
      countries: ['Russia'],
    },
  }
}

