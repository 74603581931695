import {
  Box,
  Collapse,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { ClassifierCreateButton } from 'modules/classifiers/components/ClassifierCreateButton'
import { CLASSIFIER_TYPES } from 'modules/classifiers/constants'
import { IClassifierBase, IClassifierNavRow } from 'modules/classifiers/models'
import { selectClassifiers, setNavItem } from 'modules/classifiers/slice'
import { createNavTree, getNavParents } from 'modules/classifiers/utils'
import { ROUTE } from 'modules/common/routes'
import { newArr } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'

type ClassifiersNavProps = {
  data: { [p: string]: IClassifierBase } | undefined,
  isLoading: boolean,
  params: {
    id: string | null | undefined,
    group: string | null | undefined,
    type: string | null | undefined,
    country: string | null | undefined,
    owner: string | null | undefined,
  }
}

export type RowProps = {
  row: IClassifierNavRow,
  i?: number,
  deep?: number,
  onOpen: (key: string) => void
  selectedKey: string
  tree: IClassifierNavRow[]
}

export const ClassifiersNav: FC<ClassifiersNavProps> = ({ data, isLoading, params }) => {
  const { my } = useSelector(selectMe)
  const rows = useMemo(() => (data && my?.id) ? createNavTree(data, my.id) : [], [data, my?.id])
  const { text } = useText('CLASSIFIERS')
  const dispatch = useDispatch()
  const handleOpen = useCallback((key: string) => dispatch(setNavItem(getNavParents(data, my?.id, key))), [dispatch, my?.id, data])
  const selectedKey = useMemo(() => {
    if (params.id) {
      return params.id
    }
    if (params.type && params.group) {
      return `${params.type}_${params.group}`
    }
    if (params.type && params.country) {
      return params.country
    }
    if (params.type && params.owner) {
      return params.owner
    }
    if (params.type) {
      return params.type
    }

    return ''
  }, [params])

  useEffect(() => {
    if (!isLoading && params.id && data && my?.id) {
      dispatch(setNavItem(getNavParents(data, my.id, params.id)))
    }
  }, [data, dispatch, isLoading, my?.id, params.id])

  return (
    <TableContainer sx={{ pb: '10px' }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                {text.classifiers}
                <ClassifierCreateButton />
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading
            ? (
              <TableRow>
                <TableCell sx={{ borderBottom: 'none' }}>
                  {newArr(5).map((row, i) => <Skeleton key={row} sx={{ m: i === 0 ? '20px 15px 15px' : '15px' }} />)}
                </TableCell>
              </TableRow>
            ) : rows.map((it, i) => (
              <Row
                key={it.name}
                i={i}
                row={it}
                selectedKey={selectedKey}
                tree={rows}
                onOpen={handleOpen}
              />))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Row: FC<RowProps> = ({ row, i, tree, onOpen, selectedKey, deep = 0 }) => {
  const { group, id, country, type, key, rows, owner } = row
  const { openedNavItems } = useSelector(selectClassifiers)
  const navigate = useNavigate()

  const cellProps = useMemo(() => ({
    sx: {
      pt: i === 0 ? '20px' : '10px',
      pb: '10px',
      borderBottom: 'none',
      pl: `${20 + (deep * 20)}px`,
      width: '100vw',
    },
  }), [deep, i])

  const handleClick = () => {
    let search = `type=${type}`
    group && (search += `&group=${group}`);
    (type === CLASSIFIER_TYPES.COUNTRY && country) && (search += `&country=${country}`);
    (type === CLASSIFIER_TYPES.SHARED && owner) && (search += `&owner=${owner}`);

    (id && group) ? navigate({
      pathname: generatePath(ROUTE.CLASSIFIER, { id }),
      search,
    }, { replace: true }) : navigate({ pathname: ROUTE.CLASSIFIERS, search }, { replace: true })

    onOpen(key)
  }

  return (
    <>
      <TableRow
        selected={selectedKey === key}
        hover
        sx={{ cursor: rows?.length ? 'pointer' : 'default' }}
        onClick={handleClick}
      >
        <TableCell {...cellProps}>
          {row.name}
        </TableCell>
      </TableRow>

      {(!rows?.length) ? null
        : (
          <TableRow>
            <TableCell padding='none' sx={{ borderBottom: 'none' }}>
              <Collapse in={openedNavItems[key]}>
                <Table>
                  <TableBody>
                    {rows.map((it) => (
                      <Row
                        key={it.name}
                        row={it}
                        deep={deep + 1}
                        selectedKey={selectedKey}
                        tree={tree}
                        onOpen={onOpen}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }
    </>
  )
}
