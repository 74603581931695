import { ROLE } from 'api'
import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'
import { AccessRights } from 'modules/me/models'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.HEADER[locale as Locale]

type Option = { consoleFor: ROLE | null, label: string }

export const getOptions = (accessRights: AccessRights) => {
  const { portalConsoleAccess, organizationConsoleAccess } = accessRights
  const options: Option[] = [{ consoleFor: null, label: T.signOut }, { consoleFor: ROLE.USER, label: T.userConsole }]
  if (organizationConsoleAccess) {
    options.push({ consoleFor: ROLE.ORG_ADMIN, label: T.orgConsole })
  }
  if (portalConsoleAccess) {
    options.push({ consoleFor: ROLE.ADMIN, label: T.portalConsole })
  }

  return options
}

export const accessFor = (accessRights: AccessRights | undefined): ROLE => {
  if (!accessRights) {
    return ROLE.USER
  }

  const { portalConsoleAccess, organizationConsoleAccess } = accessRights
  if (portalConsoleAccess) {
    return ROLE.ADMIN
  }
  if (organizationConsoleAccess) {
    return ROLE.ORG_ADMIN
  }

  return ROLE.USER
}
