import { Box, Dialog, Typography } from '@mui/material'
import { useDeleteClassifierMutation } from 'api'
import { Button } from 'modules/common/components'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

type Props = { open: boolean, id: string, name: string, onClose: () => void }

export const ClassifierDeleteDialog: FC<Props> = ({ open, name, id, onClose }) => {
  const { text } = useText('CLASSIFIERS')
  const { my } = useSelector(selectMe)
  const [deleteClassifier, { isLoading, error }] = useDeleteClassifierMutation()

  const handleDelete = async () => {
    if (my) {
      await deleteClassifier({ id }).unwrap()
      onClose()
    }
  }

  useShowError(error)

  return (
    <>
      <Dialog open={open}>
        <Typography mb='20px' variant='h2'>{text.deleteClassifier}</Typography>

        <Typography color='secondary' mb='10px' variant='body1'>{text.assignDeleting}</Typography>
        <Typography variant='body2' fontWeight={500}>{name}</Typography>

        <Box mt='40px' columnGap='20px' display='grid' gridTemplateColumns='1fr 1fr'>
          <Button loading={isLoading} variant='contained' onClick={handleDelete}>
            {text.delete}
          </Button>
          <Button disabled={isLoading} variant='outlined' onClick={onClose}>
            {text.cancel}
          </Button>
        </Box>
      </Dialog>
    </>
  )
}
