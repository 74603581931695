import { Box, Divider, Link, Skeleton, styled, Typography } from '@mui/material'
import { useGetClassifiersListQuery } from 'api'
import { CLASSIFIER_TYPES } from 'modules/classifiers/constants'
import { IClassifier, IClassifierBase } from 'modules/classifiers/models'
import { setNavItem, setTreeItems } from 'modules/classifiers/slice'
import { getNavParents, normalizeClassifier } from 'modules/classifiers/utils'
import { POINTER } from 'modules/common/constants'
import { ROUTE } from 'modules/common/routes'
import { newArr } from 'modules/common/utils'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import { SEARCH_PARAM } from 'pages/classifiers/constants'
import React, { FC, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { blue } from 'styles/theme'

type Props = { search: string, navData: { [p: string]: IClassifierBase; } | undefined }

export const ClassifiersSearchResults: FC<Props> = ({ search, navData }) => {
  const { text } = useText('CLASSIFIERS')
  const navigate = useNavigate()
  const { my } = useSelector(selectMe)
  const { data, error, isFetching } = useGetClassifiersListQuery({ search })
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const handleClick = (classifier: IClassifier) => {
    const pathname = generatePath(ROUTE.CLASSIFIER, { id: classifier.id })
    searchParams.delete(SEARCH_PARAM.SEARCH)
    searchParams.set(SEARCH_PARAM.TYPE, classifier.type)
    searchParams.set(SEARCH_PARAM.GROUP, classifier.parentGroup)
    classifier.type === CLASSIFIER_TYPES.COUNTRY && searchParams.set(SEARCH_PARAM.COUNTRY, classifier.countries[0]);
    (navData && my?.id) && dispatch(setNavItem(getNavParents(navData, my?.id, classifier.id)))
    dispatch(setTreeItems([]))
    navigate({ pathname, search: searchParams.toString() }, { replace: true })
  }

  useShowError(error)

  return (
    <>
      <Typography variant={'h2'}>{text.searchResults}</Typography>

      <ResultsBlock >
        {!isFetching ? null
          : (
            newArr(3).map((it, i) => {
              return (
                <Fragment key={it}>
                  {i === 0 ? <Divider /> : null}
                  <Box py='20px' display='grid' gap='6px'>
                    {newArr(3).map(it => <Skeleton key={it} />)}
                  </Box>
                  <Divider />
                </Fragment>
              )
            })
          )}

        {(data && !Object.values(data).length)
          ? (
            <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
              <Typography align='center' width='400px'>
                {text.noSearchResults}
              </Typography>
            </Box>

          )
          : null
        }

        {(data && my?.id) && Object.values(data).map((it: IClassifierBase, i) => {
          const classifier = normalizeClassifier(it, my.id)
          return (
            <Fragment key={classifier.id}>
              {i === 0 ? <Divider /> : null}
              <SearchItem>
                <Box display='flex' gap='1ch'>
                  <Typography variant='subtitle1' color={blue[800]}>{classifier.name}</Typography>
                  <Typography variant='body2' color='secondary'>-{text.classifier}</Typography>
                </Box>

                <Box display='flex' gap='40px'>
                  <Box display='flex' gap='1ch'>
                    <Typography variant='body2' color='secondary'>{text.typeGroup}:</Typography>
                    <Typography variant='body2' color='primary'>{text[classifier.type]}</Typography>
                  </Box>

                  <Box display='flex' gap='1ch'>
                    <Typography variant='body2' color='secondary'>{text.code}:</Typography>
                    <Typography variant='body2' color='primary'>{classifier.codeParameter}</Typography>
                  </Box>
                </Box>

                <Link variant='body2' {...POINTER} onClick={() => handleClick(classifier)}>
                  {text.route}
                </Link>
              </SearchItem>
              <Divider />
            </Fragment>
          )
        })
        }

      </ResultsBlock>
    </>
  )
}

const SearchItem = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 20px 0;
`
const ResultsBlock = styled('div')`
  max-height: calc(100vh - 440px);
  height: 100%;
  overflow-y: auto;
`
