import { Box, Dialog, IconButton, Link, styled, TextField, Typography } from '@mui/material'
import { useAddLicenseMutation, useDeleteLicenseMutation, useGetLicenseTypesQuery } from 'api'
import { CloseIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { LICENSE_TAG } from 'modules/app/models'
import { Button } from 'modules/common/components'
import { POINTER } from 'modules/common/constants'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { LicenseGroups } from 'modules/contracts/components'
import { useText } from 'modules/locale'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

type Props = { licenses: LicenseGroups, contractId: string }

export const ChangeLicensesCountButton: FC<Props> = ({ licenses, contractId }) => {
  const [open, setOpen] = useState(false)
  const [licensesInUse, setLicensesInUse] = useState<string[]>([])
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [deleteLicense, { isLoading: deleting, isSuccess: delSuccess, error: delError }] = useDeleteLicenseMutation()
  const [addLicense, { isLoading: adding, isSuccess: addSuccess, error: addError }] = useAddLicenseMutation()
  const { data: licenseTypes } = useGetLicenseTypesQuery()
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setLicensesInUse([])
  }
  const getLicenseName = (tag: LICENSE_TAG) => {
    const found = licenseTypes?.find(l => l.tag === tag)
    if (found) {
      return found.productCode
    }

    return 'unknown'
  }

  const initialValues = useMemo(() => Object.keys(licenses).reduce((acc, key) => {
    acc[key] = licenses[key as LICENSE_TAG].length
    return acc
  }, {} as { [key: string]: number }), [licenses])

  const formik = useFormik({
    initialValues, enableReinitialize: true,
    onSubmit: async (values) => {
      const keysArr = Object.keys(values) as LICENSE_TAG[]

      for (const key of keysArr) {
        if (licenses[key].length === values[key]) {
          // return
        }
        // удаление
        if (licenses[key].length > values[key]) {
          const idsForDelete = licenses[key].slice(-(licenses[key].length - values[key]))

          if (!licensesInUse.length) {
            const found = idsForDelete.filter(it => it.userId)
            if (found.length) {
              setLicensesInUse(found.map(it => it.userId || ''))
              return
            }
          }
          await deleteLicense({ ids: idsForDelete.map(it => it.id), contractId })
        }

        // добавление лицензий
        if (licenses[key].length < values[key]) {
          await addLicense({ contract_id: contractId, license_dict: { [key]: values[key] - licenses[key].length } })
        }
      }
    },
  })
  const { values, handleSubmit, handleChange, resetForm } = formik

  const handleBack = useCallback(() => {
    resetForm()
    setLicensesInUse([])
  }, [resetForm])

  useEffect(() => {
    if (addSuccess || delSuccess) {
      enqueueSnackbar(text.licensesCountChanged, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      handleClose()
      setLicensesInUse([])
    }
  }, [addSuccess, closeSnackbar, delSuccess, enqueueSnackbar, text.licensesCountChanged])
  useEffect(() => {
    if (delError || addError) {
      enqueueSnackbar('ОШИБКА', { variant: 'error', action: ACTION(() => closeSnackbar()) })
      handleBack()
    }
  }, [addError, addSuccess, closeSnackbar, delError, delSuccess, enqueueSnackbar, handleBack, resetForm, text.licensesCountChanged])

  return (
    <>
      <Link {...POINTER} color='secondary' onClick={handleOpen}>{text.changeCount}</Link>

      <Dialog open={open} onClose={(adding || deleting) ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={adding || deleting} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography variant='h2'>{text.changeLicensesCount}</Typography>

        <Box component='form' mt={licensesInUse.length ? '20px' : '40px'} onSubmit={handleSubmit}>
          {licensesInUse.length ? (
            <>
              <Typography variant='body1' color='secondary'>{text.sureToDeleteLicenses}</Typography>
              <Box display='grid' gap='10px' mt='10px'>
                {licensesInUse.map(userId => (<Typography key={userId} variant='body1'>{userId}</Typography>))}
              </Box>
            </>
          ) : (
            <Box display='grid' gap='20px'>
              {Object.keys(licenses).map(key => {
                return (
                  <Box key={key} display='grid' gap='20px' gridTemplateColumns='100px 1fr' alignItems='center'>
                    <Typography variant='body1'>{getLicenseName(key as LICENSE_TAG)}</Typography>
                    <TextField
                      size='small'
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                      name={key}
                      value={values[key]}
                      type='number'
                      sx={{ width: '90px' }}
                      onChange={handleChange}
                    />
                  </Box>
                )
              })}
            </Box>
          )}

          <Box display='flex' gap='20px' mt='40px'>
            <Button
              loading={adding || deleting}
              autoFocus
              fullWidth
              variant='contained'
              type='submit'
            >
              {text.save}
            </Button>
            <Button
              fullWidth
              disabled={adding || deleting}
              variant='outlined'
              onClick={licensesInUse.length ? handleBack : handleClose}
            >
              {text.cancel}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`

