export type LicenseType = {
  product: string,
  productCode: string
  licenseName: string
  tag: LICENSE_TAG
}

export enum LICENSE_TAG {
  EVAL = 'Evaluation',
  COMM = 'Commercial'
}

export const NAME_BY_TAG = {
  [LICENSE_TAG.COMM]: 'IMP-Cls-Comm',
  [LICENSE_TAG.EVAL]: 'IMP-Cls-Eval',
}

export const TAG_BY_NAME: { [key: string]: LICENSE_TAG } = {
  'IMP-Cls-Comm': LICENSE_TAG.COMM,
  'IMP-Cls-Eval': LICENSE_TAG.EVAL,
}
