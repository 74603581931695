import { Box, Skeleton, Typography } from '@mui/material'
import { useGetPublicDocsQuery } from 'api'
import { newArr, randomInt } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'

type Props = unknown

export const ContractOfferDoc: FC<Props> = () => {
  const params = useParams()
  const { text } = useText('USER')
  const { data, isLoading } = useGetPublicDocsQuery({ date: params.sign_date })

  return (
    <Box p='80px 100px'>
      <Typography mb='40px' variant='h1'>{text.contractOffer}</Typography>
      {isLoading
        ? newArr(40).map((key) => <Typography key={key} my='5px'><Skeleton width={`${randomInt(20)}%`} /></Typography>)
        : <ReactMarkdown>{data?.public_offer.text || ''}</ReactMarkdown>
      }
    </Box>
  )
}
