import { ROLE } from 'api/entities/user'
import { LOCALE } from '../constants'

export const USER = {
  [LOCALE.RUSSIAN]: {
    activeLicenses: 'Активные лицензии',
    license: 'Лицензия',
    validity: 'Срок действия',
    admins: 'Администраторы',
    more: 'Подробнее',
    support: 'Техническая поддержка',
    questionsCanBeSent: 'Все вопросы о работе с порталом Вы можете направить по адресу',
    responseTime: 'Срок ответа технической поддержки: 1 рабочий день.',
    profile: 'Профиль',
    name: 'Имя',
    surname: 'Фамилия',
    organizationName: 'Организация',
    position: 'Должность',
    organizationCountries: 'Страна',
    phone: 'Телефон',
    email: 'E-mail',
    password: 'Пароль',
    save: 'Сохранить',
    yourId: 'ваш ID',
    changePhoto: 'Изменить фото',
    noCountriesMessage: 'Список стран пуст',
    editEmail: 'Редактировать e-mail',
    putNewEmail: 'Введите новый e-mail',
    confirm: 'Подтвердить',
    cancel: 'Отмена',
    manualNotCame: 'Не пришло письмо с инструкцией?',
    manualSentToEmail: 'На указанный e-mail направленно письмо c инструкцией. Для активации изменений необходимо пройти по ссылке из письма и авторизоваться в системе под новым e-mail.',
    contactSupport: 'Связаться с техподдержкой',
    editPassword: 'Редактировать пароль',
    changePasswordManualSent: 'На Ваш e-mail направленно письмо c инструкцией о смене пароля. Для активации изменений необходимо пройти по ссылке из письма и авторизоваться в системе с помощью нового пароля.',
    newPassword: 'Новый пароль',
    currentPassword: 'Текущий пароль',
    repeatPassword: 'Повторите новый пароль',
    fileRules: 'Загрузите Вашу фотографию.\n Требования к файлу: размер не более 10Mb, формат: .png, .jpeg',
    selectPhoto: 'Выбрать фото',
    change: 'Изменить',
    avatarSuccessChanged: 'Фото успешно изменено',
    delete: 'Удалить',
    uploadedPhoto: 'Загруженное фото:',
    myLicenses: 'Мои лицензии',
    downloadIMPulse: 'скачать IMPulse',
    owner: 'Владелец',
    status: 'Статус',
    daysToFinish: 'Дней до окончания',
    contractOffer: 'Договор оферты',
    purchaseDate: 'Дата покупки',
    endDate: 'Дата окончания',
    myOrganization: 'Моя организация',
    companyId: 'ID компании',
    from: 'от',
    contacts: 'контакты',
    organizationLicenses: 'Лицензии компании',
    tableShowsAllLicenses: 'Таблица отражает все активные и неактивные лицензии Вашей организации.',
    employee: 'Сотрудник',
    employees: 'Сотрудники',
    totalStaff: 'Всего сотрудников:',
    licensesUsage: 'Использование лицензий',
    availableForAppointment: 'Доступных для назначения:',
    inUse: 'Используются:',
    full: 'Полных',
    trial: 'Триальных',
    putEmployeeEmail: 'Введите e-mail сотрудника',
    invite: 'Пригласить',
    count: 'Количество',
    availableLicenses: 'Доступные лицензии',
    role: 'Роль',
    changeName: 'Изменить название',
    additionalInfo: 'Дополнительная информация',
    corporateClients: 'Корпоративные клиенты',
    totalOrgs: 'Всего организаций:',
    add: 'Добавить',
    organization: 'Организация',
    contractExpires: 'Договор истекает',
    organizationId: 'ID организации',
    allUsers: 'Все пользователи',
    totalUsers: 'Всего пользователей:',
    entryDate: 'Дата входа',
    portalStatistic: 'Статистика портала',
    index: 'Показатель',
    value: 'Значение',
    edit: 'Редактировать',
    access: 'Доступ',
    contractIsActive: 'Активный',
    licenseIsActive: 'Активная',
    contractIsEnded: 'Истек',
    licenseIsEnded: 'Закончилась',
    downloadImpulse: 'Скачать IMPulse',
    download: 'Скачать',
    getAppDownloadLinkError: 'К сожалению не удалось загрузить IMPulse для скачивания',
    organizationContracts: 'Контракты компании',
    administrator: 'Администратор',
    userId: 'ID пользователя',
    deleteUser: 'Удалить пользователя',
    assignDeleting: 'Подтвердите удаление пользователя:',
    userSuccessDeleted: 'Пользователь успешно удален',
    successUserInvitedFirstPart: 'На почту',
    successUserInvitedLastPart: 'направлено приглашение для подключения к IMPulse.',
    couldNotDownloadData: 'Не удалось загрузить данные',
    dataSuccessUpdated: 'Данные успешно обновлены',
    editCompanyInfo: 'Редактировать данные компании',
    comment: 'Комментарий',
    noData: 'Данные отсутствуют',
    contract: 'Контракт',
    licenses: 'Лицензий',
    changeCount: 'Изменить количество',
    changeLicensesCount: 'Изменить количество лицензий',
    licensesCountChanged: 'Количество лицензий успешно изменено',
    editContract: 'Редактировать контракт',
    deleteContract: 'Удалить контракт',
    addContract: 'Добавить контракт',
    address: 'Адрес',
    contractId: 'ID контракта',
    organizationAddedSuccess: 'Организация успешно создана',
    feedbackSent: 'Ваше сообщение отправлено!',
    weWillContactYou: 'Мы свяжемся с вами в ближайшее время.',
    back: 'Назад',
    file: 'Файл',
    successRoleChanged: 'Роль пользователя изменена',
    search: 'Поиск',
    keyPoints: 'Основные положения',
    profileSuccessUpdated: 'Данные успешно обновлены',
    loadedAllData: 'Все данные загружены',
    photoSuccessDeleted: 'Фото успешно удалено',

    users_total: 'Общее количество пользователей',
    companies_total: 'Общее количество компаний',
    contracts_total: 'Общее количество контрактов',
    'lics_total_IMP-Cls-Comm': 'Активных полных лицензий',
    'lics_total_IMP-Cls-Eval': 'Активных триальных лицензий',

    accessToLicenses: 'Доступ к лицензиям',
    termsOfUse: 'Пользовательское соглашение',
    openLicenses: 'Открыть доступ к лицензиям',
    contractEditSuccess: 'Данные контракта успешно изменены',
    sureToDeleteContract: 'Вы уверены что хотите удалить контракт',
    juridicalAddress: 'Юридический адрес',
    site: 'Сайт',
    phone_number: 'Телефон',
    telegram: 'Telegram',
    contacts_name: 'Ваше имя',
    contacts_sender_email: 'Ваш email',
    contacts_theme: 'Тема письма',
    contacts_message: 'Введите Ваше сообщение',
    send: 'Отправить',
    beOnContact: 'Будьте на связи с нами! ',
    userAgreement: 'Пользовательское соглашение',
    publicOffer: 'Публичная оферта',
    confidentialityPolicy: 'Политика конфиденциальности',
    sureToDeleteLicenses: 'Вы уверены, что хотите удалить лицензии? Среди удаляемых лицензий есть те, которые используются сотрудниками компании. Список ID сотрудников можете увидеть ниже:',
    deleteOrganization: 'Удалить организацию',
    assignOrgDeleting: 'Вы действительно хотите удалить организацию? Это действие не обратимо.',
    organizationDeleted: 'Компания успешно удалена',
    actions: 'Действия',
    confirmationCode: 'Код подтверждения',
    enterConfirmationCode: 'Введите код подтверждения',
    next: 'Далее',
    emailChanged: 'Email успешно изменен',
    codeSentToEmail: 'На Ваш email направлено письмо с кодом для подтверждения новой почты. Введите код ниже',
    enterNewEmail: 'Введите новый email. На указанный адрес будет выслан код для подтверждения',
    noContracts: 'У Вас нет активных конрактов',

    // даты контракта
    payment_date: 'Дата оплаты',
    sign_date: 'Дата подписания контракта',
    sub_end_date: 'Дата окончания действия',
    sub_start_date: 'Дата начала действия',

    // роли
    [ROLE.USER]: 'Пользователь',
    [ROLE.USER_PRO]: 'Пользователь PRO',
    [ROLE.ADMIN]: 'Администратор портала',
    [ROLE.ORG_ADMIN]: 'Администратор', // админ организации
  },
  [LOCALE.ENGLISH]: {
    activeLicenses: 'Активные лицензии',
    license: 'Лицензия',
    validity: 'Срок действия',
    admins: 'Администраторы',
    more: 'Подробнее',
    support: 'Техническая поддержка',
    questionsCanBeSent: 'Все вопросы о работе с порталом Вы можете направить по адресу',
    responseTime: 'Срок ответа технической поддержки: 1 рабочий день.',
    profile: 'Профиль',
    name: 'Имя',
    surname: 'Фамилия',
    organizationName: 'Организация',
    position: 'Должность',
    organizationCountries: 'Страна',
    phone: 'Телефон',
    email: 'E-mail',
    password: 'Пароль',
    save: 'Сохранить',
    yourId: 'ваш ID',
    changePhoto: 'Изменить фото',
    noCountriesMessage: 'Список стран пуст',
    editEmail: 'Редактировать e-mail',
    putNewEmail: 'Введите новый e-mail',
    confirm: 'Подтвердить',
    cancel: 'Отмена',
    manualNotCame: 'Не пришло письмо с инструкцией?',
    manualSentToEmail: 'На указанный e-mail направленно письмо c инструкцией. Для активации изменений необходимо пройти по ссылке из письма и авторизоваться в системе под новым e-mail.',
    contactSupport: 'Связаться с техподдержкой',
    editPassword: 'Редактировать пароль',
    changePasswordManualSent: 'На Ваш e-mail направленно письмо c инструкцией о смене пароля. Для активации изменений необходимо пройти по ссылке из письма и авторизоваться в системе с помощью нового пароля.',
    newPassword: 'Новый пароль',
    currentPassword: 'Текущий пароль',
    repeatPassword: 'Повторите новый пароль',
    fileRules: 'Загрузите Вашу фотографию.\n Требования к файлу: размер не более 10Mb, формат: .png, .jpeg',
    selectPhoto: 'Выбрать фото',
    change: 'Изменить',
    avatarSuccessChanged: 'Фото успешно изменено',
    delete: 'Удалить',
    uploadedPhoto: 'Загруженное фото:',
    myLicenses: 'Мои лицензии',
    downloadIMPulse: 'скачать IMPulse',
    owner: 'Владелец',
    status: 'Статус',
    daysToFinish: 'Дней до окончания',
    contractOffer: 'Договор оферты',
    purchaseDate: 'Дата покупки',
    endDate: 'Дата окончания',
    myOrganization: 'Моя организация',
    companyId: 'ID компании',
    from: 'от',
    contacts: 'контакты',
    organizationLicenses: 'Лицензии компании',
    tableShowsAllLicenses: 'Таблица отражает все активные и неактивные лицензии Вашей организации.',
    employee: 'Сотрудник',
    employees: 'Сотрудники',
    totalStaff: 'Всего сотрудников:',
    licensesUsage: 'Использование лицензий',
    availableForAppointment: 'Доступных для назначения:',
    inUse: 'Используются:',
    full: 'Полных',
    trial: 'Триальных',
    putEmployeeEmail: 'Введите e-mail сотрудника',
    invite: 'Пригласить',
    count: 'Количество',
    availableLicenses: 'Доступные лицензии',
    role: 'Роль',
    changeName: 'Изменить название',
    additionalInfo: 'Дополнительная информация',
    corporateClients: 'Корпоративные клиенты',
    totalOrgs: 'Всего организаций:',
    add: 'Добавить',
    organization: 'Организация',
    contractExpires: 'Договор истекает',
    organizationId: 'ID организации',
    allUsers: 'Все пользователи',
    totalUsers: 'Всего пользователей:',
    entryDate: 'Дата входа',
    portalStatistic: 'Статистика портала',
    index: 'Показатель',
    value: 'Значение',
    edit: 'Редактировать',
    access: 'Доступ',
    contractIsActive: 'Активный',
    licenseIsActive: 'Активная',
    contractIsEnded: 'Истек',
    licenseIsEnded: 'Закончилась',
    downloadImpulse: 'Скачать IMPulse',
    download: 'Скачать',
    getAppDownloadLinkError: 'К сожалению не удалось загрузить IMPulse для скачивания',
    organizationContracts: 'Контракты компании',
    administrator: 'Администратор',
    userId: 'ID пользователя',
    deleteUser: 'Удалить пользователя',
    assignDeleting: 'Подтвердите удаление пользователя:',
    userSuccessDeleted: 'Пользователь успешно удален',
    successUserInvitedFirstPart: 'На почту',
    successUserInvitedLastPart: 'направлено приглашение для подключения к IMPulse.',
    couldNotDownloadData: 'Не удалось загрузить данные',
    dataSuccessUpdated: 'Данные успешно обновлены',
    editCompanyInfo: 'Редактировать данные компании',
    comment: 'Комментарий',
    noData: 'Данные отсутствуют',
    contract: 'Контракт',
    licenses: 'Лицензий',
    changeCount: 'Изменить количество',
    changeLicensesCount: 'Изменить количество лицензий',
    licensesCountChanged: 'Количество лицензий успешно изменено',
    editContract: 'Редактировать контракт',
    deleteContract: 'Удалить контракт',
    addContract: 'Добавить контракт',
    address: 'Адрес',
    contractId: 'ID контракта',
    organizationAddedSuccess: 'Организация успешно создана',
    feedbackSent: 'Ваше сообщение отправлено!',
    weWillContactYou: 'Мы свяжемся с вами в ближайшее время.',
    back: 'Назад',
    file: 'Файл',
    successRoleChanged: 'Роль пользователя изменена',
    search: 'Поиск',
    keyPoints: 'Основные положения',
    profileSuccessUpdated: 'Данные успешно обновлены',
    loadedAllData: 'Все данные загружены',
    photoSuccessDeleted: 'Фото успешно удалено',

    users_total: 'Общее количество пользователей',
    companies_total: 'Общее количество компаний',
    contracts_total: 'Общее количество контрактов',
    'lics_total_IMP-Cls-Comm': 'Активных полных лицензий',
    'lics_total_IMP-Cls-Eval': 'Активных триальных лицензий',

    accessToLicenses: 'Доступ к лицензиям',
    termsOfUse: 'Пользовательское соглашение',
    openLicenses: 'Открыть доступ к лицензиям',
    contractEditSuccess: 'Данные контракта успешно изменены',
    sureToDeleteContract: 'Вы уверены что хотите удалить контракт',
    juridicalAddress: 'Юридический адрес',
    site: 'Сайт',
    phone_number: 'Телефон',
    telegram: 'Telegram',
    contacts_name: 'Ваше имя',
    contacts_sender_email: 'Ваш email',
    contacts_theme: 'Тема письма',
    contacts_message: 'Введите Ваше сообщение',
    send: 'Отправить',
    beOnContact: 'Будьте на связи с нами! ',
    userAgreement: 'Пользовательское соглашение',
    publicOffer: 'Публичная оферта',
    confidentialityPolicy: 'Политика конфиденциальности',
    sureToDeleteLicenses: 'Вы уверены, что хотите удалить лицензии? Среди удаляемых лицензий есть те, которые используются сотрудниками компании. Список ID сотрудников можете увидеть ниже:',
    deleteOrganization: 'Удалить организацию',
    assignOrgDeleting: 'Вы действительно хотите удалить организацию? Это действие не обратимо.',
    organizationDeleted: 'Компания успешно удалена',
    actions: 'Действия',
    confirmationCode: 'Код подтверждения',
    enterConfirmationCode: 'Введите код подтверждения',
    next: 'Далее',
    emailChanged: 'Email успешно изменен',
    codeSentToEmail: 'На Ваш email направлено письмо с кодом для подтверждения новой почты. Введите код ниже',
    enterNewEmail: 'Введите новый email. На указанный адрес будет выслан код для подтверждения',
    noContracts: 'У Вас нет активных конрактов',

    // даты контракта
    payment_date: 'Дата оплаты',
    sign_date: 'Дата подписания контракта',
    sub_end_date: 'Дата окончания действия',
    sub_start_date: 'Дата начала действия',

    // роли
    [ROLE.USER]: 'Пользователь',
    [ROLE.USER_PRO]: 'Пользователь PRO',
    [ROLE.ADMIN]: 'Администратор портала',
    [ROLE.ORG_ADMIN]: 'Администратор', // админ организации
  },
  [LOCALE.KAZAKH]: {
    activeLicenses: 'Активные лицензии',
    license: 'Лицензия',
    validity: 'Срок действия',
    admins: 'Администраторы',
    more: 'Подробнее',
    support: 'Техническая поддержка',
    questionsCanBeSent: 'Все вопросы о работе с порталом Вы можете направить по адресу',
    responseTime: 'Срок ответа технической поддержки: 1 рабочий день.',
    profile: 'Профиль',
    name: 'Имя',
    surname: 'Фамилия',
    organizationName: 'Организация',
    position: 'Должность',
    organizationCountries: 'Страна',
    phone: 'Телефон',
    email: 'E-mail',
    password: 'Пароль',
    save: 'Сохранить',
    yourId: 'ваш ID',
    changePhoto: 'Изменить фото',
    noCountriesMessage: 'Список стран пуст',
    editEmail: 'Редактировать e-mail',
    putNewEmail: 'Введите новый e-mail',
    confirm: 'Подтвердить',
    cancel: 'Отмена',
    manualNotCame: 'Не пришло письмо с инструкцией?',
    manualSentToEmail: 'На указанный e-mail направленно письмо c инструкцией. Для активации изменений необходимо пройти по ссылке из письма и авторизоваться в системе под новым e-mail.',
    contactSupport: 'Связаться с техподдержкой',
    editPassword: 'Редактировать пароль',
    changePasswordManualSent: 'На Ваш e-mail направленно письмо c инструкцией о смене пароля. Для активации изменений необходимо пройти по ссылке из письма и авторизоваться в системе с помощью нового пароля.',
    newPassword: 'Новый пароль',
    currentPassword: 'Текущий пароль',
    repeatPassword: 'Повторите новый пароль',
    fileRules: 'Загрузите Вашу фотографию.\n Требования к файлу: размер не более 10Mb, формат: .png, .jpeg',
    selectPhoto: 'Выбрать фото',
    change: 'Изменить',
    avatarSuccessChanged: 'Фото успешно изменено',
    delete: 'Удалить',
    uploadedPhoto: 'Загруженное фото:',
    myLicenses: 'Мои лицензии',
    downloadIMPulse: 'скачать IMPulse',
    owner: 'Владелец',
    status: 'Статус',
    daysToFinish: 'Дней до окончания',
    contractOffer: 'Договор оферты',
    purchaseDate: 'Дата покупки',
    endDate: 'Дата окончания',
    myOrganization: 'Моя организация',
    companyId: 'ID компании',
    from: 'от',
    contacts: 'контакты',
    organizationLicenses: 'Лицензии компании',
    tableShowsAllLicenses: 'Таблица отражает все активные и неактивные лицензии Вашей организации.',
    employee: 'Сотрудник',
    employees: 'Сотрудники',
    totalStaff: 'Всего сотрудников:',
    licensesUsage: 'Использование лицензий',
    availableForAppointment: 'Доступных для назначения:',
    inUse: 'Используются:',
    full: 'Полных',
    trial: 'Триальных',
    putEmployeeEmail: 'Введите e-mail сотрудника',
    invite: 'Пригласить',
    count: 'Количество',
    availableLicenses: 'Доступные лицензии',
    role: 'Роль',
    changeName: 'Изменить название',
    additionalInfo: 'Дополнительная информация',
    corporateClients: 'Корпоративные клиенты',
    totalOrgs: 'Всего организаций:',
    add: 'Добавить',
    organization: 'Организация',
    contractExpires: 'Договор истекает',
    organizationId: 'ID организации',
    allUsers: 'Все пользователи',
    totalUsers: 'Всего пользователей:',
    entryDate: 'Дата входа',
    portalStatistic: 'Статистика портала',
    index: 'Показатель',
    value: 'Значение',
    edit: 'Редактировать',
    access: 'Доступ',
    contractIsActive: 'Активный',
    licenseIsActive: 'Активная',
    contractIsEnded: 'Истек',
    licenseIsEnded: 'Закончилась',
    downloadImpulse: 'Скачать IMPulse',
    download: 'Скачать',
    getAppDownloadLinkError: 'К сожалению не удалось загрузить IMPulse для скачивания',
    organizationContracts: 'Контракты компании',
    administrator: 'Администратор',
    userId: 'ID пользователя',
    deleteUser: 'Удалить пользователя',
    assignDeleting: 'Подтвердите удаление пользователя:',
    userSuccessDeleted: 'Пользователь успешно удален',
    successUserInvitedFirstPart: 'На почту',
    successUserInvitedLastPart: 'направлено приглашение для подключения к IMPulse.',
    couldNotDownloadData: 'Не удалось загрузить данные',
    dataSuccessUpdated: 'Данные успешно обновлены',
    editCompanyInfo: 'Редактировать данные компании',
    comment: 'Комментарий',
    noData: 'Данные отсутствуют',
    contract: 'Контракт',
    licenses: 'Лицензий',
    changeCount: 'Изменить количество',
    changeLicensesCount: 'Изменить количество лицензий',
    licensesCountChanged: 'Количество лицензий успешно изменено',
    editContract: 'Редактировать контракт',
    deleteContract: 'Удалить контракт',
    addContract: 'Добавить контракт',
    address: 'Адрес',
    contractId: 'ID контракта',
    organizationAddedSuccess: 'Организация успешно создана',
    feedbackSent: 'Ваше сообщение отправлено!',
    weWillContactYou: 'Мы свяжемся с вами в ближайшее время.',
    back: 'Назад',
    file: 'Файл',
    successRoleChanged: 'Роль пользователя изменена',
    search: 'Поиск',
    keyPoints: 'Основные положения',
    profileSuccessUpdated: 'Данные успешно обновлены',
    loadedAllData: 'Все данные загружены',
    photoSuccessDeleted: 'Фото успешно удалено',

    users_total: 'Общее количество пользователей',
    companies_total: 'Общее количество компаний',
    contracts_total: 'Общее количество контрактов',
    'lics_total_IMP-Cls-Comm': 'Активных полных лицензий',
    'lics_total_IMP-Cls-Eval': 'Активных триальных лицензий',

    accessToLicenses: 'Доступ к лицензиям',
    termsOfUse: 'Пользовательское соглашение',
    openLicenses: 'Открыть доступ к лицензиям',
    contractEditSuccess: 'Данные контракта успешно изменены',
    sureToDeleteContract: 'Вы уверены что хотите удалить контракт',
    juridicalAddress: 'Юридический адрес',
    site: 'Сайт',
    phone_number: 'Телефон',
    telegram: 'Telegram',
    contacts_name: 'Ваше имя',
    contacts_sender_email: 'Ваш email',
    contacts_theme: 'Тема письма',
    contacts_message: 'Введите Ваше сообщение',
    send: 'Отправить',
    beOnContact: 'Будьте на связи с нами! ',
    userAgreement: 'Пользовательское соглашение',
    publicOffer: 'Публичная оферта',
    confidentialityPolicy: 'Политика конфиденциальности',
    sureToDeleteLicenses: 'Вы уверены, что хотите удалить лицензии? Среди удаляемых лицензий есть те, которые используются сотрудниками компании. Список ID сотрудников можете увидеть ниже:',
    deleteOrganization: 'Удалить организацию',
    assignOrgDeleting: 'Вы действительно хотите удалить организацию? Это действие не обратимо.',
    organizationDeleted: 'Компания успешно удалена',
    actions: 'Действия',
    confirmationCode: 'Код подтверждения',
    enterConfirmationCode: 'Введите код подтверждения',
    next: 'Далее',
    emailChanged: 'Email успешно изменен',
    codeSentToEmail: 'На Ваш email направлено письмо с кодом для подтверждения новой почты. Введите код ниже',
    enterNewEmail: 'Введите новый email. На указанный адрес будет выслан код для подтверждения',
    noContracts: 'У Вас нет активных конрактов',

    // даты контракта
    payment_date: 'Дата оплаты',
    sign_date: 'Дата подписания контракта',
    sub_end_date: 'Дата окончания действия',
    sub_start_date: 'Дата начала действия',

    // роли
    [ROLE.USER]: 'Пользователь',
    [ROLE.USER_PRO]: 'Пользователь PRO',
    [ROLE.ADMIN]: 'Администратор портала',
    [ROLE.ORG_ADMIN]: 'Администратор', // админ организации
  },
} as const

