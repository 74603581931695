import { Box, Typography } from '@mui/material'
import { IContractShortResponse, ROLE, useLazyGetContractsQuery, useLazyGetOrgContractsQuery } from 'api'
import { selectAuth } from 'modules/auth/slice'
import { SimpleTable, TableSkeleton } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { COLUMNS } from './constants'
import { createRows } from './utils'

const example = 'Настоящее Лицензионное соглашение является документом, регулирующим правила использования ПРОГРАММНОГО ПРОДУКТА системы «1С:Предприятие 8», обозначенного выше, физическим или юридическим лицом («Лицензиатом»), обладающим правомерно изготовленным и зарегистрированным в ООО «1С-Софт» экземпляром данного продукта.\n' +
  'ПРОГРАММНЫЙ ПРОДУКТ – это комплект поставки, в состав которого в числе прочего входит комплексное инструментальное средство, состоящее из программных модулей «Информационная система 1С:ИТС», «Монитор портала 1С:ИТС», «Обновления программ», и Лицензионное Соглашение, регулирующее правила использования ПРОГРАММНОГО ПРОДУКТА.\n' +
  'Все исключительные имущественные права на обозначенный ПРОГРАММНЫЙ ПРОДУКТ (собственно программное обеспечение, записанное на машинных носителях, предоставляемые отдельно обновления и дополнения к программному обеспечению, а также любые сопроводительные материалы в печатном или электронном виде) принадлежат ООО «1С-Софт» (далее – «Правообладатель»), зарегистрированному в г. Москве. Устанавливая ПРОГРАММНЫЙ ПРОДУКТ, осуществляя его запись в память ЭВМ, Лицензиат признает себя связанным условиями настоящего Лицензионного соглашения.\n' +
  'По настоящему Лицензионному соглашению Лицензиату предоставляются отдельные неисключительные права, описанные ниже. Лицензиат обязан сохранять Лицензионное соглашение, входящее в комплект поставки ПРОГРАММНОГО ПРОДУКТА, в качестве документа, подтверждающего наличие у него прав, на использование ПРОГРАММНОГО ПРОДУКТА. Правообладатель оставляет за собой право в одностороннем порядке время от времени изменять условия технической поддержки, в том числе предоставления Лицензиату обновлений ПРОГРАММНОГО ПРОДУКТА, путем публикации соответствующего информационного сообщения на сайте Правообладателя.'

export const ContractOffer: FC = () => {
  const { text } = useText('USER')
  const { id } = useParams<{ id: string }>()
  const { my } = useSelector(selectMe)
  const { consoleFor } = useSelector(selectAuth)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [getContracts, { data, isLoading, error }] = useLazyGetOrgContractsQuery()
  const [getContractsByAdmin, {
    data: contractsByAdmin,
    isLoading: isLoading2,
    error: error2,
  }] = useLazyGetContractsQuery()

  const contract: IContractShortResponse = useMemo(() => {
    const contracts = data || contractsByAdmin?.contracts
    const found = contracts?.find(it => it._id === id)
    if (found) {
      return found
    }

    return {} as IContractShortResponse
  }, [contractsByAdmin?.contracts, data, id])

  useEffect(() => {
    if (consoleFor === ROLE.ADMIN && id) {
      getContractsByAdmin({ company_id: id }).unwrap()
    } else {
      getContracts().unwrap()
    }
  }, [consoleFor, getContracts, getContractsByAdmin, id])
  useEffect(() => {
    if (error || error2) {
      console.error(error || error2)
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error, error2])

  return (
    <Box p='80px 100px'>
      <Typography variant='h1'>{text.contractOffer}</Typography>
      <Typography m='20px 0 40px' variant='h2'>{my?.organizationName || ''}</Typography>

      <Box mx='-20px'>
        {(isLoading || isLoading2)
          ? <TableSkeleton columns={5} />
          : <SimpleTable columns={COLUMNS} rows={createRows(contract)} maxHeight='700px' />
        }
      </Box>

      <Typography mt='80px' variant='h1'>{text.keyPoints}</Typography>
      <Typography mt='40px' variant='body1' color='secondary'>{example}</Typography>
    </Box>
  )
}
