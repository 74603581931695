import { Box, styled } from '@mui/material'
import { TableBodySkeleton } from 'modules/common/components/tables/TableBodySkeleton'
import React, { FC, ReactText } from 'react'

type Props = { columns?: number, rows?: number, width?: ReactText }

export const TableSkeleton: FC<Props> = ({ columns = 2, rows = 2, width = '100%' }) => {
  return (
    <Box bgcolor='#F7F7F7' borderRadius='10px' width={width}>
      <Header />
      <TableBodySkeleton columns={columns} rows={rows} />
    </Box>
  )
}

const Header = styled('div')`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #EBEAEA;
  height: 36px;
  width: 100%;
`
