import { Box, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, Typography } from '@mui/material'
import React, { FC } from 'react'

export type TextFieldProps = MuiTextFieldProps & {
  labelColor?: string | 'primary' | 'secondary',
  labelWeight?: number | 'normal',
  labelVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption',
  fullWidth?: boolean,
}

export const TextField: FC<TextFieldProps> = ({ label, labelWeight, labelVariant, labelColor, ...props }) => {
  return (
    <Box width={props.fullWidth ? '100%' : 'auto'} height='auto'>
      <Typography
        hidden={!label}
        mb='5px'
        color={labelColor || 'secondary'}
        variant={labelVariant ?? 'body2'}
        fontWeight={labelWeight}
      >
        {label}
      </Typography>
      <MuiTextField {...props} placeholder={props.placeholder ?? label as string} />
    </Box>
  )
}
