import { ISignUpByInvitationInput } from 'api'
import { SignUpValues } from 'modules/auth/components/SignUp/models'

export const normalizeValues = (data: SignUpValues, guid: string): ISignUpByInvitationInput => {
  return {
    email_login: data.email,
    password: data.password,
    contact_info: {
      surname: data.surname,
      name: data.name,
      phone_number: data.phone,
    },
    position: data.position,
    invitation_guid: guid,
  }
}
