import { Box, CircularProgress, MenuItem, styled } from '@mui/material'
import { useGetCountriesQuery } from 'api'
import { useFormik } from 'formik'
import { DateTime } from 'luxon'
import { Button, DatePicker, TextField } from 'modules/common/components'
import { useText } from 'modules/locale'
import React, { FC, useMemo } from 'react'
import * as yup from 'yup'

export type ClassifierFormValues = {
  name: string,
  version: string,
  codes_info_ref: string
  parent_group: string
  description_parameter_name: string
  code_parameter_name: string
  country: string[]
  date: DateTime
}

const initial: ClassifierFormValues = {
  name: '',
  version: '',
  date: DateTime.now(),
  parent_group: '',

  codes_info_ref: '',
  description_parameter_name: '',

  code_parameter_name: '',
  country: [],
}

type Props = {
  initialValues?: ClassifierFormValues,
  onSubmit: (values: ClassifierFormValues) => void,
  isLoading: boolean,
  onCancel: () => void
  btn?: string
}

export const ClassifierForm: FC<Props> = ({ initialValues = initial, onSubmit, isLoading, onCancel, btn }) => {
  const { text } = useText('CLASSIFIERS')
  const { text: err } = useText('ERRORS')
  const { isLoading: cLoading, data: countries, error: cError } = useGetCountriesQuery()

  const validationSchema = useMemo(() => yup.object({
    name: yup.string().required(err.required).min(3, err.min3Letters),
    parent_group: yup.string().required(err.required).min(3, err.min3Letters),
    codes_info_ref: yup.string().required(err.required).min(3, err.min3Letters),
    description_parameter_name: yup.string().required(err.required).min(3, err.min3Letters),
    code_parameter_name: yup.string().required(err.required).min(3, err.min3Letters),
  }), [err.min3Letters, err.required])

  const formik = useFormik<ClassifierFormValues>({
    initialValues, validationSchema,
    onSubmit: async (values) => {
      // const classifier_tree = [{ parent: values.parent_group, name: 'Корневой узел', class: 'Root' }]
      // const date = values.date.toISO()
      await onSubmit(values)
    },
  })
  const { handleSubmit, handleBlur, values, touched, handleChange, errors, setFieldValue } = formik
  const handleDateChange = (field: string, date: DateTime | null) => setFieldValue(field, date)

  return (
    <Form onSubmit={handleSubmit}>

      <TextField
        name='name'
        value={values.name}
        label={text.name}
        fullWidth
        disabled={isLoading}
        error={touched.name && !!errors.name}
        helperText={(touched.name && errors.name) ?? ' '}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        name='version'
        value={values.version}
        label={text.version}
        fullWidth
        disabled={isLoading}
        error={touched.version && !!errors.version}
        helperText={(touched.version && errors.version) ?? ' '}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <DatePicker
        name='date'
        value={values.date}
        label={text.updateDate}
        fullWidth
        disabled={isLoading}
        error={touched.date && !!errors.date}
        helperText={(touched.date && errors.date) ?? ' '}
        onDateChange={handleDateChange}
        onBlur={handleBlur}
      />
      <TextField
        name='parent_group'
        value={values.parent_group}
        label={text.parent_group}
        fullWidth
        disabled={isLoading}
        error={touched.parent_group && !!errors.parent_group}
        helperText={(touched.parent_group && errors.parent_group) ?? ' '}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <TextField
        name='codes_info_ref'
        value={values.codes_info_ref}
        label={text.codes_info_ref}
        fullWidth
        disabled={isLoading}
        error={touched.codes_info_ref && !!errors.codes_info_ref}
        helperText={(touched.codes_info_ref && errors.codes_info_ref) ?? ' '}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        name='description_parameter_name'
        value={values.description_parameter_name}
        label={text.description_parameter_name}
        fullWidth
        disabled={isLoading}
        error={touched.description_parameter_name && !!errors.description_parameter_name}
        helperText={(touched.description_parameter_name && errors.description_parameter_name) ?? ' '}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <TextField
        fullWidth
        select
        SelectProps={{ multiple: true }}
        name='country'
        value={values.country}
        label={text.country}
        disabled={isLoading}
        error={touched.country && !!errors.country}
        helperText={(touched.country && errors.country) ?? ' '}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {cError && <MenuItem value={-1} disabled>{text.countriesErrorMessage}</MenuItem>}
        {(cLoading || !countries) && <MenuItem value={-1} disabled><CircularProgress /></MenuItem>}
        {!countries?.length && <MenuItem disabled value={-1}>{text.noCountriesMessage}</MenuItem>}
        {countries && countries.map((c) => (<MenuItem key={c} value={c}>{c}</MenuItem>))}
      </TextField>

      <TextField
        name='code_parameter_name'
        value={values.code_parameter_name}
        label={text.code_parameter_name}
        fullWidth
        disabled={isLoading}
        error={touched.code_parameter_name && !!errors.code_parameter_name}
        helperText={(touched.code_parameter_name && errors.code_parameter_name) ?? ' '}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <div />

      <Box mt='25px' display='grid' columnGap='20px' gridTemplateColumns='1fr 1fr'>
        <Button loading={isLoading} variant='contained' type='submit'>
          {btn || text.add}
        </Button>
        <Button disabled={isLoading} variant='outlined' onClick={onCancel}>
          {text.cancel}
        </Button>
      </Box>
    </Form>
  )
}
const Form = styled('form')`
  display: grid;
  grid-template-columns: 350px 350px;
  grid-column-gap: 24px;
  grid-row-gap: 4px;
`
