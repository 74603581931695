import { Box, Link, Paper, Skeleton, styled, Typography } from '@mui/material'
import { useGetContactsQuery, useGetUserLicensesQuery } from 'api'
import { SimpleTable, TableSkeleton } from 'modules/common/components'
import { ACTION } from 'modules/errorHandler/Notistack'
import { ROUTE } from 'modules/common/routes'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { COLUMNS } from 'modules/me/components/Info/constants'
import { createRows } from 'modules/me/components/Info/utils'
import { selectMe } from 'modules/me/slice'
import { useSnackbar } from 'notistack'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

export const Info: FC = () => {
  const { text } = useText('USER')
  const { my } = useSelector(selectMe)
  const { data, isLoading, error } = useGetUserLicensesQuery()
  const { data: contacts, isLoading: isContactsLoading } = useGetContactsQuery()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (error) {
      console.error(error)
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  if (!my) {
    return null
  }

  const { organizationName } = my

  return (
    <Box p='40px 100px'>

      <Typography variant='h2'>{text.activeLicenses}</Typography>

      <Box display='flex' gap='30px' m='20px -20px 10px'>
        <Box minWidth='780px'>
          {isLoading
            ? <TableSkeleton />
            : <SimpleTable rows={createRows(data)} columns={COLUMNS} />
          }
        </Box>

        <Card elevation={1}>
          <Typography variant='h2'>{organizationName}</Typography>
          <Typography m='20px 0 10px' variant='subtitle1' color='secondary'>{text.admins}</Typography>
          {my.organizationAdmins.map((ad) => {
            return <Typography key={ad.id} variant='body1'>{`${ad.name} ${ad.surname}`}</Typography>
          })}
        </Card>
      </Box>
      <Link component={RouterLink} to={ROUTE.LICENSES} color='secondary'>{text.more}</Link>

      <Typography mt='40px' variant='h2'>{text.support}</Typography>
      <Box m='20px 0 10px' display='flex' gap='1ch' alignItems='center'>
        <Typography variant='body1' color='secondary'>{text.questionsCanBeSent}</Typography>
        <Link type='email' variant='body1' color='secondary' href={`emailto:${contacts?.email}`}>
          {isContactsLoading ? <Skeleton /> : contacts?.email}
        </Link>
      </Box>

      <Typography variant='body1' color='secondary'>{text.responseTime}</Typography>

    </Box>

  )
}
const Card = styled(Paper)`
  padding: 20px;
  width: 100%;
`
