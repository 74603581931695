import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN

const T = TEXT.USER[locale as Locale]

export const COLUMNS = [
  { id: 'license', label: T.license },
  { id: 'owner', label: T.owner },
  { id: 'status', label: T.status },
  { id: 'daysToFinish', label: T.daysToFinish },
]
