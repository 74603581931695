import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { useEffect } from 'react'

export const useMessage = (message: string | null | undefined | false) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, message])
}
