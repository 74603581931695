import { Box, Divider, Typography } from '@mui/material'
import { ILicenseShortResponse, ROLE } from 'api'
import { LICENSE_TAG, NAME_BY_TAG, TAG_BY_NAME } from 'modules/app/models'
import { selectAuth } from 'modules/auth/slice'
import { TextField } from 'modules/common/components'
import { dateFormat } from 'modules/common/utils'
import { ChangeLicensesCountButton, LicenseGroups, SubRowDates } from 'modules/contracts/components'
import { useText } from 'modules/locale'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

type Props = { licenses: ILicenseShortResponse[], dates: SubRowDates, contractId: string }

export const ContractSubRow: FC<Props> = ({ licenses, dates, contractId }) => {
  const { text } = useText('USER')
  const { consoleFor } = useSelector(selectAuth)
  const initialAcc: LicenseGroups = {
    [LICENSE_TAG.COMM]: [],
    [LICENSE_TAG.EVAL]: [],
  }
  const groups = licenses.reduce((acc, l) => {
    if (contractId && acc[TAG_BY_NAME[l.product_code]]) {
      if (l.attached_to_user_id) {
        acc[TAG_BY_NAME[l.product_code]] = [{
          id: l.license_id,
          userId: l.attached_to_user_id,
        }, ...acc[TAG_BY_NAME[l.product_code]]]
      } else {
        acc[TAG_BY_NAME[l.product_code]] = [...acc[TAG_BY_NAME[l.product_code]], {
          id: l.license_id,
          userId: l.attached_to_user_id,
        }]
      }
    } else {
      acc[TAG_BY_NAME[l.product_code]] = [{ id: l.license_id, userId: l.attached_to_user_id }]
    }

    return acc
  }, initialAcc as LicenseGroups)

  return (
    <div>
      <Box display='flex' gap='690px' alignItems='center'>
        <Box display='grid' gap='10px'>
          {Object.keys(groups).map(key => {
            return (
              <Box key={key} display='grid' gridTemplateColumns='279px 1fr'>
                <Typography variant='body1'>{NAME_BY_TAG[key as LICENSE_TAG]}</Typography>
                <Typography variant='body1'>{groups[key as LICENSE_TAG].length}</Typography>
              </Box>
            )
          })}
        </Box>
        {consoleFor === ROLE.ADMIN && <ChangeLicensesCountButton licenses={groups} contractId={contractId} />}

      </Box>

      <Divider sx={{ my: '20px' }} />

      <Box display='flex' gap='20px'>
        {Object.keys(dates).map((key) => {
          return (
            <TextField
              key={key}
              sx={{ minWidth: '180px' }}
              value={dateFormat(dates[key as keyof SubRowDates])}
              label={text[key as keyof SubRowDates]}
              InputProps={{ readOnly: true }}
            />
          )
        })}
      </Box>
    </div>
  )
}
