import { PopoverOrigin } from '@mui/material/Popover/Popover'

export const HEIGHT = {
  FOOTER: '56px',
  HEADER: '60px',
}

export const POINTER = {
  sx: { cursor: 'pointer' },
}
export const ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}
export const TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: -30,
  horizontal: 'left',
}
