import { Box, Dialog, IconButton, Link, styled, Typography } from '@mui/material'
import { CloseIcon } from 'assets/svg'
import { Button } from 'modules/common/components/index'
import { POINTER } from 'modules/common/constants'
import { useText } from 'modules/locale'
import React, { FC, useState } from 'react'

type Props = {
  userId: string,
  userName: string,
  isDeleting?: boolean,
  onDelete: (userId: string) => void
}

export const UserDeleteButton: FC<Props> = ({ userId, userName, onDelete, isDeleting }) => {
  const { text } = useText('USER')
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleDelete = () => {
    onDelete(userId)
  }

  return (
    <div>
      <Link {...POINTER} onClick={handleOpen}>{text.delete}</Link>

      <Dialog open={open} onClose={handleClose}>
        <StyledIconButton size='small' onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography variant='h2'>{text.deleteUser}</Typography>
        <Typography m='20px 0 10px' variant='body1' color='secondary'>{text.assignDeleting}</Typography>
        <Typography variant='body1' fontWeight={500}>{userName}</Typography>

        <Box display='flex' gap='20px' mt='40px'>
          <Button
            fullWidth
            loading={isDeleting}
            variant='contained'
            onClick={handleDelete}
          >
            {text.delete}
          </Button>
          <Button autoFocus fullWidth variant='outlined' onClick={handleClose}>{text.cancel}</Button>
        </Box>
      </Dialog>
    </div>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
