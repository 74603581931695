import { Dialog, IconButton, Tooltip, Typography } from '@mui/material'
import { useCreateClassifierMutation } from 'api'
import { CreateIcon } from 'assets/svg'
import { ClassifierForm } from 'modules/classifiers/components/ClassifierForm'
import { ClassifierFormValues } from 'modules/classifiers/components/ClassifierForm/ClassifierForm'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import React, { FC, useState } from 'react'

export const ClassifierCreateButton: FC = () => {
  const { text } = useText('CLASSIFIERS')
  const [open, setOpen] = useState(false)
  const [createClassifier, { isLoading, error }] = useCreateClassifierMutation()
  const handleToggleOpen = () => setOpen(prev => !prev)

  const handleSubmit = async (values: ClassifierFormValues) => {
    const classifier_tree = [{ parent: values.parent_group, name: 'Корневой узел', class: 'Root' }]
    const date = values.date.toISO()
    await createClassifier({ ...values, classifier_tree, date }).unwrap()
    handleToggleOpen()
  }

  useShowError(error)

  return (
    <>
      <Tooltip title={text.add}>
        <IconButton size='small' color='inherit' onClick={handleToggleOpen}>
          <CreateIcon />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth={false} open={open}>
        <Typography mb='20px' variant='h2'>{text.addClassifier}</Typography>
        <ClassifierForm isLoading={isLoading} onCancel={handleToggleOpen} onSubmit={handleSubmit} />
      </Dialog>
    </>
  )
}

