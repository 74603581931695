import { LOCALE } from '../constants'

export const HEADER = {
  [LOCALE.RUSSIAN]: {
    main: 'Главная',
    licenses: 'Лицензии',
    classifiers: 'Классификаторы',
    knowledgeBase: 'База знаний',
    portal: 'Портал',
    clients: 'Клиенты',
    profile: 'Профиль',
    signOut: 'Выйти',
    signIn: 'Войти',
    cancel: 'Отмена',
    sureToSignOut: 'Вы уверены что хотите покинуть портал IMPulse?',
    portalConsole: 'Консоль Администратора портала',
    orgConsole: 'Консоль Администратора организации',
    userConsole: 'Консоль Пользователя',
  },
  [LOCALE.ENGLISH]: {
    main: 'Главная',
    licenses: 'Лицензии',
    classifiers: 'Классификаторы',
    knowledgeBase: 'База знаний',
    portal: 'Портал',
    clients: 'Клиенты',
    profile: 'Профиль',
    signOut: 'Выйти',
    signIn: 'Войти',
    cancel: 'Отмена',
    sureToSignOut: 'Вы уверены что хотите покинуть портал IMPulse?',
    portalConsole: 'Консоль Администратора портала',
    orgConsole: 'Консоль Администратора организации',
    userConsole: 'Консоль Пользователя',
  },
  [LOCALE.KAZAKH]: {
    main: 'Главная',
    licenses: 'Лицензии',
    classifiers: 'Классификаторы',
    knowledgeBase: 'База знаний',
    portal: 'Портал',
    clients: 'Клиенты',
    profile: 'Профиль',
    signOut: 'Выйти',
    signIn: 'Войти',
    cancel: 'Отмена',
    sureToSignOut: 'Вы уверены что хотите покинуть портал IMPulse?',
    portalConsole: 'Консоль Администратора портала',
    orgConsole: 'Консоль Администратора организации',
    userConsole: 'Консоль Пользователя',
  },
} as const

