import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'
import * as yup from 'yup'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const ERR = TEXT.ERRORS[locale as Locale]

export const validationSchema = yup.object({
  email: yup.string().required(ERR.required).email(ERR.invalidEmail),
})
