import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ROLE } from 'api'
import { clearTokens } from 'modules/app/actions'
import { getMyConsole } from 'modules/auth/utils'
import { clearMe, getUser } from 'modules/me/actions'

export const setIsAuth = createAction<boolean>('auth/setIsAuth')
export const setConsole = createAction<ROLE>('auth/setConsole')

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    await dispatch(clearMe())
    await dispatch(clearTokens())
    localStorage.clear()
    window.location.href = '/sign-in'
  },
)

export const login = createAsyncThunk(
  'auth/login',
  async (_, { dispatch, rejectWithValue }) => {
    const user = await dispatch(getUser()).unwrap()
    if (user) {
      const consoleType = getMyConsole(user.accessRights.portalConsoleAccess, user.accessRights.organizationConsoleAccess)
      dispatch(setConsole(consoleType))
      return true
    }
    localStorage.clear()
    return rejectWithValue(user)
  },
)

// export const login = createAsyncThunk(
//   'auth/login',
//   async (body: LoginByEmailInput, { dispatch, rejectWithValue }) => {
//     try {
//       const response = await userApi.login(body)
//       if (response) {
//         await dispatch(getUser())
//
//         // eslint-disable-next-line no-console
//         console.log(response)
//         return true
//       }
//
//       return false
//     } catch (e) {
//       const message = Object.getOwnPropertyDescriptor(e, 'message')?.value
//       console.error(e)
//       return rejectWithValue(message)
//     }
//   },
// )
