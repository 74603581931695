import { Box, Link } from '@mui/material'
import { IContractShortResponse } from 'api'
import { DownloadIcon } from 'assets/svg'
import { Row } from 'modules/common/components'
import { ROUTE } from 'modules/common/routes'
import { dateFormat } from 'modules/common/utils'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const createRows = (contract: IContractShortResponse | undefined): Row[] => {
  if (!contract?.licenses) {
    return []
  }
  const licenses = contract.licenses.reduce((acc, it) => {
    if (acc[it.product_code]) {
      acc[it.product_code] += 1
    } else {
      acc[it.product_code] = 1
    }

    return acc
  }, {} as { [key: string]: number })

  return Object.keys(licenses).map(key => {
    // const date = DateTime.fromSQL(contract.sign_date).toISO({ includeOffset: false })
    return {
      license: key,
      signDate: dateFormat(contract.sign_date),
      endDate: dateFormat(contract.sub_end_date),
      count: licenses[key],
      file: (
        <Link
          component={RouterLink}
          to={`${ROUTE.CONTRACT_OFFER_DOC}/${contract.sign_date}`}
          color='secondary'
          underline='none'
        >
          <Box component='span' display='flex' alignItems='center' gap='5px'>
            {key} <DownloadIcon />
          </Box>
        </Link>
      ),
    }
  })
}
