import { Dialog, IconButton, styled, Typography } from '@mui/material'
import { useShareClassifierMutation } from 'api'
import { CloseIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { Button, TextField } from 'modules/common/components'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import React, { FC } from 'react'
import * as yup from 'yup'
import { ClassifierSharedList } from './ClassifierSharedList'

type Props = { open: boolean, id: string, name: string, onClose: () => void }
type Values = { email: string }

export const ClassifierShareDialog: FC<Props> = ({ open, name, id, onClose }) => {
  const { text } = useText('CLASSIFIERS')
  const { text: err } = useText('ERRORS')
  const [shareClassifier, { isLoading, error }] = useShareClassifierMutation()

  const formik = useFormik<Values>({
    initialValues: { email: '' },
    validationSchema: yup.object({ email: yup.string().required(err.required).email(err.invalidEmail) }),
    onSubmit: async ({ email }) => {
      await shareClassifier({ id, share_to: email }).unwrap()
    },
  })

  useShowError(error)

  const { handleSubmit, values, touched, errors, handleChange, handleBlur } = formik

  return (
    <>
      <Dialog keepMounted={false} open={open}>
        <StyledIconButton size='small' disabled={isLoading} onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
        <Typography mb='20px' variant='h2'>{text.shareClassifier} “{name}“</Typography>
        <ClassifierSharedList open={open} id={id} />

        <Form onSubmit={handleSubmit}>
          <TextField
            name='email'
            value={values.email}
            label={text.userEmailOrId}
            fullWidth
            disabled={isLoading}
            error={touched.email && !!errors.email}
            helperText={(touched.email && errors.email) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Button sx={{ px: '32px' }} loading={isLoading} variant='contained' type='submit'>
            {text.share}
          </Button>
        </Form>
      </Dialog>
    </>
  )
}

const Form = styled('form')`
  display: flex;
  align-items: center;
  column-gap: 24px;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
