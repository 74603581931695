import { Box, Link, LinkProps, Skeleton, Typography } from '@mui/material'
import { useGetContactsQuery } from 'api'
import { useText } from 'modules/locale'
import React, { FC } from 'react'

const PROPS: LinkProps = {
  variant: 'subtitle1',
  color: 'primary',
  underline: 'hover',
  target: '_blank',
  rel: 'noopener noreferrer',
}

export const ContactsData: FC = () => {
  const { data, isLoading } = useGetContactsQuery()
  const { text } = useText('USER')

  return (
    <Box display='grid' gap='40px'>
      <div>
        <Typography variant='subtitle1' color='secondary'>{text.phone_number}</Typography>
        <Link href={`tel:${data?.phone_number}`} {...PROPS}>
          {isLoading ? <Skeleton /> : data?.phone_number}
        </Link>
      </div>

      <div>
        <Typography variant='subtitle1' color='secondary'>{text.email}</Typography>
        <Link href={`mailto:${data?.email}`} {...PROPS}>
          {isLoading ? <Skeleton /> : data?.email}
        </Link>
      </div>

      <div>
        <Typography variant='subtitle1' color='secondary'>{text.telegram}</Typography>
        <Link href={data?.telegram} {...PROPS}>
          {isLoading ? <Skeleton /> : data?.telegram}
        </Link>
      </div>

      <div>
        <Typography variant='subtitle1' color='secondary'>{text.address}</Typography>
        <Typography variant='subtitle1' color='primary'>
          {isLoading ? <Skeleton /> : data?.address}
        </Typography>
      </div>

      <div>
        <Typography variant='subtitle1' color='secondary'>{text.site}</Typography>
        <Link href={data?.site} {...PROPS}>
          {isLoading ? <Skeleton /> : data?.site}
        </Link>
      </div>
    </Box>
  )
}
