import { configureStore } from '@reduxjs/toolkit'
import { adminQuery, appQuery, authQuery, classifierQuery, organizationQuery, userQuery } from 'api'
import app from 'modules/app/slice'
import auth from 'modules/auth/slice'
import locale from 'modules/locale/slice'
import me from 'modules/me/slice'
import classifiers from 'modules/classifiers/slice'

const rootReducer = {
  locale, me, auth, app, classifiers,
  [authQuery.reducerPath]: authQuery.reducer,
  [userQuery.reducerPath]: userQuery.reducer,
  [organizationQuery.reducerPath]: organizationQuery.reducer,
  [adminQuery.reducerPath]: adminQuery.reducer,
  [appQuery.reducerPath]: appQuery.reducer,
  [classifierQuery.reducerPath]: classifierQuery.reducer,
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(
        authQuery.middleware,
        userQuery.middleware,
        organizationQuery.middleware,
        adminQuery.middleware,
        appQuery.middleware,
        classifierQuery.middleware,
      )
  },
})

