import { Box, CircularProgress, Container } from '@mui/material'
import { useVerificationQuery } from 'api/entities/auth/mock'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { ROUTE } from 'modules/common/routes'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

type Props = unknown

export const Verification: FC<Props> = () => {
  const params = useParams<{ userId: string }>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { text } = useText('AUTH')
  const { text: errorText } = useText('ERRORS')
  const { data, error } = useVerificationQuery({ userId: params.userId || '' })
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      console.error(error)
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
      navigate(ROUTE.SIGN_IN)
    }
    if (data) {
      enqueueSnackbar(text.successVerified, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      navigate(ROUTE.SIGN_IN, { replace: true })
    }
  }, [closeSnackbar, data, enqueueSnackbar, error, errorText.status502, navigate, text.successVerified])

  return (
    <Container>
      <Box display='flex' justifyContent='center' py='200px'>
        <CircularProgress />
      </Box>
    </Container>
  )
}
