import { Box, Typography } from '@mui/material'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { color } from 'styles/theme'

type Props = unknown

export const OrgInfoHead: FC<Props> = () => {
  const { text } = useText('USER')
  const { my } = useSelector(selectMe)

  return (
    <Box p='80px 100px' mx='auto' width='1400px'>

      <Typography variant='subtitle1' mb='10px' color={color.success}>{text.myOrganization}</Typography>
      <Typography variant='h1' color={color.surface}>{my?.organizationName || ''}</Typography>

      <Box height='40px' />

      <Typography variant='subtitle1' mb='10px' color={color.success}>{text.companyId}</Typography>
      <Typography variant='body1' color={color.surface}>{my?.organizationId || ''}</Typography>

    </Box>
  )
}
