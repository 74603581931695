import React, { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, TextField, Typography } from '@mui/material'
import { Button } from 'modules/common/components'
import { useFormik } from 'formik'
import { CodeNotCame, ResendCode, BackBtn } from 'modules/auth/components'
import { useRestorePasswordMutation } from 'api/entities/auth/mock'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { useText } from 'modules/locale'
import { validationSchema } from './constants'

const initialValues: Values = { email: '' }
type Values = { email: string }

export const RestorePassword: FC = () => {
  const [step, setStep] = useState(1)
  const [handleRestorePassword, { isLoading }] = useRestorePasswordMutation()
  const { text } = useText('AUTH')
  const { text: errorText } = useText('ERRORS')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const handleSendRequest = useCallback(async (values: Values) => {
    const response = await handleRestorePassword(values)
      .unwrap()
      .catch(err => {
        // пользователь еще не подтвердил почту, поэтому приходит ошибка, но письмо на почту продублировано
        if (err.status === 409) {
          setStep(prev => prev + 1)
          return
        }
        console.error(err)
        const message = err.status === 502 ? errorText.status502 : err.data.message
        enqueueSnackbar(message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
      })
    if (response) {
      setStep(prev => prev + 1)
    }
  }, [closeSnackbar, enqueueSnackbar, errorText.status502, handleRestorePassword])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => await handleSendRequest(values),
  })

  const { handleSubmit, handleChange, values, touched, handleBlur, errors } = formik
  const handleBack = () => navigate(-1)

  const handleResendCode = useCallback(async () => {
    await handleSendRequest(values)
  }, [handleSendRequest, values])

  if (step === 2) {
    return <ResendCode title={text.forgotPassword} description={text.manualForRestore} onResend={handleResendCode} />
  }
  if (step === 3) {
    return <CodeNotCame title={text.forgotPassword} description={text.manualResent} questionText={text.manualNotCame} />
  }

  return (
    <>

      <Box display='grid' gap='20px'>
        <Typography variant='h1'>{text.forgotPassword}</Typography>
        <Typography variant='body1' color='secondary'>{text.restoreDescription}</Typography>
      </Box>

      <Box mt='40px' component='form' onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          name='email'
          value={values.email}
          disabled={isLoading}
          label={text.login}
          error={touched.email && !!errors.email}
          helperText={(touched.email && errors.email) ?? ' '}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Box display='grid' gap='20px' mt='40px' gridTemplateColumns='1fr 1fr'>
          <Button
            type='submit'
            loading={isLoading}
            fullWidth
            variant='contained'
            color='primary'
          >
            {text.restore}
          </Button>
          <div />
        </Box>

      </Box>

      <BackBtn onClick={handleBack}>{text.backToMain}</BackBtn>
    </>
  )
}

