export const ROUTE = {
  MAIN: '/',
  RIGHTS: '/rights',
  CONFIDENTIALITY: '/confidentiality',
  CONDITIONS: '/conditions',
  PUBLIC_OFFER: '/public-offer',
  CONTACTS: '/contacts',

  LICENSES: '/licenses',
  KNOWLEDGE_BASE: '/knowledge-base', // такого роута нет - база знаний на другом ресурсе
  PROFILE: '/profile',
  CLIENTS: '/clients',
  CLIENT: '/clients/:id',
  CONTRACT_OFFER: '/contract-offer',
  CONTRACT_OFFER_DOC: '/contract-offer-document',

  CLASSIFIERS: '/classifiers',
  CLASSIFIER: '/classifiers/:id',

  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  RESTORE_PASSWORD: '/restore-password',
  VERIFICATION: '/verification/:userId',
  RESET_PASSWORD: '/reset-password',
  USER_INVITATION: '/user-invitation',

}
