import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Link,
  Menu,
  MenuItem,
  styled,
  Typography,
} from '@mui/material'
import { useGetUserPhotoQuery } from 'api'
import { CloseIcon } from 'assets/svg'
import { Button, UploadButton } from 'modules/common/components'
import { ANCHOR_ORIGIN, POINTER, TRANSFORM_ORIGIN } from 'modules/common/constants'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { deletedFile, uploadFile } from 'modules/files/actions'
import { useText } from 'modules/locale'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { color } from 'styles/theme'

const avaProps = { width: '183px', height: '183px' }

export const AvatarUploader: FC = () => {
  const { data, error, refetch, isLoading } = useGetUserPhotoQuery()
  const [link, setLink] = useState<string | null | undefined>(data?.download_image)
  const [open, setOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleOpen = () => {
    setOpen(true)
    handleCloseMenu()
  }
  const handleClose = () => setOpen(false)
  const handleCloseMenu = () => setAnchorEl(null)
  const handleOpenMenu = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const handleBack = () => setFile(null)
  const handleUpload = (file: File) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setLink(reader.result as string | null)
    }
    reader.readAsDataURL(file)
    setFile(file)
  }
  const handleSubmit = async () => {
    if (data && file) {
      setIsFetching(true)
      const { upload_image: { url, fields: params } } = data
      const response = await uploadFile({ url, params, file })
      if (response) {
        setLink(undefined)
        await refetch()
        enqueueSnackbar(text.avatarSuccessChanged, { variant: 'success', action: ACTION(() => closeSnackbar()) })
        handleClose()
      }
      setIsFetching(false)
    }
  }
  const handleDelete = async () => {
    if (data) {
      setIsFetching(true)
      const { delete_image: url } = data
      const response = await deletedFile(url)
      if (response) {
        await refetch()
        enqueueSnackbar(text.photoSuccessDeleted, { variant: 'success', action: ACTION(() => closeSnackbar()) })
        handleClose()
      }
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  return (
    <>
      {isLoading
        ? <Loader><CircularProgress /></Loader>
        : <Avatar src={link || data?.download_image} variant='rounded' sx={avaProps} />
      }

      <Link
        {...POINTER}
        variant='body2'
        pt='10px'
        display='block'
        color='secondary'
        onClick={handleOpenMenu}
      >
        {text.changePhoto}
      </Link>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
        onClose={handleCloseMenu}
      >
        <StyledItem onClick={handleOpen}>{text.change}</StyledItem>
        <StyledItem onClick={handleDelete}>{text.delete}</StyledItem>
      </Menu>

      <Dialog open={open} onClose={!isFetching ? handleClose : undefined}>
        <StyledIconButton size='small' onClick={!isFetching ? handleClose : undefined}>
          <CloseIcon />
        </StyledIconButton>
        <Typography mb='20px' variant='h2'>{text.changePhoto}</Typography>

        {!file
          ? <Typography variant='body1' color='secondary'>{text.fileRules}</Typography>
          : (
            <>
              <Typography mb='20px' variant='body1' color='secondary'>{text.uploadedPhoto}</Typography>
              <Avatar src={link || undefined} variant='rounded' sx={{ width: '183px', height: '183px' }} />
            </>
          )
        }
        <Box display='flex' gap='20px' mt='40px'>
          {file ? (
            <Button
              autoFocus
              loading={isFetching}
              fullWidth
              variant='contained'
              onClick={handleSubmit}
            >
              {text.save}
            </Button>
          ) : (
            <UploadButton fileType='image/*' autoFocus fullWidth variant='contained' onUpload={handleUpload}>
              {text.selectPhoto}
            </UploadButton>
          )}

          <Button
            fullWidth
            disabled={isFetching}
            variant='outlined'
            onClick={file ? handleBack : handleClose}
          >
            {file ? text.change : text.cancel}
          </Button>
        </Box>
      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
const StyledItem = styled(MenuItem)`
  font-weight: ${({ selected }) => selected ? 500 : 'normal'};
  color: ${({ selected }) => selected ? color.high : color.medium};
  padding: 5px 10px;

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 5px;
  }
`

const Loader = styled('div')`
  width: 183px;
  height: 183px;
  display: grid;
  place-items: center;
  border: solid 1px #9EA4D6;
  border-radius: 10px;
`

