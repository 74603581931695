import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api/api-config'
import { HTTP_METHODS } from 'api/constants'
import {
  IAccessTokenInput,
  IInvitationInfoResponse,
  ISignUpByInvitationInput,
  IToken,
  ITokenRefreshResponse,
  ITokenResponse,
  SignUpInput,
} from 'api/entities/auth/models'
import { createBasicToken, normalizeToken } from 'modules/auth/utils'
import { UpdatePasswordInput } from './models'

// todo: убрать any

export const authQuery = createApi({
  reducerPath: 'authQuery',
  baseQuery,
  endpoints: (build) => ({
    // query для запроса JWT
    getAccessToken: build.query<IToken, IAccessTokenInput>({
      query: (params) => ({ url: 'user/login', headers: { authorization: `Basic ${createBasicToken(params)}` } }),
      transformResponse: (response: { scope: ITokenResponse }) => normalizeToken(response.scope),
    }),

    getAccessRefreshToken: build.query<ITokenRefreshResponse, void>({
      query: () => ({ url: '/user/refresh-access-token' }),
    }),
    signUp: build.mutation<any, SignUpInput>({
      query: (body) => ({ url: 'user/profile', method: HTTP_METHODS.POST, body }),
    }),
    restorePassword: build.mutation<any, { email: string }>({
      query: (body) => ({ url: 'user/reset-password', method: HTTP_METHODS.POST, body }),
    }),
    updatePassword: build.mutation<any, { password: string }>({
      query: (body) => ({ url: 'user/update-password', method: HTTP_METHODS.PATCH, body }),
    }),
    verification: build.query<string, { userId: string }>({
      query: ({ userId }) => ({ url: `user/verification?user_id=${userId}` }),
    }),
    createNewPassword: build.mutation<any, UpdatePasswordInput>({
      query: ({ userId, resetKey, password }) => ({
        url: `user/reset-password?user_id=${userId}&reset_key=${resetKey}`,
        method: HTTP_METHODS.PUT,
        body: { password },
      }),
    }),

    getInvitationInfo: build.query<IInvitationInfoResponse, { guid: string }>({
      query: (params) => ({ url: `user/join-with-invitation-link`, params }),
      transformResponse: (response: { scope: IInvitationInfoResponse }) => response.scope,
    }),
    signUpByInvitation: build.mutation<string, ISignUpByInvitationInput>({
      query: (body) => ({ url: `user/join-with-invitation-link`, method: HTTP_METHODS.POST, body }),
      transformResponse: (response: { scope: string }) => response.scope,
    }),
    acceptInvitation: build.query<string, { guid: string }>({
      query: (params) => ({ url: `user/company/accept-invitation`, params }),
      transformResponse: (response: { scope: string }) => response.scope,
    }),

  }),
})

export const {
  useLazyAcceptInvitationQuery,
  useAcceptInvitationQuery,
  useGetInvitationInfoQuery,
  useLazyGetInvitationInfoQuery,
  useSignUpByInvitationMutation,
  useCreateNewPasswordMutation,
  useGetAccessRefreshTokenQuery,
  useLazyGetAccessTokenQuery,
  useGetAccessTokenQuery,
  useLazyGetAccessRefreshTokenQuery,
  useSignUpMutation,
  useLazyVerificationQuery,
  useVerificationQuery,
  useUpdatePasswordMutation,
  useRestorePasswordMutation,
} = authQuery
