import { Column } from 'modules/common/components'
import { Locale, LOCALE } from 'modules/locale'
import { TEXT } from 'modules/locale/text'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.USER[locale as Locale]

export const COLUMNS: Column[] = [
  { id: 'employee', label: T.employee },
  { id: 'role', label: T.role, noTypography: true },
  { id: 'availableLicenses', label: T.availableLicenses, noTypography: true, align: 'center' },
  { id: 'validity', label: T.validity, noTypography: true, align: 'center' },
]
