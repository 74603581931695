import { Box, Checkbox, Dialog, FormControlLabel, IconButton, styled, Typography } from '@mui/material'
import { useAddContractMutation, useGetLicenseTypesQuery } from 'api'
import { CloseIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { DateTime } from 'luxon'
import { LICENSE_TAG } from 'modules/app/models'
import { Button, DatePicker, DatePickerProps, TextField, TextFieldProps } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { validationSchema } from './constants'

type Props = { companyId: string }

const initialValues = {
  contractId: '',
  sign_date: DateTime.now(),
  payment_date: DateTime.now(),
  sub_start_date: DateTime.now(),
  sub_end_date: DateTime.now(),
  Evaluation: 0,
  Commercial: 0,
  isApproved: true,
}
const commonProps: Partial<DatePickerProps> = {
  fullWidth: true,
  labelWeight: 500,
  labelVariant: 'body1',
  labelColor: 'primary',
}

export const ContractAdd: FC<Props> = ({ companyId }) => {
  const [open, setOpen] = useState(false)
  const { text } = useText('USER')
  const { data: licenseTypes } = useGetLicenseTypesQuery()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [addContract, { isLoading, isSuccess, error }] = useAddContractMutation()
  const getLicenseName = useCallback((tag: LICENSE_TAG) => {
    const found = licenseTypes?.find(it => it.tag === tag)
    if (found) {
      return found.productCode
    }

    return 'tag'
  }, [licenseTypes])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.contractEditSuccess, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      handleClose()
    }
  }, [isSuccess, closeSnackbar, enqueueSnackbar, text.contractEditSuccess])

  const formik = useFormik({
    initialValues, validationSchema, enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        contractId,
        sign_date,
        sub_end_date,
        sub_start_date,
        Commercial,
        Evaluation,
        payment_date,
      } = values
      const contract = {
        company_id: companyId,
        contract_id: contractId,
        sub_start_date: sub_start_date.toISO(),
        sub_end_date: sub_end_date.toISO(),
        sign_date: sign_date.toISO(),
        payment_date: payment_date.toISO(),
        is_approved: values.isApproved,
      }
      const license_dict = { Evaluation, Commercial }
      await addContract({ contract, license_dict }).unwrap()
    },
  })
  const { handleSubmit, values, setFieldValue, handleChange, handleBlur, touched, errors } = formik
  const handleDateChange = (field: string, date: DateTime | null) => {
    setFieldValue(field, date)
  }

  return (
    <>
      <Button variant='outlined' onClick={handleOpen}>{text.addContract}</Button>

      <Dialog open={open} onClose={isLoading ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={isLoading} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography mb='20px' variant='h2'>{text.addContract}</Typography>
        <Box component='form' display='grid' gap='20px' onSubmit={handleSubmit}>
          <TextField
            name='contractId'
            fullWidth
            value={values.contractId}
            label={text.contractId}
            disabled={isLoading}
            required
            error={touched.contractId && !!errors.contractId}
            helperText={(touched.contractId && errors.contractId) ?? ' '}
            onBlur={handleBlur}
            onChange={handleChange}
            {...commonProps as TextFieldProps}
            sx={{ mb: '-15px' }}
          />
          <DatePicker
            {...commonProps}
            label={text.sign_date}
            name='sign_date'
            value={values.sign_date}
            onDateChange={handleDateChange}
          />

          <DatePicker
            {...commonProps}
            label={text.payment_date}
            name='payment_date'
            value={values.payment_date}
            onDateChange={handleDateChange}
          />
          <Box>
            <Typography mb='20px' variant='body1' fontWeight={500}>{text.accessToLicenses}</Typography>

            <Box display='grid' gridTemplateColumns='min-content min-content' gap='20px' alignItems='center'>
              <Typography variant='body1' noWrap>{getLicenseName(LICENSE_TAG.COMM)}</Typography>
              <TextField
                size='small'
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                name={LICENSE_TAG.COMM}
                value={values.Commercial}
                type='number'
                sx={{ width: '90px' }}
                onChange={handleChange}
              />
              <Typography variant='body1' noWrap>{getLicenseName(LICENSE_TAG.EVAL)}</Typography>
              <TextField
                size='small'
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                name={LICENSE_TAG.EVAL}
                value={values.Evaluation}
                type='number'
                sx={{ width: '90px' }}
                onChange={handleChange}
              />
            </Box>
          </Box>

          <DatePicker
            {...commonProps}
            label={text.sub_start_date}
            name='sub_start_date'
            value={values.sub_start_date}
            onDateChange={handleDateChange}
          />

          <DatePicker
            {...commonProps}
            label={text.sub_end_date}
            name='sub_end_date'
            value={values.sub_end_date}
            onDateChange={handleDateChange}
          />

          <FormControlLabel
            name='isApproved'
            control={<Checkbox checked={values.isApproved} onChange={handleChange} />}
            label={text.openLicenses}
            disabled={isLoading}
          />

          <Box display='flex' gap='20px' mt='40px'>
            <Button loading={isLoading} fullWidth variant='contained' type='submit'>
              {text.add}
            </Button>
            <Button fullWidth disabled={isLoading} variant='outlined' onClick={handleClose}>
              {text.cancel}
            </Button>
          </Box>
        </Box>

      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
