import { Box, Dialog, IconButton, InputAdornment, styled, Typography } from '@mui/material'
import { useConfirmUpdateEmailCodeMutation, useUpdateEmailMutation } from 'api'
import { CloseIcon, EditIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { Button, TextField } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { updateEmailAction } from 'modules/me/actions'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

type Props = { value: string }

export const EmailUpdate: FC<Props> = ({ value }) => {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState<number>(1)
  const { text } = useText('USER')
  const { text: errorText } = useText('ERRORS')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [updateEmail, { isSuccess, isLoading, error }] = useUpdateEmailMutation()
  const [confirmCode, {
    isSuccess: isCodeSuccess,
    isLoading: isLoading2,
    error: codeError,
  }] = useConfirmUpdateEmailCodeMutation()
  const dispatch = useDispatch()

  const handleOpen = () => setOpen(true)

  const validationSchema = yup.object({
    email: yup.string().when('step', {
      is: 1,
      then: yup.string().required(errorText.required).email(errorText.invalidEmail),
    }),
    confirmationCode: yup.string().when('step', { is: 2, then: yup.string().required(errorText.required) }),
  })
  const formik = useFormik({
    initialValues: { email: value, confirmationCode: '', step: 1 }, validationSchema,
    onSubmit: async (values) => {
      if (step === 1) {
        await updateEmail({ email: values.email }).unwrap()
        return
      }
      if (values.confirmationCode) {
        await confirmCode({ code: values.confirmationCode }).unwrap()
      }
    },
  })
  const { values, touched, handleBlur, handleChange, handleSubmit, errors, setFieldValue, resetForm } = formik

  useEffect(() => {
    if (isSuccess) {
      setFieldValue('step', 2)
      setStep(2)
    }
    if (isCodeSuccess) {
      enqueueSnackbar(text.emailChanged, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      dispatch(updateEmailAction(values.email))
      setOpen(false)
    }
  }, [closeSnackbar, dispatch, enqueueSnackbar, isCodeSuccess, isSuccess, setFieldValue, text.emailChanged, values.email])
  useEffect(() => {
    if (error || codeError) {
      enqueueSnackbar(getError(error || codeError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, codeError, enqueueSnackbar, error])

  const handleBack = () => {
    setStep(1)
    setFieldValue('step', 1)
  }
  const handleClose = () => {
    setOpen(false)
    setStep(1)
    resetForm()
  }

  const endAdornment = (
    <InputAdornment position='end'>
      <IconButton
        size='small'
        edge='end'
        onClick={handleOpen}
        onMouseDown={handleClose}
      >
        <EditIcon />
      </IconButton>
    </InputAdornment>
  )

  return (
    <>
      <TextField label={text.email} fullWidth value={values.email} InputProps={{ endAdornment, readOnly: true }} />

      <Dialog open={open} onClose={(isLoading || isLoading2) ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={isLoading || isLoading2} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>
        <Typography variant='h2'>{text.editEmail}</Typography>

        <Typography mt='10px' variant='body1' color='secondary'>
          {step === 1 ? text.enterNewEmail : text.codeSentToEmail}
        </Typography>

        <Box component='form' mt='24px' onSubmit={handleSubmit}>
          <Box hidden={step === 2}>
            <TextField
              fullWidth
              label={text.email}
              name='email'
              value={values.email}
              placeholder='E-mail'
              disabled={isLoading}
              error={touched.email && !!errors.email}
              helperText={(touched.email && errors.email) ?? ' '}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <Box hidden={step === 1}>
            <TextField
              fullWidth
              label={text.confirmationCode}
              name='confirmationCode'
              value={values.confirmationCode}
              placeholder={text.confirmationCode}
              disabled={isLoading2}
              error={touched.confirmationCode && !!errors.confirmationCode}
              helperText={(touched.confirmationCode && errors.confirmationCode) ?? ' '}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <Box display='flex' gap='20px' mt='20px'>
            <Button
              autoFocus
              loading={isLoading || isLoading2}
              type='submit'
              fullWidth
              variant='contained'
            >
              {step === 1 ? text.next : text.confirm}
            </Button>
            <Button
              fullWidth
              disabled={isLoading || isLoading2}
              variant='outlined'
              onClick={step === 2 ? handleBack : handleClose}
            >
              {step === 1 ? text.cancel : text.back}
            </Button>
          </Box>
        </Box>

      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
