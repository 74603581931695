import { useGetClassifierTreeQuery, useLazyGetClassifierTreeQuery } from 'api'
import { setTreeItems } from 'modules/classifiers/slice'
import { getParents } from 'modules/classifiers/utils'
import { Search } from 'modules/common/components'
import { useDebounce } from 'modules/common/useDebounce'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import { SEARCH_PARAM } from 'pages/classifiers/constants'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

type Props = { id: string | undefined }
type Option = { value: string, label: string }

export const ClassifiersSearch: FC<Props> = ({ id }) => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [term, setTerm] = useState<string | null>(null)
  const [options, setOptions] = useState<Option[]>()
  const [variant, setVariant] = useState<string | null>(null)
  const debouncedTerm = useDebounce(term, 600)
  const dispatch = useDispatch()
  const { search } = useMemo(() => ({ search: searchParams.get(SEARCH_PARAM.SEARCH) }), [searchParams])

  const { text } = useText('CLASSIFIERS')
  const [searchInTree, { data: treeSearch, isFetching: isSearching, error }] = useLazyGetClassifierTreeQuery()
  const { data: tree } = useGetClassifierTreeQuery({ id: id || '' })
  const liveSearch = useMemo(() => !!id && (variant === 'table'), [id, variant])

  useShowError(error)

  const variants = useMemo(() => {
    if (id && !search) {
      setVariant('table')
      return ([{ label: text.inTable, value: 'table' }, { label: text.inBase, value: 'base' }])
    }

    return undefined
  }, [id, search, text.inBase, text.inTable])

  const handleInBaseSearch = useCallback(() => {
    if (term && (variant !== 'table' || !id)) {
      searchParams.set('search', term)
      navigate({ pathname, search: searchParams.toString() }, { replace: true })
    }
  }, [id, navigate, pathname, searchParams, term, variant])

  const handleVariantChange = useCallback((value: string | null) => {
    setVariant(value)
    setTerm(null)
    setOptions(undefined)
  }, [])
  const handleSearch = (value: string | null) => setTerm(value)

  const handleChoose = useCallback((value: string | null) => {
    if (value) {
      searchParams.set('table_search', value)
      navigate({ pathname, search: searchParams.toString() }, { replace: true })
      const element = treeSearch?.find(it => it.class === value);
      (element && tree && id) && dispatch(setTreeItems([id, ...getParents(tree, element)]))
    }
  }, [dispatch, id, navigate, pathname, searchParams, tree, treeSearch])

  useEffect(() => {
    if (variant === 'table' && !!searchParams.get('search')) {
      searchParams.delete('search')
      navigate({ pathname, search: searchParams.toString() }, { replace: true })
    }
    if (variant === 'base' && !!searchParams.get('table_search')) {
      setOptions(undefined)
      searchParams.delete('table_search')
      navigate({ pathname, search: searchParams.toString() }, { replace: true })
    }
  }, [navigate, pathname, searchParams, variant])

  // поиск по таблице
  useEffect(() => {
    if (debouncedTerm && variant === 'table' && id) {
      searchInTree({ id, search: debouncedTerm })
        .unwrap()
        .then(resp => {
          setOptions(
            resp.map((it) => ({ value: it.class, label: it.name })))
        })
    }
  }, [debouncedTerm, id, searchInTree, variant])

  return (
    <>
      <Search
        fullWidth
        liveSearch={liveSearch}
        value={term}
        variants={id ? variants : undefined}
        options={id ? options : undefined}
        loading={isSearching}
        linkItem
        onSearch={handleSearch}
        onButtonClick={handleInBaseSearch}
        onChoose={handleChoose}
        onVariantChoose={handleVariantChange}
      />
    </>
  )
}
