import { Box, Container, Divider, Skeleton, styled } from '@mui/material'
import { HEIGHT } from 'modules/common/constants'
import React, { FC } from 'react'

export const Skelet: FC = () => {
  return (
    <Box component='main' minHeight={`calc(100vh - ${HEIGHT.HEADER} - ${HEIGHT.FOOTER})`}>

      <Box width='100vw' height='190px' display='flex' bgcolor='#F7F7F7'>
        <Box width='1400px' m='0 auto' p='60px 100px'>
          <Skeleton animation='wave' height={20} width={160} />
          <Skeleton animation='wave' height={60} width={300} />
        </Box>
      </Box>

      <Container disableGutters>
        <Box p='40px 100px'>
          <Skeleton animation='wave' height={40} width={225} />

          <Box display='flex' gap='30px' m='20px -20px 80px'>
            <Box bgcolor='#F7F7F7' borderRadius='10px' width='780px'>
              <CardHeader />
              <Box px='10px'>
                <Box display='grid' gridTemplateColumns='1fr 1fr' alignItems='center' height='60px'>
                  <Skeleton animation='wave' height={20} width={160} />
                  <Skeleton animation='wave' height={20} width={80} />
                </Box>
                <Divider />
                <Box display='grid' gridTemplateColumns='1fr 1fr' alignItems='center' height='65px'>
                  <Skeleton animation='wave' height={20} width={160} />
                  <Skeleton animation='wave' height={20} width={80} />
                </Box>
              </Box>
            </Box>

            <Box bgcolor='#F7F7F7' borderRadius='10px' width='400px' p='10px 20px 20px' height='min-content'>
              <Skeleton animation='wave' height={60} width={225} />
              <Box height='20px' />
              <Skeleton animation='wave' height={20} width={100} />
              <Skeleton animation='wave' height={20} width={160} />
            </Box>
          </Box>

          <Skeleton animation='wave' height={40} width={225} />
          <Box height='20px' />
          <Skeleton animation='wave' height={20} width={780} />
          <Skeleton animation='wave' height={20} width={470} />

        </Box>
      </Container>
    </Box>
  )
}
const CardHeader = styled('div')`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #EBEAEA;
  height: 36px;
  width: 100%;
`
