import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IToken } from 'api'
import { clearTokens, initApp, setTokens } from 'modules/app/actions'
import { RootState } from 'store/models'

export type IState = {
  isLoading: boolean;
  error: string | null,
  isInit: boolean;
  access: string | null,
  refresh: string | null
}
export const initialState: IState = {
  isLoading: false,
  error: null,
  isInit: false,
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
}

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: {
    [setTokens.type]: (state, { payload }: PayloadAction<IToken>) => {
      state.access = payload.access
      state.refresh = payload.refresh
    },
    [clearTokens.type]: (state) => {
      state.access = null
      state.refresh = null
    },

    [initApp.pending.type]: (state) => {
      state.error = null
      state.isLoading = true
    },
    [initApp.fulfilled.type]: (state, { payload }: PayloadAction<boolean>) => {
      state.isInit = payload
      state.isLoading = false
    },
  },
})

export const selectApp = (state: RootState) => state.app
export default slice.reducer
