import { Link, Menu, MenuItem, styled } from '@mui/material'
import { ArrowDownFilled } from 'assets/toggle'
import { POINTER } from 'modules/common/constants'
import { setLanguage } from 'modules/locale/actions'
import { LOCALE } from 'modules/locale/constants'
import { selectLocale } from 'modules/locale/slice'
import React, { FC, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { color } from 'styles/theme'

export const Locale: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { locale } = useSelector(selectLocale)
  const dispatch = useDispatch()

  const handleOpen = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleSelect = (e: MouseEvent<HTMLLIElement>) => {
    dispatch(setLanguage(e.currentTarget.id as typeof locale))
  }
  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    handleSelect(e)
    handleClose()
  }

  return (
    <>
      <Link
        {...POINTER}
        underline='none'
        variant='body1'
        fontWeight={500}
        color={anchorEl ? color.high : color.medium}
        display='flex'
        alignItems='center'
        gap='10px'
        onClick={handleOpen}
      >
        {locale.slice(0, 2).toUpperCase()}
        <Icon opened={anchorEl ? 'on' : ''} />
      </Link>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -10, horizontal: 'left' }}
        onClose={handleClose}
      >
        {Object.values(LOCALE).map((lang) => (
          <StyledItem key={lang} id={lang} selected={lang === locale} onClick={handleClick}>
            {lang.slice(0, 2).toUpperCase()}
          </StyledItem>
        ))}

      </Menu>
    </>
  )
}

const StyledItem = styled(MenuItem)`
  font-weight: ${({ selected }) => selected ? 500 : 'normal'};
  color: ${({ selected }) => selected ? color.high : color.medium};
  padding: 5px 10px;

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 5px;
  }

  &:before {
    display: none;
  }
`
const Icon = styled(ArrowDownFilled)<{ opened: string }>`
  transform: ${({ opened }) => opened ? 'rotate(180deg)' : 'none'};;
`
