import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api/api-config'
import { HTTP_METHODS } from 'api/constants'
import { SearchParams } from 'api/entities/admin/models'
import { IContractShortResponse, ILicensesStatisticResponse } from 'api/entities/contracts'
import { EMPTY_PARAMS } from 'modules/organization/constants'
import {
  IAssignLicenseInput,
  IAssignLicenseResponse,
  IChangeRoleInput,
  IChangeRoleResponse,
  IOrgProfile,
  IOrgUsersResponse,
  IUpdateOrgProfileInput,
} from './models'

// todo: удалить any

export const organizationQuery = createApi({
  reducerPath: 'organizationQuery',
  baseQuery,
  tagTypes: ['Statistic', 'Contract', 'User'],
  endpoints: (build) => ({
    getOrgContracts: build.query<IContractShortResponse[], void>({
      query: () => ({ url: 'company/licenses' }),
      transformResponse: (response: { scope: IContractShortResponse[] }) => response.scope,
      providesTags: ['Contract'],
      keepUnusedDataFor: 10,
    }),
    getOrgLicensesStat: build.query<ILicensesStatisticResponse, void>({
      query: () => ({ url: 'company/licenses/stats' }),
      providesTags: ['Statistic'],
      transformResponse: (response: { scope: ILicensesStatisticResponse }) => response.scope,
    }),
    getOrgUsers: build.query<IOrgUsersResponse, SearchParams | void>({
      query: (params) => ({ url: 'company/users', params: params ? { ...params } : { ...EMPTY_PARAMS } }),
      transformResponse: (response: { scope: IOrgUsersResponse }) => response.scope,
      providesTags: ['User'],
    }),
    getOrgProfile: build.query<IOrgProfile, void>({
      query: () => ({ url: 'company/profile' }),
    }),
    assignLicenseToUser: build.mutation<IAssignLicenseResponse, IAssignLicenseInput>({
      query: ({ licenseId, userId }) => ({
        url: 'company/licenses',
        method: HTTP_METHODS.PATCH,
        params: { user_id: userId, license_id: licenseId },
      }),
      invalidatesTags: ['Statistic', 'Contract', 'User'],
      // Функция типа optimisticResponse - если не словили ошибку - обновляем данные в кэше, до обновления списка
      onQueryStarted({ licenseId, userId }, { dispatch, queryFulfilled }) {
        const result = dispatch(organizationQuery.util.updateQueryData('getOrgContracts', undefined, cache => {
          return cache?.map(contract => {
            const foundLicense = contract.licenses.find(l => l.license_id === licenseId)
            if (foundLicense) {
              const licenses = contract.licenses.map(l => {
                if (l.license_id === licenseId) {
                  return { ...l, attached_to_user_id: userId || null }
                }

                return l
              })
              return { ...contract, licenses }
            }

            return contract
          })
        }))
        queryFulfilled.catch(result.undo)
      },
    }),
    changeOrgUserRole: build.mutation<IChangeRoleResponse, IChangeRoleInput>({
      query: ({ role, userId }) => ({
        url: 'company/users',
        method: HTTP_METHODS.PUT,
        body: { user_id: userId, role },
      }),
      invalidatesTags: ['User'],
    }),
    deleteOrgUser: build.mutation<{ user_id: string }, { userId: string }>({
      query: ({ userId }) => ({
        url: 'company/users',
        method: HTTP_METHODS.DELETE,
        params: { user_id: userId },
      }),
      invalidatesTags: ['User'],
    }),
    inviteUser: build.mutation<string, { email: string }>({
      query: ({ email }) => ({
        url: 'company/users/invitations',
        method: HTTP_METHODS.POST,
        body: { email_login: email },
      }),
    }),
    deleteInvitation: build.mutation<string, { guid: string }>({
      query: ({ guid }) => ({
        url: 'company/users/invitations',
        method: HTTP_METHODS.DELETE,
        params: { guid },
      }),
    }),
    updateOrgProfile: build.mutation<any, IUpdateOrgProfileInput>({
      query: (body) => ({
        url: 'company/profile',
        method: HTTP_METHODS.PATCH,
        body,
      }),
    }),
  }),
})

export const {
  useGetOrgContractsQuery,
  useGetOrgUsersQuery,
  useGetOrgLicensesStatQuery,
  useGetOrgProfileQuery,

  useLazyGetOrgContractsQuery,
  useLazyGetOrgUsersQuery,
  useLazyGetOrgLicensesStatQuery,
  useLazyGetOrgProfileQuery,

  useAssignLicenseToUserMutation,
  useChangeOrgUserRoleMutation,
  useDeleteInvitationMutation,
  useDeleteOrgUserMutation,
  useInviteUserMutation,
  useUpdateOrgProfileMutation,
} = organizationQuery
