import { Box, Link, Typography } from '@mui/material'
import { useText } from 'modules/locale'
import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

type Props = { title: string; description: string; questionText: string }

// todo: сделать ссылку на форму обртной связи
export const CodeNotCame: FC<Props> = ({ title, description, questionText }) => {
  const { text } = useText('AUTH')
  return (
    <>
      <Box display='grid' gap='20px'>
        <Typography variant='h1'>{title}</Typography>
        <Typography variant='body1' color='secondary'>{description}</Typography>
      </Box>

      <Box display='grid' gap='10px' mt='80px'>
        <Typography variant='body1' fontWeight={500}>{questionText}</Typography>
        <Link component={RouterLink} to='#' color='secondary'>{text.contactSupport}</Link>
      </Box>
    </>
  )
}

