import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { initApp } from 'modules/app/actions'
import { selectApp } from 'modules/app/slice'
import { Auth } from 'modules/auth'
import { selectAuth } from 'modules/auth/slice'
import { Authorized } from 'modules/authorized'
import { Header } from 'modules/common/components'
import { Footer } from 'modules/common/components/Footer'
import { Loader } from 'modules/common/components/Loader'
import { ROUTE } from 'modules/common/routes'
import { CommonPages } from 'pages'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const jwt = localStorage.getItem('access')

export function App() {
  const dispatch = useDispatch()
  const { isAuth } = useSelector(selectAuth)
  const { isInit } = useSelector(selectApp)
  const { pathname } = useLocation()
  const routes = [ROUTE.CONDITIONS, ROUTE.CONTACTS, ROUTE.PUBLIC_OFFER, ROUTE.CONFIDENTIALITY]

  useEffect(() => {
    dispatch(initApp())
  }, [dispatch])

  if (pathname.split('/')[1] === ROUTE.USER_INVITATION.slice(1)) {
    return (
      <>
        <Auth />
        <Footer />
      </>
    )
  }

  if (!isInit && jwt) {
    return (
      <>
        <Box display='flex' alignItems='center' height='100vh'>
          <Loader />
        </Box>
      </>
    )
  }
  if (routes.includes(pathname)) {
    return (
      <>
        <Header />
        <CommonPages />
        <Footer />
      </>
    )
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='ru'>
        {isAuth ? <Authorized /> : <Auth />}
        <Footer />
      </LocalizationProvider>
    </>

  )
}
