import { Box } from '@mui/material'
import {
  IOrganizationUser,
  ROLE,
  useChangeOrgUserRoleMutation,
  useDeleteOrgUserMutation,
  useGetOrgContractsQuery,
  useGetOrgLicensesStatQuery,
  useGetOrgUsersQuery,
  useLazyGetOrgUsersQuery,
} from 'api'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { ContractsTable } from 'modules/contracts'
import { useText } from 'modules/locale'
import { EmployeesTable } from 'modules/organization/components'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { normalizeTableUsers } from './utils'

export const OrganizationLicenses: FC = () => {
  const [isSearchResults, setIsSearchResults] = useState(false)
  const [limit, setLimit] = useState(15)
  const { text } = useText('USER')
  const [tableData, setTableData] = useState<IOrganizationUser[]>()
  const { data: contracts, isLoading: cLoading, error: cError } = useGetOrgContractsQuery()
  const { data: users, error: uError, isLoading: uLoading, isFetching } = useGetOrgUsersQuery({ limit })
  const [searchUsers, { data: searchData, isFetching: isSearching }] = useLazyGetOrgUsersQuery()
  const { data: statistic, error: sError } = useGetOrgLicensesStatQuery()
  const [deleteUser, { isLoading: isDeleting, isSuccess, error: delError }] = useDeleteOrgUserMutation()
  const [changeRole, { isLoading: isRoleChanging, error: roleChError, isSuccess: isRoleChanged }] = useChangeOrgUserRoleMutation()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const options = useMemo(() => {
    return searchData?.users.map(it => ({
      value: it.user_id,
      label: `${it.contact_info.name} ${it.contact_info.surname}`,
    }))
  }, [searchData?.users])

  useEffect(() => {
    if (users) {
      setTableData(users.users)
    }
  }, [users])
  useEffect(() => {
    if (cError || uError || sError) {
      console.error(cError || uError || sError)
      enqueueSnackbar('ОШИБКА', { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, cError, uError, sError])

  // изм роли
  useEffect(() => {
    if (roleChError) {
      enqueueSnackbar(getError(roleChError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, roleChError])

  // удаление
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.userSuccessDeleted, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
    if (isRoleChanged) {
      enqueueSnackbar(text.successRoleChanged, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, isRoleChanged, isSuccess, text.successRoleChanged, text.userSuccessDeleted])
  useEffect(() => {
    if (delError) {
      console.error(delError)
      enqueueSnackbar(getError(delError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, delError, enqueueSnackbar])

  const handleDeleteUser = useCallback(async (userId: string) => {
    await deleteUser({ userId }).unwrap()
  }, [deleteUser])
  const handleChangeRole = useCallback(async (userId: string, role: ROLE) => {
    await changeRole({ userId, role }).unwrap()
  }, [changeRole])
  const handleFetchMore = useCallback(() => {
    if (!isSearchResults) {
      setLimit(prev => prev + 15)
    }
  }, [isSearchResults])
  const handleSearch = useCallback(async (value: string | null) => {
    if (value) {
      await searchUsers({ search_query: value }).unwrap()
    }
  }, [searchUsers])
  const handleChoose = useCallback((value: string | null) => {
    if (!value) {
      setIsSearchResults(false)
      setTableData(users?.users || [])
      return
    }
    setIsSearchResults(true)
    setTableData(searchData?.users.filter(it => it.user_id === value) || [])
  }, [searchData?.users, users?.users])

  return (
    <Box p='80px 100px' display='grid' gap='80px'>

      <ContractsTable data={contracts} isLoading={cLoading} />

      <EmployeesTable
        options={options}
        users={normalizeTableUsers(tableData)}
        total={users?.users_total_number}
        isLoading={uLoading || cLoading}
        contracts={contracts}
        statistic={statistic}
        isFetching={isFetching}
        isSearching={isSearching}
        isDeleting={isDeleting}
        isRoleChanging={isRoleChanging}
        onChoose={handleChoose}
        onSearch={handleSearch}
        onFetchMore={handleFetchMore}
        onUserDelete={handleDeleteUser}
        onRoleChange={handleChangeRole}
      />
    </Box>
  )
}
