import { Box, CircularProgress, MenuItem, Typography } from '@mui/material'
import { useGetCountriesQuery } from 'api'
import { useFormik } from 'formik'
import { Button, TextField } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { AvatarUploader } from 'modules/files/components/AvatarUploader'
import { useText } from 'modules/locale'
import { clearSuccessField, updateUserProfile } from 'modules/me/actions'
import { EmailUpdate } from 'modules/me/components/EmailUpdate'
import { PasswordUpdate } from 'modules/me/components/PasswordUpdate'
import { UpdateProfileValues } from 'modules/me/models'
import { selectMe } from 'modules/me/slice'
import React, { FC, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { blue } from 'styles/theme'
import { validationSchema } from './constants'

export const Profile: FC = () => {
  const {
    my,
    isLoading,
    errors: { updateUserProfile: error },
    success: { updateProfile: success },
  } = useSelector(selectMe)
  const { text } = useText('USER')
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { isLoading: cLoading, data: countries, error: cError } = useGetCountriesQuery()

  const initialValues = {
    name: my?.name || '',
    surname: my?.surname || '',
    organizationName: my?.organizationName || '',
    position: my?.position || '',
    organizationCountries: my?.organizationCountries || [],
    phone: my?.phone || '',
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  useEffect(() => {
    if (success) {
      enqueueSnackbar(text.profileSuccessUpdated, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, success, text.profileSuccessUpdated])

  const formik = useFormik({
    initialValues, validationSchema, enableReinitialize: true,
    onSubmit: async (values: UpdateProfileValues) => {
      await dispatch(updateUserProfile({
        ...values,
        organizationCountries: Array.isArray(values.organizationCountries) ? values.organizationCountries : [values.organizationCountries],
      }))
    },
  })

  useEffect(() => {
    return () => {
      dispatch(clearSuccessField('updateProfile'))
    }
  }, [dispatch])

  if (!my) {
    return null
  }

  const { values, handleBlur, handleChange, handleSubmit, touched, errors } = formik

  return (
    <Box p='80px 100px'>
      <Typography variant='h1'>{text.profile}</Typography>

      <Box display='flex' gap='120px'>
        <Box component='form' mt='40px' width='590px' onSubmit={handleSubmit}>
          <Box display='grid' mb='40px' rowGap='5px' columnGap='20px' gridTemplateColumns='1fr 1fr'>

            {Object.keys(initialValues).slice(0, 4).map((field) => {
              return (
                <TextField
                  key={field}
                  fullWidth
                  required
                  name={field}
                  value={values[field as keyof typeof initialValues]}
                  label={text[field as keyof typeof initialValues]}
                  disabled={isLoading}
                  error={touched[field as keyof typeof initialValues] && !!errors[field as keyof typeof initialValues]}
                  helperText={(touched[field as keyof typeof initialValues] && errors[field as keyof typeof initialValues]) ?? ' '}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )
            })}

            <TextField
              fullWidth
              select
              name='organizationCountries'
              value={my.organizationCountries}
              label={text.organizationCountries}
              disabled={isLoading}
              error={touched.organizationCountries && !!errors.organizationCountries}
              helperText={(touched.organizationCountries && errors.organizationCountries) ?? ' '}
              InputProps={{ readOnly: true }}
              // onChange={handleChange}
              // onBlur={handleBlur}
            >
              {cError && <MenuItem value={-1} disabled>{error}</MenuItem>}
              {(cLoading || !countries) && <MenuItem value={-1} disabled><CircularProgress /></MenuItem>}
              {!countries?.length && <MenuItem disabled value={-1}>{text.noCountriesMessage}</MenuItem>}
              {countries && countries.map((c) => (<MenuItem key={c} value={c}>{c}</MenuItem>))}
            </TextField>

            <NumberFormat
              customInput={TextField}
              format='+7 (###) ### ## ##'
              fullWidth
              required
              name='phone'
              value={values.phone}
              label={text.phone}
              disabled={isLoading}
              error={touched.phone && !!errors.phone}
              helperText={(touched.phone && errors.phone) ?? ' '}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <EmailUpdate value={my.email} />
            <PasswordUpdate />

          </Box>

          <Box width='30%'>
            <Button fullWidth loading={isLoading} type='submit' variant='contained'>{text.save}</Button>
          </Box>
        </Box>

        <Box>
          <Typography variant='subtitle1' color={blue[800]}>{text.yourId}</Typography>
          <Typography variant='body1' mt='5px' mb='40px'>{my.id}</Typography>
          <AvatarUploader />
        </Box>

      </Box>

    </Box>
  )
}
