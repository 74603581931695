import { IUpdatePasswordInput, IUpdateUserInput, IUserResponse } from 'api/entities/user/models'
import { get, patch, put } from 'api/methods'

export const userApi = {
  requestUser: () => {
    return get<IUserResponse>(`user/profile`)
  },

  updateUser: (body: IUpdateUserInput) => {
    return put<IUpdateUserInput>(`user/profile`, body)
  },

  updatePassword: (body: IUpdatePasswordInput) => {
    return patch<{ message: string }>(`user/update-password`, body)
  },

}

