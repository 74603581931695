import { styled } from '@mui/material'
import { ROLE } from 'api/entities/user'
import { selectAuth } from 'modules/auth/slice'
import { EditableInfoHead, OrgInfoHead, UserInfoHead } from 'modules/authorized/components/GradientHead'
import { ROUTE } from 'modules/common/routes'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const GradientHead: FC = () => {
  const { consoleFor } = useSelector(selectAuth)
  const { pathname } = useLocation()
  const id = useMemo(() => pathname.split('/')[2], [pathname])

  if (pathname === `${ROUTE.CLIENTS}/${id}` && consoleFor === ROLE.ADMIN) {
    return (
      <Wrapper>
        <EditableInfoHead id={id} />
      </Wrapper>
    )
  }

  if (pathname === ROUTE.PROFILE && consoleFor === ROLE.ORG_ADMIN) {
    return (
      <Wrapper>
        <OrgInfoHead />
      </Wrapper>
    )
  }

  if (pathname === ROUTE.MAIN) {
    return (
      <Wrapper>
        <UserInfoHead />
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled('div')`
  width: 100vw;
  min-width: 1400px;
  background: linear-gradient(79.16deg, #2C338D 1.44%, #3497B7 124.62%);
  display: flex;
`
