import { Box, Link, Paper, styled, Typography } from '@mui/material'
import { useGetPortalStatsQuery } from 'api'
import { Row, SimpleTable, TableSkeleton } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { ROUTE } from 'modules/common/routes'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { COLUMNS } from './constants'

export const Statistic: FC = () => {
  const { text } = useText('USER')
  const { my } = useSelector(selectMe)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { data, error, isLoading } = useGetPortalStatsQuery()

  useEffect(() => {
    if (error) {
      console.error(error)
      enqueueSnackbar('ОШИБКА', { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  const ROWS: Row[] = useMemo(() => {
    if (data) {
      return Object.keys(data).map(key => {
        if (key === 'lics_total') {
          return data.lics_total.map(it => ({ index: text[`lics_total_${it.type}`], value: it.count }))
        }

        return { index: text[key], value: data[key as keyof typeof data] }
      }).flat()
    }

    return []
  }, [data, text])

  if (!my) {
    return null
  }

  const { organizationName } = my

  return (
    <Box p='40px 100px'>

      <Typography variant='h2'>{text.portalStatistic}</Typography>

      <Box display='flex' gap='30px' m='20px -20px 10px'>
        <Box minWidth='780px'>
          {isLoading
            ? <TableSkeleton rows={5} columns={2} width='780px' />
            : <SimpleTable rows={ROWS} columns={COLUMNS} />
          }

        </Box>

        <Card elevation={1}>
          <Typography variant='h2'>{organizationName}</Typography>
          <Typography m='20px 0 10px' variant='subtitle1' color='secondary'>{text.admins}</Typography>
          {my.organizationAdmins.map((ad) => {
            return <Typography key={ad.id} variant='body1'>{`${ad.name} ${ad.surname}`}</Typography>
          })}
        </Card>
      </Box>

      <Link component={RouterLink} color='secondary' to={ROUTE.CLIENTS}>{text.more}</Link>

    </Box>
  )
}
const Card = styled(Paper)`
  padding: 20px;
  width: 100%;
  height: min-content;
`

