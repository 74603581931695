import { Column } from 'modules/common/components'
import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN

const T = TEXT.USER[locale as Locale]

export const COLUMNS: Column[] = [
  { id: 'license', label: T.license },
  { id: 'signDate', label: T.sign_date },
  { id: 'endDate', label: T.endDate },
  { id: 'count', label: T.count },
  { id: 'file', label: T.file, noTypography: true },
]
