import { Box, Typography } from '@mui/material'
import { IContractShortResponse, ILicensesStatisticResponse, ROLE } from 'api'
import { CollapsibleTable, Option, Search, SendInvitationForm, TableSkeleton } from 'modules/common/components'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import { LicensesStatisticCard } from 'modules/organization/components'
import { ITableUser } from 'modules/organization/components/EmployeesTable/models'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { COLUMNS } from './constants'
import { createRows } from './utils'

type Props = {
  contracts: IContractShortResponse[] | undefined
  users: ITableUser[] | undefined,
  total: number | undefined,
  statistic: ILicensesStatisticResponse | undefined
  isLoading?: boolean
  isFetching?: boolean
  onFetchMore: () => void
  // поиск
  isSearching?: boolean
  onSearch?: (value: string | null) => void
  onChoose?: (value: string | null) => void
  options?: Option[]
  // удаление
  isDeleting: boolean,
  onUserDelete: (userId: string) => void
  // изм роли
  isRoleChanging: boolean,
  onRoleChange: (userId: string, role: ROLE) => void
}

export const EmployeesTable: FC<Props> = ({
  contracts,
  isSearching,
  options,
  onSearch,
  onChoose,
  isFetching,
  statistic,
  isLoading,
  users,
  total,
  onFetchMore,
  isDeleting,
  onUserDelete, onRoleChange, isRoleChanging,
}) => {
  const { text } = useText('USER')
  const { my } = useSelector(selectMe)
  const { id } = useParams<{ id: string }>()

  const rows = useMemo(() => {
    return createRows(users, contracts, my?.id, onRoleChange, isRoleChanging, onUserDelete, isDeleting, id)
  }, [contracts, id, isDeleting, isRoleChanging, my?.id, onRoleChange, onUserDelete, users])

  return (
    <div>
      <Typography variant='h1'>{text.employees}</Typography>
      <Box display='flex' gap='10px' alignItems='center'>
        <Typography my='20px' variant='subtitle1' color='secondary'>{text.totalStaff}</Typography>
        <Typography variant='subtitle1' color='primary' fontWeight={500}>{total || ''}</Typography>
      </Box>
      <Box mx='-20px'>
        <LicensesStatisticCard statistic={statistic} />
      </Box>

      <Box mt='40px' mb='24px' display='flex' justifyContent='space-between'>
        <SendInvitationForm />
        <Search options={options} loading={isSearching} onSearch={onSearch} onChoose={onChoose} />
      </Box>

      <Box mx='-20px'>
        {(isLoading)
          ? <TableSkeleton columns={4} rows={4} />
          : (
            <CollapsibleTable
              iconAlign='flex-start'
              rows={rows}
              columns={COLUMNS}
              maxHeight='700px'
              fetching={isFetching}
              total={total}
              fetchData={onFetchMore}
            />
          )}
      </Box>
    </div>
  )
}
