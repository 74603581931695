import { Column } from 'modules/common/components'
import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.USER[locale as Locale]

export const COLUMNS: Column[] = [
  { id: 'organization', label: T.organization, noTypography: true },
  { id: 'license', label: T.license, noTypography: true },
  { id: 'count', label: T.count, noTypography: true },
  { id: 'contractExpires', label: T.contractExpires, noTypography: true },
  { id: 'organizationId', label: T.organizationId, noWrap: true },
  { id: 'actions', label: T.delete, noTypography: true, justify: 'center' },
]
