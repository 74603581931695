import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'
import * as yup from 'yup'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const ERR = TEXT.ERRORS[locale as Locale]

export const validationSchema = yup.object({
  contractId: yup.string().required(ERR.required),
  sub_start_date: yup.string().required(ERR.required),
  sub_end_date: yup.string().required(ERR.required),
  sign_date: yup.string().required(ERR.required),
  payment_date: yup.string().required(ERR.required),
})
