import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import { useDeleteClassifierSharingMutation, useLazyGetClassifiersSharingQuery } from 'api'
import { DeleteIcon } from 'assets/svg'
import { TableBodySkeleton } from 'modules/common/components'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import React, { FC, Fragment, MouseEvent, useEffect, useState } from 'react'

type Props = { open: boolean, id: string }

export const ClassifierSharedList: FC<Props> = ({ open, id }) => {
  const { text } = useText('CLASSIFIERS')
  const [getClassifierObservers, { data, isLoading, error }] = useLazyGetClassifiersSharingQuery()
  const [deletingId, setId] = useState<string>('')
  const [deleteSharing, { isLoading: isDeleting, error: deleteError }] = useDeleteClassifierSharingMutation()
  const handleDeleteSharing = async (e: MouseEvent<HTMLButtonElement>) => {
    const userId = e.currentTarget.id
    setId(userId)
    await deleteSharing({ id, userId }).unwrap()
    setId('')
  }

  useShowError(error)
  useShowError(deleteError)

  useEffect(() => {
    if (open) {
      getClassifierObservers({ id }).unwrap()
    }
  }, [getClassifierObservers, id, open])

  if (isLoading) {
    return (
      <Box height='400px'>
        <TableBodySkeleton rows={4} columns={3} />
      </Box>
    )
  }

  if (error) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='400px' width='100%'>
        <Typography align='center'>{text.getSharedError}</Typography>
      </Box>
    )
  }
  if (data && (!data.observers.users.length && !data.observers.companies.length)) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='400px' width='100%'>
        <Typography align='center'>{text.noSharedUsers}</Typography>
      </Box>
    )
  }

  return (
    <Box height='400px'>
      <Box
        display='grid'
        gridTemplateColumns='2fr 2fr 2fr 24px'
        columnGap='12px'
        rowGap='16px'
        gridAutoRows='min-content'
        alignItems='center'
      >
        {data?.observers.users.map(it => {
          const { name, surname } = it
          return (
            <Fragment key={it.identifier}>
              <Typography>{text.user}</Typography>
              <Typography>{`${surname} ${name}`}</Typography>
              <Typography>{it.email}</Typography>

              <Tooltip title={text.delete}>
                <IconButton
                  sx={{ p: 0 }}
                  id={it.identifier}
                  size='small'
                  disabled={!!(isDeleting && deletingId === it.email)}
                  onClick={handleDeleteSharing}
                >
                  {(isDeleting && deletingId === it.email) ? <CircularProgress size={24} /> : <DeleteIcon />}
                </IconButton>
              </Tooltip>
            </Fragment>
          )
        })}

        {data?.observers.companies.map(it => {
          return (
            <Fragment key={it._id}>
              <Typography>{text.company}</Typography>
              <Typography>{it.name}</Typography>
              <Typography>{it.email}</Typography>

              <Tooltip title={text.delete}>
                <IconButton
                  sx={{ p: 0 }}
                  disabled={!!(isDeleting && deletingId === it.email)}
                  id={it._id}
                  size='small'
                  onClick={handleDeleteSharing}
                >
                  {(isDeleting && deletingId === it.email) ? <CircularProgress size={24} /> : <DeleteIcon />}
                </IconButton>
              </Tooltip>
            </Fragment>
          )
        })}
      </Box>
    </Box>
  )
}
