import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api/api-config'
import { HTTP_METHODS } from 'api/constants'
import {
  IClassifierCopyResponse,
  IClassifierLinks,
  IClassifierResponse,
  IClassifierTreeElement,
  ICreateClassifierInput,
  ICreateClassifierResponse,
  IShareClassifierInput,
  ISharedClassifierResponse,
  IShareToClassifierResponse,
  IUpdateClassifierInput,
} from 'api/entities/classifer/models'
import { IApiRequest } from 'api/models'
import { IClassifierBase } from 'modules/classifiers/models'
import { normalizeClassifiers } from 'modules/classifiers/utils'

// todo: убрать any

export const classifierQuery = createApi({
  reducerPath: 'classifierQuery',
  tagTypes: ['Classifier', 'ClassifierShared', 'ClassifierTree'],
  baseQuery,
  endpoints: (build) => ({
    // Get запрос на список классификаторов
    getClassifiersList: build.query<{ [key: string]: IClassifierBase }, { search?: string } | void>({
      query: (params) => ({ url: 'webapp/classifiers/list', params: params?.search ? { search: params.search } : {} }),
      transformResponse: ({ scope }: { scope: IClassifierResponse[] }) => normalizeClassifiers(scope),
      providesTags: ['Classifier'],

    }),
    // Get запрос дерева конкретного классификатора
    getClassifierTree: build.query<IClassifierTreeElement[], { id: string, search?: string }>({
      query: ({ id, search }) => ({
        url: 'webapp/classifier/tree',
        params: search ? { classifier_id: id, search } : { classifier_id: id },
      }),
      providesTags: ['ClassifierTree'],
      transformResponse: ({ scope }: { scope: IClassifierTreeElement[] }) => scope,
    }),
    // Get запрос списка расшаренных классификаторов
    getClassifiersSharing: build.query<ISharedClassifierResponse, { id: string }>({
      query: ({ id }) => ({ url: 'webapp/classifier/sharing', params: { classifier_id: id } }),
      providesTags: ['ClassifierShared'],
      transformResponse: ({ scope }: { scope: ISharedClassifierResponse }) => scope,
    }),

    // поделиться, или изменить права расшаренного классификатора
    // todo дописать optimistic response
    shareClassifier: build.mutation<IShareToClassifierResponse, IShareClassifierInput>({
      query: ({ id, share_to }) => ({
        url: 'webapp/classifier/sharing',
        params: { classifier_id: id, share_to },
        method: HTTP_METHODS.PATCH,
      }),
      invalidatesTags: ['ClassifierShared'],
      transformResponse: ({ scope }: { scope: IShareToClassifierResponse }) => scope,
    }),

    // отменить шэринг классификатора
    // todo дописать optimistic response
    deleteClassifierSharing: build.mutation<{ id: string }, { id: string, userId: string }>({
      query: ({ id, userId }) => ({
        url: 'webapp/classifier/sharing',
        method: HTTP_METHODS.DELETE,
        params: { classifier_id: id, identifier: userId },
      }),
      transformResponse: ({ scope }: { scope: { identifier: string } }) => ({ id: scope.identifier }),
      invalidatesTags: ['ClassifierShared'],
    }),

    // убрать себя из шэринга классификатора
    // todo дописать optimistic response
    refuseSharing: build.mutation<{ id: string }, { id: string, company?: string }>({
      query: ({ id, company }) => ({
        url: 'webapp/classifier/shared-to-me',
        method: HTTP_METHODS.DELETE,
        params: company ? { classifier_id: id, identifier: company } : { classifier_id: id },
      }),
      transformResponse: ({ scope }: { scope: { identifier: string } }) => ({ id: scope.identifier }),
      invalidatesTags: ['Classifier'],
    }),

    //  изменить владельца классификатора
    // todo дописать optimistic response
    changeClassifierOwner: build.mutation<IShareToClassifierResponse, { id: string, email: string }>({
      query: ({ id, email }) => ({
        url: 'webapp/classifier/sharing',
        params: { classifier_id: id, new_owner: email },
        method: HTTP_METHODS.PUT,
      }),
      invalidatesTags: ['Classifier'],
      transformResponse: ({ scope }: { scope: IShareToClassifierResponse }) => scope,
    }),

    // Создать новый классификатор
    // todo дописать optimistic response
    createClassifier: build.mutation<ICreateClassifierResponse, ICreateClassifierInput>({
      query: (body) => ({ url: 'webapp/classifier', method: HTTP_METHODS.POST, body }),
      invalidatesTags: ['Classifier'],
      transformResponse: ({ scope }: { scope: ICreateClassifierResponse }) => scope,
    }),

    // обновить частично информацию о классификаторе
    // todo дописать optimistic response
    updateClassifier: build.mutation<IApiRequest, IUpdateClassifierInput>({
      query: ({ id, body }) => ({
        url: 'webapp/classifier',
        method: HTTP_METHODS.PATCH,
        params: { classifier_id: id },
        body,
      }),
      invalidatesTags: ['Classifier'],
    }),
    // удалить классификатор
    // todo дописать optimistic response
    deleteClassifier: build.mutation<{ id: string }, { id: string }>({
      query: ({ id }) => ({ url: 'webapp/classifier', method: HTTP_METHODS.DELETE, params: { classifier_id: id } }),
      transformResponse: ({ classifier_id }: { classifier_id: string }) => ({ id: classifier_id }),
      invalidatesTags: ['Classifier'],
    }),

    // создать копию классификатора- автоматически попадает в группу персональные
    // todo дописать optimistic response
    makeClassifierCopy: build.mutation<IClassifierCopyResponse, { id: string }>({
      query: ({ id }) => ({ url: 'webapp/classifier/copy', method: HTTP_METHODS.POST, params: { classifier_id: id } }),
      transformResponse: ({ scope }: { scope: IClassifierCopyResponse }) => scope,
      invalidatesTags: ['Classifier'],
    }),

    // Запросить ссылки на скачивание и загрузку файла классификатора
    getClassifierLinks: build.query<IClassifierLinks, { id: string }>({
      query: ({ id }) => ({ url: 'webapp/classifier', params: { classifier_id: id } }),
      transformResponse: (response: { scope: IClassifierLinks }) => response.scope,
    }),
  }),
})

export const {
  useGetClassifiersListQuery,
  useDeleteClassifierSharingMutation,
  useGetClassifiersSharingQuery,
  useLazyGetClassifiersSharingQuery,
  useLazyGetClassifiersListQuery,
  useCreateClassifierMutation,
  useDeleteClassifierMutation,
  useUpdateClassifierMutation,
  useShareClassifierMutation,
  useGetClassifierLinksQuery,
  useGetClassifierTreeQuery,
  useLazyGetClassifierLinksQuery,
  useLazyGetClassifierTreeQuery,
  useMakeClassifierCopyMutation,
  useChangeClassifierOwnerMutation,
  useRefuseSharingMutation,
} = classifierQuery
