import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'
import * as yup from 'yup'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const ERR = TEXT.ERRORS[locale as Locale]

export const validationSchema = yup.object({
  password: yup
    .string()
    .required(ERR.required)
    .min(8, ERR.min8Letters)
    .matches(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g, ERR.passwordRules),
  repeatPassword: yup
    .string()
    .required(ERR.required)
    .oneOf([yup.ref('password'), null], ERR.doesNotMatch),
})
