import { LOCALE } from 'modules/locale/constants'
import { HEADER } from 'modules/locale/text/header'
import { NOT_FOUND } from 'modules/locale/text/notFound'
import { USER } from 'modules/locale/text/user'
import { AUTH } from './auth'
import { CLASSIFIERS } from './classifiers'
import { ERRORS } from './errors'
import { FOOTER } from './footer'

export const TEXT = { FOOTER, AUTH, ERRORS, HEADER, USER, NOT_FOUND, CLASSIFIERS } as const

export const TEXT_BLOCKS = {
  [LOCALE.ENGLISH]: { ...FOOTER[LOCALE.ENGLISH], ...AUTH[LOCALE.ENGLISH], ...ERRORS[LOCALE.ENGLISH], ...HEADER[LOCALE.ENGLISH], ...USER[LOCALE.ENGLISH], ...NOT_FOUND[LOCALE.ENGLISH], ...CLASSIFIERS[LOCALE.ENGLISH] },
  [LOCALE.RUSSIAN]: { ...FOOTER[LOCALE.RUSSIAN], ...AUTH[LOCALE.RUSSIAN], ...ERRORS[LOCALE.RUSSIAN], ...HEADER[LOCALE.RUSSIAN], ...USER[LOCALE.RUSSIAN], ...NOT_FOUND[LOCALE.RUSSIAN], ...CLASSIFIERS[LOCALE.RUSSIAN] },
  [LOCALE.KAZAKH]: { ...FOOTER[LOCALE.KAZAKH], ...AUTH[LOCALE.KAZAKH], ...ERRORS[LOCALE.KAZAKH], ...HEADER[LOCALE.KAZAKH], ...USER[LOCALE.KAZAKH], ...NOT_FOUND[LOCALE.KAZAKH], ...CLASSIFIERS[LOCALE.KAZAKH] },
}
