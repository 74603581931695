import { LOCALE } from '../constants'

export const FOOTER = {
  [LOCALE.RUSSIAN]: {
    rights: '© Все права защищены',
    confidentiality: 'Конфиденциальность',
    conditions: 'Условия',
    publicOffer: 'Публичная оферта',
    contacts: 'Контакты',
  },
  [LOCALE.ENGLISH]: {
    rights: '© All rights reserved',
    confidentiality: 'Confidentiality',
    conditions: 'Conditions',
    publicOffer: 'Public offer',
    contacts: 'Контакты',
  },
  [LOCALE.KAZAKH]: {
    rights: 'Все права защищены',
    confidentiality: 'Конфиденциальность',
    conditions: 'Условия',
    publicOffer: 'Публичная оферта',
    contacts: 'Контакты',
  },
} as const

