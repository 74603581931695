import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useGetClassifiersListQuery, useLazyGetClassifierTreeQuery } from 'api'
import { ClassifierInfo, ClassifierTableActions, ClassifierTreeTable } from 'modules/classifiers/components'
import { ClassifierType, IClassifierBase } from 'modules/classifiers/models'
import { selectClassifiers, setTreeItems } from 'modules/classifiers/slice'
import { normalizeClassifier } from 'modules/classifiers/utils'
import { ROUTE } from 'modules/common/routes'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'

type Props = { group: string, type: ClassifierType }
type ClassifierRowProps = {
  classifier: IClassifierBase,
  i: number,
  isLast: boolean,
  onClick: (id: string) => void
}

const CLASSIFIER_TABLE_HEAD = ['tableCode', 'name', 'actions']

export const ClassifiersTable: FC<Props> = ({ group, type }) => {
  const { id } = useParams<{ id: string }>()
  const { my } = useSelector(selectMe)
  const { text } = useText('CLASSIFIERS')
  const { data } = useGetClassifiersListQuery()
  const { openedTableItems } = useSelector(selectClassifiers)
  const classifiers = useMemo(() => {
    if (data && my) {
      return Object.values(data).reduce((acc, it) => {
        if (normalizeClassifier(it, my.id).type === type && it.parentGroup === group) {
          acc.push(it)
        }

        return acc
      }, [] as IClassifierBase[])
    }

    return undefined
  }, [data, group, my, type])
  const navigate = useNavigate()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const handleOpen = useCallback((key: string) => {
    if (openedTableItems[key]) {
      dispatch(setTreeItems([]))
      return
    }
    dispatch(setTreeItems([key]))
  }, [dispatch, openedTableItems])

  const handleClassifierClick = (id: string) => {
    navigate({ pathname: generatePath(ROUTE.CLASSIFIER, { id }), search }, { replace: true })
    handleOpen(id)
  }

  const classifier = useMemo(() => {
    if (!data || !id) {
      return undefined
    }

    return data[id]
  }, [data, id])

  return (
    <div>
      <ClassifierInfo group={group} classifier={classifier} />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {CLASSIFIER_TABLE_HEAD.map((it) => (
                <TableCell key={it}>
                  <Typography noWrap variant='subtitle2'>{text[it]}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {classifiers?.map((it, i, arr) => {
              return (
                <ClassifierRow
                  key={it.id}
                  classifier={it}
                  i={i}
                  isLast={i === arr.length - 1}
                  onClick={handleClassifierClick}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}

const ClassifierRow: FC<ClassifierRowProps> = ({ classifier, i, isLast, onClick }) => {
  const [getClassifierTree, { data, isLoading, error }] = useLazyGetClassifierTreeQuery()
  const { openedTableItems } = useSelector(selectClassifiers)
  const cellProps = useMemo(() => ({ sx: (!openedTableItems[classifier.id] && isLast) ? { borderBottom: 'none' } : {} }), [classifier.id, isLast, openedTableItems])

  const handleClick = useCallback(async () => {
    onClick(classifier.id)
    !data && await getClassifierTree({ id: classifier.id }).unwrap()
  }, [classifier.id, data, getClassifierTree, onClick])

  useEffect(() => {
    if (!data && openedTableItems[classifier.id]) {
      getClassifierTree({ id: classifier.id }).unwrap()
    }
  }, [classifier.id, data, getClassifierTree, openedTableItems])

  useShowError(error)

  return (
    <>
      <TableRow hover sx={{ cursor: 'pointer' }}>
        <TableCell sx={{ ...cellProps.sx, minWidth: '130px' }}>
          {i + 1}
        </TableCell>
        <TableCell sx={{ ...cellProps.sx, width: '100%' }} onClick={handleClick}>
          {classifier.name}
        </TableCell>
        <TableCell sx={{ ...cellProps.sx, minWidth: '150px' }}>
          <ClassifierTableActions id={classifier.id} reFetch={handleClick} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={4} padding='none' sx={{ borderBottom: 'none' }}>
          <Collapse in={openedTableItems[classifier.id]}>
            {!openedTableItems[classifier.id] ? null : (
              <ClassifierTreeTable data={data} isLoading={isLoading} id={classifier.id} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
