import { Box, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { PasswordHelpers } from 'modules/auth/components/PasswordHelpers'
import { useCreateNewPasswordMutation } from 'api/entities/auth/mock'
import { Button, Password } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { ROUTE } from 'modules/common/routes'
import { useText } from 'modules/locale'
import React, { FC } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { validationSchema } from './constants'

const initialValues: Values = { password: '', repeatPassword: '' }
type Values = { password: string; repeatPassword: string }

export const ResetPassword: FC = () => {
  const [searchParams] = useSearchParams()
  const { text } = useText('AUTH')
  const { text: errorText } = useText('ERRORS')
  const [createNewPassword, { isLoading }] = useCreateNewPasswordMutation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues, validationSchema, onSubmit: async (values) => {
      const userId = searchParams.get('user_id') || ''
      const resetKey = searchParams.get('reset_key') || ''
      const response = await createNewPassword({ password: values.password, userId, resetKey })
        .unwrap()
        .catch(err => {
          console.error(err)
          const message = err.status === 502 ? errorText.status502 : err.data.message
          enqueueSnackbar(message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
        })
      if (response) {
        enqueueSnackbar(response.message, { variant: 'success', action: ACTION(() => closeSnackbar()) })
        navigate(ROUTE.SIGN_IN, { replace: true })
      }
    },
  })
  const { handleSubmit, handleChange, values, handleBlur, errors, touched } = formik

  return (
    <>
      <Box display='grid' gap='20px'>
        <Typography variant='h1'>{text.putNewPassword}</Typography>
        <Typography variant='body1' color='secondary'>{text.newPasswordDesc}</Typography>
      </Box>

      <Box mt='40px' component='form' onSubmit={handleSubmit}>

        <Box display='grid' gap='10px' mb='10px'>
          <Password
            fullWidth
            required
            name='password'
            autoComplete='new-password'
            value={values.password}
            label={text.newPassword}
            disabled={isLoading}
            error={touched.password && !!errors.password}
            helperText={(touched.password && errors.password) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Password
            fullWidth
            required
            name='repeatPassword'
            autoComplete='new-password'
            value={values.repeatPassword}
            label={text.repeatPassword}
            disabled={isLoading}
            error={touched.repeatPassword && !!errors.repeatPassword}
            helperText={(touched.repeatPassword && errors.repeatPassword) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>

        <PasswordHelpers password={values.password} />

        <Box display='grid' gap='20px' mt='40px' gridTemplateColumns='1fr 1fr'>
          <Button
            type='submit'
            loading={isLoading}
            fullWidth
            variant='contained'
            color='primary'
          >
            {text.changePassword}
          </Button>
          <div />
        </Box>

      </Box>
    </>
  )
}
