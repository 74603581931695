import { SearchParams } from 'api/entities/admin/models'

export const ERRORS = {
  INVITE_EMPLOYEE: 'inviteEmployee',
} as const

export const EMPTY_PARAMS: SearchParams = {
  limit: 20,
  offset: 0,
  search_query: '',
}
