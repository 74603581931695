import { styled } from '@mui/material'
import { useText } from 'modules/locale'
import React, { FC, useMemo } from 'react'
import { color } from 'styles/theme'

type Props = { active?: boolean; entity?: 'contract' | 'license' }

export const Status: FC<Props> = ({ active, entity }) => {
  const { text } = useText('USER')
  const status = useMemo(() => {
    if (entity === 'license') {
      return active ? text.licenseIsActive : text.licenseIsEnded
    }

    return active ? text.contractIsActive : text.contractIsEnded
  }, [active, entity, text.contractIsActive, text.contractIsEnded, text.licenseIsActive, text.licenseIsEnded],
  )
  return (
    <StatusWrapper active={active ? 'true' : undefined}>
      {status}
    </StatusWrapper>
  )
}
const StatusWrapper = styled('span')<{ active?: string }>`
  display: flex;
  align-items: center;
  gap: 10px;

  &:before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${({ active }) => active ? color.success : color.error};
    display: block;

  }
`
