import { Box, Typography } from '@mui/material'
import { IOrganizationResponse, useGetCompaniesQuery, useLazyGetCompaniesQuery } from 'api'
import { AddOrganization } from 'modules/admin/components/Clients/AddOrganization'
import { Search, SimpleTable, TableSkeleton } from 'modules/common/components'
import { ACTION } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { COLUMNS } from './constants'
import { createRows } from './utils'

export const CorporateClients: FC = () => {
  const [isSearchResults, setIsSearchResults] = useState(false)
  const [limit, setLimit] = useState(15)
  const [tableData, setTableData] = useState<IOrganizationResponse[]>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { text } = useText('USER')
  const { error, data, isLoading, isFetching } = useGetCompaniesQuery({ limit })
  const [searchUsers, { data: searchData, isFetching: isSearching }] = useLazyGetCompaniesQuery()
  const options = useMemo(() => {
    return searchData?.companies.map(c => ({
      value: c._id,
      label: c.name,
    }))
  }, [searchData?.companies])
  const handleFetchMore = useCallback(() => {
    if (!isSearchResults) {
      setLimit(prev => prev + 15)
    }
  }, [isSearchResults])

  useEffect(() => {
    if (error) {
      console.error(error)
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])
  useEffect(() => {
    if (data) {
      setTableData(data.companies)
    }
  }, [data])
  const handleChoose = useCallback((value: string | null) => {
    if (!value) {
      setIsSearchResults(false)
      setTableData(data?.companies || [])
      return
    }
    setIsSearchResults(true)
    setTableData(searchData?.companies.filter(it => it._id === value) || [])
  }, [data?.companies, searchData?.companies])
  const handleSearch = useCallback(async (value: string | null) => {
    if (value) {
      await searchUsers({ search_query: value }).unwrap()
    }
  }, [searchUsers])

  return (
    <>
      <Typography mb='20px' variant='h1'>{text.corporateClients}</Typography>
      <Box display='flex' gap='10px' alignItems='center'>
        <Typography variant='subtitle1' color='secondary'>{text.totalOrgs}</Typography>
        <Typography variant='subtitle1' color='primary' fontWeight={500}>
          {data?.companies_total_number || ''}
        </Typography>
      </Box>

      <Box m='40px 0' display='flex' justifyContent='space-between'>
        <AddOrganization />
        <Search options={options} loading={isSearching} onSearch={handleSearch} onChoose={handleChoose} />
      </Box>

      <Box mx='-20px'>
        {(isLoading)
          ? <TableSkeleton columns={5} rows={3} />
          :
          <SimpleTable
            total={data?.companies_total_number}
            fetchData={handleFetchMore}
            columns={COLUMNS}
            rows={createRows(tableData)}
            maxHeight='700px'
            fetching={isFetching}
          />
        }
      </Box>
    </>
  )
}
