import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api/api-config'
import { HTTP_METHODS } from 'api/constants'
import { IUserLicenseResponse, IUserPhotoResponse } from './models'

export const userQuery = createApi({
  reducerPath: 'userQuery',
  baseQuery,
  endpoints: (build) => ({
    getUserLicenses: build.query<IUserLicenseResponse[], void>({
      query: () => ({ url: 'user/licenses' }),
      transformResponse: (response: { scope: IUserLicenseResponse[] }) => response.scope,
    }),
    getUserPhoto: build.query<IUserPhotoResponse, void>({
      query: () => ({ url: 'user/profile/image' }),
      transformResponse: (response: { scope: IUserPhotoResponse }) => response.scope,
    }),
    updateEmail: build.mutation<any, { email: string }>({
      query: (body) => ({ url: '', method: HTTP_METHODS.PATCH, body }),
      transformResponse: (response: { scope: any }) => response.scope,
    }),
    confirmUpdateEmailCode: build.mutation<any, { code: string }>({
      query: (body) => ({ url: '', method: HTTP_METHODS.POST, body }),
      transformResponse: (response: { scope: any }) => response.scope,
    }),
  }),
})

export const {
  useGetUserLicensesQuery,
  useLazyGetUserLicensesQuery,
  useGetUserPhotoQuery,
  useConfirmUpdateEmailCodeMutation,
  useLazyGetUserPhotoQuery,
  useUpdateEmailMutation,
} = userQuery

