import { FormControlLabel as MuiFormControlLabel, FormControlLabelProps, styled } from '@mui/material'
import React, { FC } from 'react'
import { color } from 'styles/theme'

type Props = FormControlLabelProps & { error?: boolean }

export const FormControlLabel: FC<Props> = ({ error, ...props }) => {
  return (
    <StyledFormControlLabel error={error} {...props} />
  )
}

const StyledFormControlLabel = styled(MuiFormControlLabel)<{ error?: boolean }>`

   &.MuiFormControlLabel-root > span {
     color: ${({ error }) => error && color.error }
   } 
  
`
