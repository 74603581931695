import { Column } from 'modules/common/components'
import { Locale, LOCALE } from 'modules/locale'
import { TEXT } from 'modules/locale/text'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.USER[locale as Locale]

export const COLUMNS_USER: Column[] = [
  { id: 'contract', label: T.contract },
  { id: 'licenses', label: T.licenses },
  { id: 'status', label: T.status, noTypography: true },
  { id: 'validity', label: T.validity },
]

export const COLUMNS_ADMIN: Column[] = [
  ...COLUMNS_USER,
  { id: 'edit', label: T.edit, noTypography: true },
]
