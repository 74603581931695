import { LOCALE } from '../constants'

export const NOT_FOUND = {
  [LOCALE.RUSSIAN]: {
    pageNotFound: 'Страница не найдена',
    pageIsNotExist: 'Страница, которую Вы ищете, не существует, либо устарела.',
    pageInDev: 'Страница, которую Вы ищете, находится в разработке. Мы делаем все чтобы она скорее воявилась на вашем экране',
    mainPage: 'Главная страница',
  },
  [LOCALE.ENGLISH]: {
    pageNotFound: 'Страница не найдена',
    pageIsNotExist: 'Страница, которую Вы ищете, не существует, либо устарела.',
    pageInDev: 'Страница, которую Вы ищете, находится в разработке. Мы делаем все чтобы она скорее воявилась на вашем экране',
    mainPage: 'Главная страница',
  },
  [LOCALE.KAZAKH]: {
    pageNotFound: 'Страница не найдена',
    pageIsNotExist: 'Страница, которую Вы ищете, не существует, либо устарела.',
    pageInDev: 'Страница, которую Вы ищете, находится в разработке. Мы делаем все чтобы она скорее воявилась на вашем экране',
    mainPage: 'Главная страница',
  },
} as const

