import { Box } from '@mui/material'
import { IUserLicenseResponse } from 'api'
import { CollapsibleRow, Status, TextField } from 'modules/common/components'
import { dateFormat } from 'modules/common/utils'
import { LOCALE, Locale } from 'modules/locale'
import { TEXT } from 'modules/locale/text'
import React from 'react'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.USER[locale as Locale]

export const createSubRow = (contractOffer: string, purchaseDate: string, endDate: string) => {
  return (
    <Box display='grid' gridTemplateColumns='repeat(3, 183px)' gap='20px'>
      <TextField label={T.contractOffer} value={`${T.from} ${contractOffer}`} InputProps={{ readOnly: true }} />
      <TextField label={T.purchaseDate} value={purchaseDate} InputProps={{ readOnly: true }} />
      <TextField label={T.endDate} value={endDate} InputProps={{ readOnly: true }} />
    </Box>
  )
}

export const createRows = (data: IUserLicenseResponse[] | undefined): CollapsibleRow[] => {
  if (!data) {
    return []
  }

  return data.map(it => ({
    id: it.license_id,
    license: it.product_code,
    owner: it.company_name,
    status: <Status active={it.is_active} entity='license' />,
    daysToFinish: it.days_before_end_date > 0 ? it.days_before_end_date : '-',
    subRow: createSubRow(dateFormat(it.sign_date), dateFormat(it.payment_date), dateFormat(it.sub_end_date)),
  }))
}
