import { LOCALE } from '../constants'

export const ERRORS = {
  [LOCALE.RUSSIAN]: {
    status502: 'Упс... Что-то пошло не так',
    required: 'Обязательное поле',
    min8Letters: 'Минимум 8 символов',
    min3Letters: 'Минимум 3 символа',
    doesNotMatch: 'Пароли не совпадают',
    agreeIsRequired: 'Необходимо принять условия конфиденциальности',
    passwordRules: 'Пароль должен содержать цифру, заглавную букву',
    invalidEmail: 'Введите правильный email',
    fileSizeIsLarge: 'Размер файла не должен превышать',
  },
  [LOCALE.ENGLISH]: {
    status502: 'Упс... Что-то пошло не так',
    required: 'Обязательное поле',
    min8Letters: 'Минимум 8 символов',
    min3Letters: 'Минимум 3 символа',
    doesNotMatch: 'Пароли не совпадают',
    agreeIsRequired: 'Необходимо принять условия конфиденциальности',
    passwordRules: 'Пароль должен содержать цифру, заглавную букву',
    invalidEmail: 'Введите правильный email',
    fileSizeIsLarge: 'Размер файла не должен превышать',
  },
  [LOCALE.KAZAKH]: {
    status502: 'Упс... Что-то пошло не так',
    required: 'Обязательное поле',
    min8Letters: 'Минимум 8 символов',
    min3Letters: 'Минимум 3 символа',
    doesNotMatch: 'Пароли не совпадают',
    agreeIsRequired: 'Необходимо принять условия конфиденциальности',
    passwordRules: 'Пароль должен содержать цифру, заглавную букву',
    invalidEmail: 'Введите правильный email',
    fileSizeIsLarge: 'Размер файла не должен превышать',
  },
} as const
