import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ROLE } from 'api/entities/user'
import { login, logout, setConsole, setIsAuth } from 'modules/auth/actions'
import { RootState } from 'store/models'

export type IState = {
  isLoading: boolean;
  error: string | null,
  isAuth: boolean;
  consoleFor: ROLE | null
}
export const initialState: IState = {
  isLoading: false,
  error: null,
  isAuth: false,
  consoleFor: null,
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [setIsAuth.type]: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuth = payload
    },
    [logout.fulfilled.type]: (state) => {
      state.isAuth = false
    },

    [setConsole.type]: (state, { payload }: PayloadAction<ROLE>) => {
      state.consoleFor = payload
    },

    [login.pending.type]: (state) => {
      state.error = null
      state.isLoading = true
    },
    [login.fulfilled.type]: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuth = payload
      state.isLoading = false
    },
    [login.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const selectAuth = (state: RootState) => state.auth
export default slice.reducer
