import { Box, Checkbox, FormControlLabel, Link, TextField, Typography } from '@mui/material'
import { useLazyGetAccessTokenQuery } from 'api'
import { useFormik } from 'formik'
import { setTokens } from 'modules/app/actions'
import { login } from 'modules/auth/actions'
import { selectAuth } from 'modules/auth/slice'
import { Button, Password } from 'modules/common/components'
import { ROUTE } from 'modules/common/routes'
import { getError } from 'modules/common/utils'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { useText } from 'modules/locale'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { validationSchema } from './constants'

const initialValues: Values = { email: '', password: '', remember: false }
type Values = { email: string; password: string; remember: boolean }

export const SignIn: FC = () => {
  const { text } = useText('AUTH')
  const { isLoading, error: authErr } = useSelector(selectAuth)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [getToken, { isLoading: loading, error }] = useLazyGetAccessTokenQuery()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ remember, ...values }) => {
      await getToken(values).unwrap().then(response => dispatch(setTokens(response)))
      await dispatch(login())
    },
  })

  const { handleSubmit, handleChange, values, handleBlur, errors, touched } = formik
  const handleSignUp = () => navigate(ROUTE.SIGN_UP)

  useEffect(() => {
    if (error || authErr) {
      console.error(error || authErr)
      enqueueSnackbar(authErr || getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [authErr, closeSnackbar, enqueueSnackbar, error])

  return (
    <>
      <Box display='grid' gap='20px'>
        <Typography variant='h1'>{text.title}</Typography>
        <Typography variant='body1' color='secondary'>{text.description}</Typography>
      </Box>

      <Box mt='40px' component='form' onSubmit={handleSubmit}>

        <Box display='grid' gap='10px'>
          <TextField
            fullWidth
            name='email'
            value={values.email}
            label={text.login}
            disabled={isLoading || loading}
            required
            error={touched.email && !!errors.email}
            helperText={(touched.email && errors.email) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Password
            muiInput
            fullWidth
            name='password'
            value={values.password}
            label={text.password}
            disabled={isLoading}
            required
            error={touched.password && !!errors.password}
            helperText={(touched.password && errors.password) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <FormControlLabel
            name='remember'
            control={<Checkbox checked={values.remember} onChange={handleChange} />}
            label={text.remember}
            disabled={isLoading}
          />

          {(isLoading || loading)
            ? <Typography color='secondary' variant='body1'>{text.forgotPassword}</Typography>
            : (
              <Link color='secondary' variant='body1' component={RouterLink} to={ROUTE.RESTORE_PASSWORD}>
                {text.forgotPassword}
              </Link>
            )}
        </Box>

        <Box display='flex' gap='20px' mt='40px'>
          <Button
            type='submit'
            loading={isLoading || loading}
            fullWidth
            variant='contained'
          >
            {text.signIn}
          </Button>
          <Button
            fullWidth
            disabled={isLoading || loading}
            variant='outlined'
            onClick={handleSignUp}
          >
            {text.signUp}
          </Button>
        </Box>

      </Box>

    </>
  )
}
