import { styled } from '@mui/material'
import { LogoAnimatedIcon } from 'assets/svg'
import React, { FC } from 'react'

type Props = unknown

export const Loader: FC<Props> = () => {
  return (
    <Wrapper>
      <LogoAnimatedIcon />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`
