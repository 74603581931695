import { useSelector } from 'react-redux'
import { TextBlock } from './models'
import { selectLocale } from './slice'
import { TEXT } from './text'

// todo: убрать any
export const useText = (block: TextBlock) => {
  const { locale } = useSelector(selectLocale)
  const text = TEXT[block][locale] as any
  return { text }
}
