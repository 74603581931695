import { Box, Container } from '@mui/material'
import { HEIGHT } from 'modules/common/constants'
import { ROUTE } from 'modules/common/routes'
import { Confidentiality, Contacts, PublicOffer, UserAgreement } from 'pages'
import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

export const CommonPages: FC = () => {
  return (
    <Box component='main' minHeight={`calc(100vh - ${HEIGHT.HEADER} - ${HEIGHT.FOOTER})`}>
      <Container disableGutters>
        <Routes>
          <Route path={ROUTE.CONFIDENTIALITY} element={<Confidentiality />} />
          <Route path={ROUTE.CONDITIONS} element={<UserAgreement />} />
          <Route path={ROUTE.PUBLIC_OFFER} element={<PublicOffer />} />
          <Route path={ROUTE.CONTACTS} element={<Contacts />} />
        </Routes>
      </Container>
    </Box>
  )
}
