import { Box } from '@mui/material'
import { IContractShortResponse } from 'api'
import { ContractDelete, ContractEdit } from 'modules/contracts/components'
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

type Props = { contract: IContractShortResponse }

export const ContractActions: FC<Props> = ({ contract }) => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    return null
  }

  return (
    <Box my='auto' display='flex' gap='10px' height='min-content'>
      <ContractEdit contract={contract} companyId={id} />
      <ContractDelete id={contract._id} name={contract.contract_id} />
    </Box>
  )
}
