import { Box, Button, IconButton, styled } from '@mui/material'
import { LogoInverseIcon } from 'assets/svg'
import { HEIGHT } from 'modules/common/constants'
import { ROUTE } from 'modules/common/routes'
import { useText } from 'modules/locale'
import React, { FC, MouseEvent, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { color } from 'styles/theme'

const TABS = {
  RIGHTS: 'rights',
  CONFIDENTIALITY: 'confidentiality',
  CONDITIONS: 'conditions',
  PUBLIC_OFFER: 'publicOffer',
  CONTACTS: 'contacts',
} as const

export const Footer: FC = () => {
  const { text } = useText('FOOTER')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const active = useMemo(() => pathname.slice(1) ? pathname.slice(1) : undefined, [pathname])

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { name } = e.currentTarget
    navigate(ROUTE[name as keyof typeof ROUTE])
  }

  const handleClickLogo = () => navigate(ROUTE.MAIN)

  return (
    <Box mt='auto' width='100vw' height={HEIGHT.FOOTER} display='flex' component='footer' bgcolor={color.high}>
      <Box width='1400px' p='0 100px 0 60px' display='flex' mx='auto'>
        <div>
          {Object.keys(TABS).map((it) => {
            return (
              <Action
                key={it}
                checked={active === TABS[it as keyof typeof TABS]}
                disabled={it === 'RIGHTS'}
                variant='text'
                name={it}
                onClick={handleClick}
              >
                {text[TABS[it as keyof typeof TABS]]}
              </Action>
            )
          })}
        </div>

        <StyledButton onClick={handleClickLogo}>
          <LogoInverseIcon />
        </StyledButton>

      </Box>
    </Box>
  )
}

const Action = styled(Button)<{ checked?: boolean }>`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  height: auto;
  padding: 20px 40px;
  border-radius: 5px;


  &.MuiButton-root {
    color: ${({ checked }) => checked ? color.surface : color.medium};
    position: relative;

    &:after {
      padding: 0 40px;
      content: '';
      width: calc(100% - 70px);
      height: ${({ checked }) => checked ? '1px' : 0};
      border-radius: 1px;
      position: absolute;
      background: ${color.success};
      top: 38px;
    }
  }

  &[disabled] {
    color: ${color.medium}
  }
`
const StyledButton = styled(IconButton)`
  border-radius: 5px;
  margin-left: auto;
`
