import { Box, CircularProgress, Container } from '@mui/material'
import { ROLE } from 'api/entities/user'
import { Clients, Organization } from 'modules/admin/components'
import { selectAuth } from 'modules/auth/slice'
import { GradientHead, Licenses, Main } from 'modules/authorized/components'
import { Header, NotFound } from 'modules/common/components'
import { HEIGHT } from 'modules/common/constants'
import { ROUTE } from 'modules/common/routes'
import { ContractOffer, ContractOfferDoc } from 'modules/contracts/components'
import { Profile } from 'modules/me/components'
import ClassifiersPage from 'pages/classifiers'
import React, { FC, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

export const Authorized: FC = () => {
  const { consoleFor } = useSelector(selectAuth)

  return (
    <>
      <Suspense fallback={<CircularProgress />}>
        <Header />
        <Box component='main' minHeight={`calc(100vh - ${HEIGHT.HEADER} - ${HEIGHT.FOOTER})`}>

          <GradientHead />

          <Container disableGutters>

            <Routes>
              <Route path={ROUTE.SIGN_IN} element={<Navigate to={ROUTE.MAIN} />} />
              <Route path={ROUTE.MAIN} element={<Main />} />

              <Route path={ROUTE.CLASSIFIERS} element={<ClassifiersPage />} />
              <Route path={ROUTE.CLASSIFIER} element={<ClassifiersPage />} />

              <Route path={ROUTE.PROFILE} element={<Profile />} />
              <Route path={`${ROUTE.CONTRACT_OFFER}/:id`} element={<ContractOffer />} />
              <Route path={`${ROUTE.CONTRACT_OFFER_DOC}/:sign_date`} element={<ContractOfferDoc />} />

              {consoleFor === ROLE.ADMIN &&
                <>
                  <Route path={ROUTE.CLIENTS} element={<Clients />} />
                  <Route path={ROUTE.CLIENT} element={<Organization />} />
                  <Route path={ROUTE.LICENSES} element={<Navigate to={ROUTE.MAIN} />} />
                </>
              }
              {consoleFor !== ROLE.ADMIN &&
                <>
                  <Route path={ROUTE.CLIENTS} element={<Navigate to={ROUTE.MAIN} />} />
                  <Route path={ROUTE.LICENSES} element={<Licenses />} />
                </>
              }

              <Route path='*' element={<NotFound />} />
            </Routes>
          </Container>
        </Box>
      </Suspense>
    </>
  )
}
