import { Box } from '@mui/material'
import { CorporateClients } from 'modules/admin/components/Clients/CorporateClients'
import { Users } from 'modules/admin/components/Clients/Users'
import React, { FC } from 'react'

type Props = unknown

export const Clients: FC<Props> = () => {
  return (
    <Box p='80px 100px'>
      <CorporateClients />
      <Box height='80px' />
      <Users />
    </Box>
  )
}
