import { createTheme } from '@mui/material'
import { ruRU } from '@mui/material/locale'
import { ArrowDropdown, CheckboxOff, CheckboxOn, RadioOff, RadioOn } from 'assets/toggle'
import React from 'react'
import type {} from '@mui/x-date-pickers/themeAugmentation'

export const color = {
  error: '#FF5F5F',
  success: '#5BE77F',
  outline: '#E0E0E0',
  surface: '#FFFFFF',
  background: '#FBFBFD',
  // typography colors
  high: '#0F0F0F',
  medium: '#828282',
  disabled: '#D1D1D1',
}
export const blue = {
  50: '#E8E9F5',
  100: '#C4C8E6',
  200: '#9EA4D6',
  300: '#7881C5',
  400: '#5B66B9',
  500: '#3F4BAD',
  600: '#3A43A3',
  700: '#313997',
  800: '#29308B',
  900: '#1B1E76',
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1920,
    },
  },
  palette: {
    background: { default: color.background },
    primary: { main: color.high },
    secondary: { main: color.medium },
    text: { disabled: color.disabled, primary: color.high, secondary: color.medium },
    success: { main: color.success },
    error: { main: color.error },
  },

  typography: {
    allVariants: {
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
    },
    h1: {
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '120%',
    },
    h2: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '130%',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '130%',
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '130%',
      textTransform: 'uppercase',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '130%',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '130%',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '130%',
    },
  },

  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {

        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          textUnderlineOffset: '2px',
          '&:hover': {
            color: color.high,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {

          '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: '20px',
          },
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0 20px 0 20px',
          },
        },
      },

    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },

      styleOverrides: {
        rounded: { borderRadius: '10px' },
        elevation1: { boxShadow: '0px 4px 20px rgba(44, 51, 141, 0.05)' },
        elevation0: { boxShadow: 'none' },
        outlined: { border: `1px solid ${blue[200]}` },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckboxOn />,
        icon: <CheckboxOff />,
      },

      styleOverrides: {
        root: {
          padding: '4px',
          '&.Mui-checked': { color: color.medium },
          '&:active': { color: color.high },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        checkedIcon: <RadioOn />,
        icon: <RadioOff />,
      },

      styleOverrides: {
        root: {
          padding: '4px',
          '&.Mui-checked': {
            color: color.high,
            '& ~ span': { color: color.high },
          },
          '&:active': { color: color.high },
        },
      },
    },

    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: { color: '#FFFFFF', borderRadius: '5px', height: '50px' },

        outlined: { padding: '16px' },
        outlinedPrimary: {
          border: `1px solid ${blue[800]}`,
          color: blue[800],
          '&.Mui-disabled': { border: `1px solid ${color.outline}`, color: color.outline },
          '&:hover': { border: `1px solid ${blue[400]}`, color: blue[400], background: 'none' },
        },

        contained: { padding: '16px' },
        containedPrimary: {
          background: blue[800],
          '&.Mui-disabled': { background: color.disabled, color: '#FFFFFF' },
          '&:hover': { background: blue[400] },
        },

        text: { color: color.high },
        textSecondary: { color: color.medium },
        sizeSmall: {
          padding: '6px 12px',
          height: 'auto',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: color.medium,
          transition: 'all 0.2s ease',
          '&:active': { color: color.high },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '130%',
          margin: '5px 4px 0',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          color: color.high,
          borderColor: color.outline,
          minHeight: '50px',

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: blue[400],
          },
          '&.Mui-disabled': {
            background: color.background,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.outline,
            },
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: blue[400],
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: color.error,
          },
        },

        input: { padding: '15px 20px' },
        notchedOutline: {
          '> legend': { marginLeft: '4px' },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: { left: 4 },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          '&.MuiMenu-paper': {
            border: `1px solid ${color.outline}`,
            background: color.background,
            borderRadius: '5px',
            boxShadow: 'none',
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: { color: color.medium, padding: 0 },
      },
    },

    MuiSelect: {
      defaultProps: {
        IconComponent: ArrowDropdown,
        MenuProps: {
          sx: {
            maxHeight: '170px',
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: -10,
            horizontal: 'left',
          },
        },
      },
      styleOverrides: {
        icon: { top: 'auto' },
        iconOutlined: { transform: 'none' },
        outlined: {
          padding: '16px',
        },
        // select: {
        //   padding: '16px',
        // },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '5px 20px',
          margin: '0',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '130%',
          '&:first-of-type': {
            marginTop: '15px',
          },
          '&:last-child': {
            marginBottom: '15px',
          },
          '&.Mui-selected': {
            color: color.high,
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
            '&:focus': { backgroundColor: 'transparent' },
            '&:before': {
              content: '""',
              height: '4px',
              width: '4px',
              borderRadius: '50%',
              backgroundColor: color.high,
              marginRight: '10px',
            },
          },
          '&:hover': {
            color: color.high,
            backgroundColor: 'transparent',
          },
        },
      },
    },

    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          strokeLinecap: 'round',
          stroke: blue[800],
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          // paddingLeft: '1.25rem',
          // paddingRight: '1.25rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '40px',
          minWidth: '470px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: '10px',
        },
      },
    },

    MuiAppBar: {
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        root: {
          backgroundColor: color.surface,
          boxShadow: '0 4px 20px rgba(44, 51, 141, 0.05)',
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 20px rgba(44, 51, 141, 0.05)',
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          background: blue[800],
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          background: color.surface,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '20px',
        },
        sizeSmall: {
          padding: '5px 20px',
        },
        paddingNone: {
          padding: 0,
        },
        head: {
          color: color.surface,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '130%',
          letterSpacing: '0.03em',
          textTransform: 'uppercase',
          padding: '10px 20px',
        },
        body: {
          color: color.high,
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '130%',
        },
      },
    },

  },
}, ruRU)
