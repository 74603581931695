import { Box, Dialog, IconButton, styled, Typography } from '@mui/material'
import { useDeleteContractMutation } from 'api'
import { CloseIcon, DeleteIcon } from 'assets/svg'
import { Button } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useEffect, useState } from 'react'

type Props = { id: string, name: string }

export const ContractDelete: FC<Props> = ({ id, name }) => {
  const [open, setOpen] = useState(false)
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [deleteContract, { isLoading, isSuccess, error }] = useDeleteContractMutation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleSubmit = () => deleteContract({ id })

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.contractEditSuccess, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      handleClose()
    }
  }, [isSuccess, closeSnackbar, enqueueSnackbar, text.contractEditSuccess])

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>

      <Dialog open={open} onClose={isLoading ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={isLoading} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography mb='20px' variant='h2'>{text.deleteContract}</Typography>
        <Typography variant='body1' color='secondary'>{`${text.sureToDeleteContract} ${name}?`}</Typography>

        <Box display='flex' gap='20px' mt='40px'>
          <Button
            loading={isLoading}
            fullWidth
            variant='contained'
            onClick={handleSubmit}
          >
            {text.delete}
          </Button>
          <Button
            autoFocus
            fullWidth
            disabled={isLoading}
            variant='outlined'
            onClick={handleClose}
          >
            {text.cancel}
          </Button>
        </Box>

      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
