import { Box } from '@mui/material'
import { IContractResponse, IUserFromAdmin, ROLE } from 'api'
import {
  AvailableLicensesList,
  ChangeRoleSelect,
  CollapsibleRow,
  LicensesEndDatesList,
  TextField,
  UserDeleteButton,
} from 'modules/common/components'
import { LOCALE, Locale, TEXT } from 'modules/locale'
import React from 'react'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.USER[locale as Locale]
type SubRowData = {
  id: string
  email: string
  companyId: string
  companyName: string
  fullName: string,
}
export const createSubRow = (data: SubRowData, onDelete?: (userId: string) => void, isDeleting?: boolean) => {
  const { id, email, companyName, fullName, companyId } = data
  return (
    <div>
      <Box display='flex' alignItems='center' gap='20px' width='780px'>
        <TextField fullWidth label={T.email} value={email} InputProps={{ readOnly: true }} />
        <TextField fullWidth label={T.organizationName} value={companyName} InputProps={{ readOnly: true }} />
      </Box>

      <Box display='flex' alignItems='center' gap='20px'>
        <TextField label={T.userId} value={id} InputProps={{ readOnly: true }} sx={{ minWidth: '380px' }} />
        <TextField
          label={T.organizationId}
          value={companyId}
          InputProps={{ readOnly: true }}
          sx={{ minWidth: '380px' }}
        />
        {onDelete && (
          <Box ml='auto'>
            <UserDeleteButton userId={id} userName={fullName} isDeleting={isDeleting} onDelete={onDelete} />
          </Box>
        )}
      </Box>
    </div>

  )
}

export const createRows = (
  users: IUserFromAdmin[] | undefined,
  contracts: IContractResponse[] | undefined,
  myId: string | undefined,
  onRoleChange: (userId: string, role: ROLE) => void,
  isRoleChanging: boolean,
  onUserDelete: (userId: string) => void,
  isDeleting: boolean,
): CollapsibleRow[] => {
  if (!users || !contracts) {
    return []
  }

  return users.map(user => {
    const userCompanyContracts = contracts.filter(c => c.company_id === user.company_id)

    const subRowData: SubRowData = {
      id: user.user_id,
      email: user.email_login,
      companyId: user.company_id,
      companyName: user.company_name,
      fullName: `${user.contact_info.name} ${user.contact_info.surname}`,
    }
    return {
      id: user.user_id,
      employee: `${user.contact_info.name} ${user.contact_info.surname}`,
      role: (
        <ChangeRoleSelect
          rolesList='portal'
          role={user.role}
          userId={user.user_id}
          isLoading={isRoleChanging}
          onRoleChange={user.user_id === myId ? undefined : onRoleChange}
        />
      ),
      availableLicenses: (
        <AvailableLicensesList
          contracts={userCompanyContracts}
          userId={user.user_id}
          companyId={user.company_id}
        />
      ),
      validity: <LicensesEndDatesList contracts={userCompanyContracts} userId={user.user_id} />,
      entryDate: '_',
      subRow: createSubRow(subRowData, user.user_id !== myId ? onUserDelete : undefined, isDeleting),
    }
  })
}
