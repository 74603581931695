import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.USER[locale as Locale]

export const COLUMNS = [
  { id: 'license', label: T.license },
  { id: 'validity', label: T.validity },
]

export const ROWS = [
  { license: 'IMPulse Trial Single', validity: '18.04.2022', id: '1' },
  { license: 'IMPulse Full Single', validity: '20.07.2022', id: '2' },
]
