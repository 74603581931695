import { LOCALE } from 'modules/locale/constants'
import { Locale } from 'modules/locale/models'
import { TEXT } from 'modules/locale/text'
import * as yup from 'yup'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const ERR = TEXT.ERRORS[locale as Locale]

export const validationSchema = yup.object({
  name: yup.string().required(ERR.required),
  sender_email: yup.string().required(ERR.required).email(ERR.invalidEmail),
  theme: yup.string().required(ERR.required),
  message: yup.string().required(ERR.required),
  agree: yup.bool().oneOf([true], ERR.agreeIsRequired),
})

export const initialValues = {
  name: '',
  sender_email: '',
  theme: '',
  message: '',
  agree: true,
}
