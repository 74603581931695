import { Box, styled, Typography } from '@mui/material'
import { IContractShortResponse, ROLE } from 'api'
import { selectAuth } from 'modules/auth/slice'
import { CollapsibleTable, IMPulseDownloadButton, TableSkeleton } from 'modules/common/components'
import { ContractAdd } from 'modules/contracts/components'
import { useText } from 'modules/locale'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { COLUMNS_ADMIN, COLUMNS_USER } from './constants'
import { createRows } from './utils'

type Props = {
  isLoading?: boolean,
  data: IContractShortResponse[] | undefined,
}

export const ContractsTable: FC<Props> = ({ data, isLoading }) => {
  const { text } = useText('USER')
  const { consoleFor } = useSelector(selectAuth)
  const { id } = useParams<{ id: string }>()
  const columns = useMemo(() => consoleFor === ROLE.ADMIN ? COLUMNS_ADMIN : COLUMNS_USER, [consoleFor])
  const rows = useMemo(() => createRows(data), [data])

  return (
    <div>
      <Typography mb='20px' variant='h1'>{text.organizationContracts}</Typography>

      {
        consoleFor === ROLE.ADMIN ?
          (
            <>
              {id && (
                <Box py='20px'>
                  <ContractAdd companyId={id} />
                </Box>
              )}
            </>
          ) : (
            <>
              <Typography variant='body1' color='secondary'>{text.tableShowsAllLicenses}</Typography>
              <Box width='100%' display='flex' justifyContent='flex-end'>
                <IMPulseDownloadButton />
              </Box>
            </>
          )
      }

      <Box m='20px -20px 0'>
        {isLoading
          ? <TableSkeleton columns={5} rows={3} />
          : (
            <>
              <CollapsibleTable rows={rows} columns={columns} maxHeight='700px' />
              {rows.length ? null
                : (
                  <Empty>
                    <Typography align='center' width='300px'>{text.noContracts}</Typography>
                  </Empty>
                )
              }
            </>
          )
        }
      </Box>
    </div>
  )
}

const Empty = styled('div')`
  background: #fff;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
`
