import { Box, Typography } from '@mui/material'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { color } from 'styles/theme'

export const UserInfoHead: FC = () => {
  const { text } = useText('USER')
  const { my } = useSelector(selectMe)

  if (!my) {
    return null
  }

  const { name, surname, role } = my

  return (
    <Box p='60px 100px' mx='auto' width='1400px'>
      <Typography mb='10px' variant='subtitle1' color={color.success}>{text[role]}</Typography>
      <Typography variant='h1' color={color.surface}>{`${name} ${surname}`}</Typography>
    </Box>
  )
}
