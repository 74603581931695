import { Box, Typography } from '@mui/material'
import { Button } from 'modules/common/components'
import { useText } from 'modules/locale'
import React, { FC, useEffect, useState } from 'react'

type Props = {
  title: string
  description: string
  onResend: () => void
  loading?: boolean
}

export const ResendCode: FC<Props> = ({ title, description, onResend, loading }) => {
  const { text } = useText('AUTH')
  const [count, setCount] = useState(60)

  useEffect(() => {
    const id = setInterval(() => setCount(count - 1), 1000)
    if (count === 0) {
      clearInterval(id)
      return () => clearInterval(id)
    }

    return () => clearInterval(id)
  }, [count])

  return (
    <>
      <Box display='grid' gap='20px'>
        <Typography variant='h1'>{title}</Typography>
        <Typography variant='body1' color='secondary'>{description}</Typography>
      </Box>

      <Box display='flex' gap='1ch' alignItems='center' my='40px'>
        <Typography variant='body2' color='secondary'>{text.timerMessage}</Typography>
        <Typography variant='body1' fontWeight={500}>00:{String(count).length > 1 ? count : `0${count}`}</Typography>
      </Box>

      <Box display='grid' gap='20px' gridTemplateColumns='1fr 1fr'>
        <Button
          loading={loading}
          disabled={!!count}
          variant='contained'
          color='primary'
          onClick={onResend}
        >
          {text.sendAgain}
        </Button>

        <div />
      </Box>
    </>
  )
}

