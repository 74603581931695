import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  clearErrorField, clearMe,
  clearSuccessField,
  getUser,
  updateEmailAction,
  updatePassword,
  updateUserProfile,
} from 'modules/me/actions'
import { ERRORS, SUCCESS } from 'modules/me/constants'
import { Errors, IUser, Success, UpdateProfileValues } from 'modules/me/models'
import { RootState } from 'store/models'

export type IState = {
  isLoading: boolean;
  isFetching: boolean, // загрузка для updateEmail, updatePassword, updateAvatar
  errors: Errors,
  my: IUser | null;
  success: Success;
}
export const initialState: IState = {
  isLoading: false,
  isFetching: false,
  errors: { updateUserProfile: null, getUser: null, updatePassword: null, updateAvatar: null },
  success: { updatePassword: false, updateAvatar: false, updateProfile: false },
  my: null,
}

export const slice = createSlice({
  name: 'me',
  initialState,
  reducers: {},
  extraReducers: {
    [clearSuccessField.type]: (state, { payload }: PayloadAction<typeof SUCCESS[keyof typeof SUCCESS]>) => {
      state.success[payload] = false
    },
    [clearErrorField.type]: (state, { payload }: PayloadAction<typeof ERRORS[keyof typeof ERRORS]>) => {
      state.errors[payload] = null
    },
    [clearMe.type]: (state) => {
      state.my = null
    },

    [getUser.pending.type]: (state) => {
      state.errors.getUser = null
      state.isLoading = true
    },
    [getUser.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.my = payload
      state.isLoading = false
    },
    [getUser.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.errors.getUser = payload
      state.isLoading = false
    },

    [updateUserProfile.pending.type]: (state) => {
      state.errors.updateUserProfile = null
      state.isLoading = true
    },
    [updateUserProfile.fulfilled.type]: (state, { payload }: PayloadAction<UpdateProfileValues>) => {
      state.my && (state.my = { ...state.my, ...payload })
      state.success.updateProfile = true
      state.isLoading = false
    },
    [updateUserProfile.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.errors.updateUserProfile = payload
      state.isLoading = false
    },

    [updateEmailAction.type]: (state, { payload }: PayloadAction<string>) => {
      state.my && (state.my.email = payload)
    },

    [updatePassword.pending.type]: (state) => {
      state.errors.updatePassword = null
      state.isFetching = true
    },
    [updatePassword.fulfilled.type]: (state) => {
      state.success.updatePassword = true
      state.isFetching = false
    },
    [updatePassword.rejected.type]: (state, { payload }: PayloadAction<string>) => {
      state.errors.updatePassword = payload
      state.isFetching = false
    },

  },
})

export const selectMe = (state: RootState) => state.me
export default slice.reducer
