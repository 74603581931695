import { IconButton, InputAdornment, styled, TextFieldProps, TextField as MuiTextField } from '@mui/material'
import { EyeCloseIcon, EyeOpenIcon } from 'assets/svg'
import React, { FC, useState } from 'react'
import { color } from 'styles/theme'
import { TextField } from 'modules/common/components'

type Props = TextFieldProps & { muiInput?: boolean }

export const Password: FC<Props> = ({ muiInput, ...props }) => {
  const [visible, setVisible] = useState(false)
  const handleClickShowPassword = () => setVisible(true)
  const handleMouseDownPassword = () => setVisible(false)
  const endAdornment = (
    <InputAdornment position='end'>
      <StyledIconButton
        size='small'
        aria-label='toggle password visibility'
        edge='end'
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {visible ? <EyeOpenIcon color={color.high} /> : <EyeCloseIcon color={color.disabled} />}
      </StyledIconButton>
    </InputAdornment>
  )
  if (muiInput) {
    return (
      <MuiTextField
        {...props}
        type={visible ? 'text' : 'password'}
        InputProps={{ endAdornment, ...props.InputProps }}
      />
    )
  }

  return (
    <TextField
      {...props}
      type={visible ? 'text' : 'password'}
      InputProps={{ endAdornment, ...props.InputProps }}
    />
  )
}
const StyledIconButton = styled(IconButton)`
  &:hover {
    background: none;
  }
`

