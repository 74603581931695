import { IContractShortResponse } from 'api'
import { IAvailableLicences } from 'modules/organization/models'

const isPassed = (dateTime: string): boolean => {
  const date = Date.parse(dateTime)
  const now = Date.now()
  return (date - now) >= 0
}

export const normalizeLicenses = (contracts: IContractShortResponse[] | undefined, userId: string): IAvailableLicences => {
  if (!contracts) {
    return {} as IAvailableLicences
  }
  const initial = {}
  return contracts.reduce((acc, contract, i) => {
    // группа лицензий контракта
    const group = contract.licenses.reduce((res, l) => {
      const groupId = `${l.product_code}_${i}`
      if (res[groupId]) {
        (!l.attached_to_user_id && isPassed(contract.sub_end_date)) && res[groupId].availableIds.push(l.license_id)
        if (l.attached_to_user_id === userId) {
          res[groupId].availableIds.unshift(l.license_id)
          res[groupId].attachedToUser = l.attached_to_user_id === userId
        }
      } else {
        res[groupId] = {
          endDate: contract.sub_end_date,
          attachedToUser: l.attached_to_user_id === userId,
          availableIds: (!l.attached_to_user_id || l.attached_to_user_id === userId) ? [l.license_id] : [],
        }
      }

      return res
    }, {} as IAvailableLicences)

    return { ...acc, ...group }
  }, initial as IAvailableLicences)
}
