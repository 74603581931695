import { CloseIcon } from 'assets/svg'
import React, { CSSProperties } from 'react'
import { SnackbarProvider } from 'notistack'
import { IconButton } from '@mui/material'

const root: CSSProperties = {
  width: '80vw',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '1rem', // 16px
  lineHeight: '120%',
  padding: '0.75rem 1.5rem',
  display: 'flex',
  flexWrap: 'nowrap',
  margin: '0 auto',
}

const classes: { [key: string]: CSSProperties } = {
  variantSuccess: { backgroundColor: '#35AB48E5', ...root },
  variantError: { backgroundColor: '#AB4335E5', ...root },
  variantWarning: { backgroundColor: '#E3C316E5', ...root },
  variantInfo: { backgroundColor: 'rgba(20, 0, 220, 0.06)', ...root },
}

// кнопка куда передаем коллбэк закрыть snackbar.
export const ACTION = (onClick: () => void) => (
  <IconButton size='small' onClick={onClick}>
    <CloseIcon />
  </IconButton>
)

export const NotistackProvider: React.FC = ({ children }) => {
  return (
    <>
      <SnackbarProvider
        hideIconVariant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        classes={{ ...classes }}
      >
        {children}
      </SnackbarProvider>
    </>
  )
}
