import { Dialog, Typography } from '@mui/material'
import { useUpdateClassifierMutation } from 'api'
import { ClassifierForm, ClassifierFormValues } from 'modules/classifiers/components'
import { IClassifierBase } from 'modules/classifiers/models'
import { normalizeInitialValues } from 'modules/classifiers/utils'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import React, { FC } from 'react'

type Props = { open: boolean, classifier: IClassifierBase, onClose: () => void }

export const ClassifierUpdateDialog: FC<Props> = ({ open, classifier, onClose }) => {
  const { text } = useText('CLASSIFIERS')
  const [updateClassifier, { isLoading, error }] = useUpdateClassifierMutation()

  const handleSubmit = async (values: ClassifierFormValues) => {
    const date = values.date.toISO()
    await updateClassifier({ id: classifier.id, body: { ...values, date } }).unwrap()
    onClose()
  }

  useShowError(error)

  return (
    <>
      <Dialog maxWidth={false} open={open}>
        <Typography mb='20px' variant='h2'>{text.updateClassifier}</Typography>

        <ClassifierForm
          isLoading={isLoading}
          initialValues={normalizeInitialValues(classifier)}
          btn={text.update}
          onCancel={onClose}
          onSubmit={handleSubmit}
        />

      </Dialog>
    </>
  )
}

