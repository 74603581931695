import { fileApi } from 'api/entities/files/fileApi'
import { IFileUploadInput, IFormDataParams } from 'api/entities/files/models'

export const prepareFormData = (params: IFormDataParams, file: File, fileName?: string): FormData => {
  const formData = new FormData()
  formData.append('key', params.key)
  formData.append('policy', params.policy)
  formData.append('x-amz-algorithm', params['x-amz-algorithm'])
  formData.append('x-amz-credential', params['x-amz-credential'])
  formData.append('x-amz-date', params['x-amz-date'])
  formData.append('x-amz-signature', params['x-amz-signature'])

  formData.append('file', file, fileName)
  return formData
}

export const uploadFile = async (data: IFileUploadInput) => {
  try {
    const { file, params, url, fileName } = data

    const formData = prepareFormData(params, file, fileName)
    const response = await fileApi.uploadS3File(url, formData)
    if (response) {
      return { url: `${url}/${params.key}` }
    }

    return null
  } catch
  (e) {
    console.error(e)
    return null
  }
}

export const deletedFile = async (url: string) => {
  try {
    const response = await fileApi.deleteS3File(url)
    if (response) {
      return true
    }

    return null
  } catch
  (e) {
    console.error(e)
    return null
  }
}
