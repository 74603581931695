import { Link } from '@mui/material'
import { IContractShortResponse } from 'api'
import { CollapsibleRow, Status } from 'modules/common/components'
import { ROUTE } from 'modules/common/routes'
import { dateFormat } from 'modules/common/utils'
import { ContractActions, ContractSubRow, SubRowDates } from 'modules/contracts/components'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const createRows = (contracts: IContractShortResponse[] | undefined): CollapsibleRow[] => {
  if (!contracts) {
    return []
  }

  return contracts.map(contract => {
    const { payment_date, sign_date, sub_end_date, sub_start_date, _id, contract_id } = contract
    const dates: SubRowDates = { payment_date, sign_date, sub_end_date, sub_start_date }
    return {
      id: contract._id,
      contract: (
        <Link component={RouterLink} to={`${ROUTE.CONTRACT_OFFER}/${_id}`} color='primary' underline='none'>
          {contract_id}
        </Link>
      ),
      licenses: contract.licenses.length,
      status: <Status active={contract.is_active} />,
      validity: dateFormat(contract.sub_end_date),
      edit: <ContractActions contract={contract} />,
      subRow: <ContractSubRow licenses={contract.licenses} dates={dates} contractId={contract._id} />,
    }
  })
}
