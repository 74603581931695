import { ROLE } from 'api/entities/user'
import { selectAuth } from 'modules/auth/slice'
import { MyLicenses } from 'modules/me/components'
import { OrganizationLicenses } from 'modules/organization/components'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

export const Licenses: FC = () => {
  const { consoleFor } = useSelector(selectAuth)

  if (consoleFor === ROLE.ORG_ADMIN) {
    return (<OrganizationLicenses />)
  }

  return (
    <MyLicenses />
  )
}
