import { Box, CircularProgress } from '@mui/material'
import { useGetInvitationInfoQuery, useLazyAcceptInvitationQuery, useSignUpByInvitationMutation } from 'api'
import { SignUpValues } from 'modules/auth/components/SignUp/models'
import { INITIAL_VALUES } from 'modules/auth/components/SignUp/SignUp/constants'
import { normalizeValues } from 'modules/auth/components/SignUp/SignUpByInvitation/utils'
import { SignUpForm } from 'modules/auth/components/SignUp/SignUpForm'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { ROUTE } from 'modules/common/routes'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const SignUpByInvitation: FC = () => {
  const { guid } = useParams<{ guid: string }>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { data, isLoading, error } = useGetInvitationInfoQuery({ guid: guid || '' })
  const [acceptInvitation, { data: aData, isSuccess: aSuccess, error: aError }] = useLazyAcceptInvitationQuery()
  const [signInByInvitation, { isSuccess: sSuccess, error: sError }] = useSignUpByInvitationMutation()
  const { text } = useText('AUTH')

  const handleSubmit = useCallback(async (values: SignUpValues) => {
    if (guid) {
      await signInByInvitation(normalizeValues(values, guid)).unwrap()
    }
  }, [guid, signInByInvitation])

  useEffect(() => {
    if (aSuccess && aData) {
      enqueueSnackbar(text.successEntryToCompany, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      navigate(ROUTE.MAIN)
    }

    if (sSuccess) {
      enqueueSnackbar(text.successRegistered, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      navigate(ROUTE.MAIN)
    }
  }, [aData, aSuccess, closeSnackbar, enqueueSnackbar, navigate, sSuccess, text.successEntryToCompany, text.successRegistered])
  useEffect(() => {
    if (error) {
      console.error(error)
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
      navigate(ROUTE.MAIN)
    }
    if (aError) {
      console.error(aError)
      enqueueSnackbar(getError(aError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
      navigate(ROUTE.SIGN_IN)
    }
    if (data?.is_expired) {
      enqueueSnackbar(text.invitationIsExpired, { variant: 'error', action: ACTION(() => closeSnackbar()) })
      navigate(ROUTE.SIGN_IN)
    }
    if (data && !data.organization_name) {
      navigate(ROUTE.SIGN_UP)
    }
  }, [aError, closeSnackbar, data, data?.is_expired, enqueueSnackbar, error, navigate, text.invitationIsExpired])
  useEffect(() => {
    if (sError) {
      console.error(sError)
      enqueueSnackbar(getError(sError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, sError])
  useEffect(() => {
    if (guid && data?.user_exists) {
      acceptInvitation({ guid }).unwrap()
    }
  }, [acceptInvitation, data?.user_exists, guid])

  if (isLoading || !data || data.is_expired) {
    return null
  }

  if (data.user_exists) {
    return (
      <Box display='flex' justifyContent='center' pt='20vh' width='100%'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <SignUpForm
      disabledFields={['email', 'company']}
      initialValues={{ ...INITIAL_VALUES, company: data.organization_name || '', email: data.email_login }}
      onSubmit={handleSubmit}
    />
  )
}
