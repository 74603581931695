import { Box, Dialog, IconButton, styled, Typography } from '@mui/material'
import { useChangeClassifierOwnerMutation } from 'api'
import { CloseIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { Button, TextField } from 'modules/common/components'
import { useMessage } from 'modules/errorHandler/useMessage'
import { useShowError } from 'modules/errorHandler/useShowError'
import { useText } from 'modules/locale'
import React, { FC } from 'react'

type Props = { open: boolean, id: string, name: string, onClose: () => void }

export const ChangeOwnerDialog: FC<Props> = ({ open, name, id, onClose }) => {
  const { text } = useText('CLASSIFIERS')
  const [changeOwner, { isLoading, error, isSuccess }] = useChangeClassifierOwnerMutation()

  useShowError(error)
  useMessage(isSuccess && text.changeOwnerSuccess)

  const formik = useFormik({
    initialValues: { email: '' },
    // validationSchema: yup.object({ email: yup.string().required(err.required).email(err.invalidEmail) }),
    onSubmit: async ({ email }) => {
      await changeOwner({ id, email }).unwrap()
    },
  })

  const { handleSubmit, values, touched, errors, handleChange, handleBlur } = formik

  return (
    <>
      <Dialog open={open}>
        <StyledIconButton size='small' disabled={isLoading} onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography mb='20px' variant='h2'>{`${text.changeOwner} "${name}"`}</Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            name='email'
            value={values.email}
            label={text.userEmailOrId}
            fullWidth
            disabled={isLoading}
            error={touched.email && !!errors.email}
            helperText={(touched.email && errors.email) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Box mt='40px' columnGap='20px' display='grid' gridTemplateColumns='1fr 1fr'>
            <Button loading={isLoading} variant='contained' type='submit'>
              {text.change}
            </Button>
            <Button disabled={isLoading} variant='outlined' onClick={onClose}>
              {text.cancel}
            </Button>
          </Box>
        </form>

      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
