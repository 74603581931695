import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { IToken } from 'api'
import { IState } from 'modules/app/slice'
import { login } from 'modules/auth/actions'

export const initApp = createAsyncThunk(
  'app/initApp',
  async (_, { dispatch, getState }) => {
    const { app } = getState() as { app: IState }
    if (app.access) {
      await dispatch(login())
      return true
    }

    return true
  })

export const setTokens = createAction('app/setTokens', (data: IToken) => {
  localStorage.setItem('access', data.access)
  localStorage.setItem('refresh', data.refresh)
  return { payload: data }
})
export const clearTokens = createAction('app/clearTokens')
