export const INITIAL_VALUES = {
  step: 1,
  email: '',
  phone: '',
  password: '',
  repeatPassword: '',
  name: '',
  surname: '',
  company: '',
  position: '',

  agree: false,
}
