import { Box, Link, Skeleton, Typography } from '@mui/material'
import { IUpdateOrgInfoInput, useGetCompanyByIdQuery } from 'api'
import { randomInt } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useMemo } from 'react'
import { color } from 'styles/theme'
import { EditButton } from '../EditButton'

type Props = { id: string }

const width = `${randomInt(20)}%`

export const EditableInfoHead: FC<Props> = ({ id }) => {
  const { text } = useText('USER')
  const { data, isLoading } = useGetCompanyByIdQuery({ id })

  const initialValues: IUpdateOrgInfoInput = useMemo(() => ({
    _id: data?.company_id || '',
    name: data?.name || '',
    email: data?.email || '',
    phone: data?.phone || '',
    comment: data?.comment || '',
    address: '',

  }), [data?.comment, data?.company_id, data?.email, data?.name, data?.phone])

  return (
    <Box p='80px 100px' mx='auto' width='1400px'>
      <Typography mb='60px' variant='h1' color={color.surface}>{data?.name ||
        <Skeleton width={width} />}
      </Typography>

      <Box display='grid' gridTemplateColumns='50% 50%' gridAutoRows='min-content' gap='40px'>
        <div>
          <Typography mb='20px' variant='subtitle1' color={color.success}>{text.companyId}</Typography>
          <Typography variant='body1' color={color.surface}>
            {isLoading ? <Skeleton width={width} /> : data?.company_id || ''}
          </Typography>
        </div>

        <div>
          <Typography mb='20px' variant='subtitle1' color={color.success}>{text.contacts}</Typography>

          <Typography mb='10px' variant='body1' color={color.surface}>
            {isLoading ? <Skeleton width={width} /> : data?.address || ''}
          </Typography>
          <Typography mb='10px' variant='body1' color={color.surface}>
            {isLoading ? <Skeleton width={width} /> : data?.email || ''}
          </Typography>
          <Typography variant='body1' color={color.surface}>
            {isLoading ? <Skeleton width={width} /> : data?.phone || ''}
          </Typography>
        </div>

        <div>
          <Typography mb='20px' variant='subtitle1' color={color.success}>{text.admins}</Typography>
          {isLoading ? <Skeleton width={width} /> : data?.company_admins.map((a, i) => {
            return (
              <Typography key={a.user_id} mt={i === 0 ? 0 : '5px'} variant='body1' color={color.surface}>
                {`${a.name} ${a.surname}`}
              </Typography>
            )
          })}
        </div>

        <div>
          <Typography mb='20px' variant='subtitle1' color={color.success}>{text.additionalInfo}</Typography>
          <Typography variant='body1' color={color.surface}>
            {isLoading ? <Skeleton width={width} /> : data?.comment || ''}
          </Typography>
        </div>

        <div />
        {data ? <EditButton initialValues={initialValues} /> : <Link><Skeleton width={width} /></Link>}
      </Box>

    </Box>
  )
}
