import { Typography } from '@mui/material'
import { IContractShortResponse } from 'api'
import { normalizeLicenses } from 'modules/common/components/AvailableLicensesList/utils'
import { dateFormat } from 'modules/common/utils'
import React, { FC, useMemo } from 'react'

type Props = { userId: string, contracts: IContractShortResponse[] }

export const LicensesEndDatesList: FC<Props> = ({ userId, contracts }) => {
  const licenses = useMemo(() => normalizeLicenses(contracts, userId), [contracts, userId])

  return (
    <div>
      {Object.keys(licenses).map(license => {
        return (
          <Typography key={license} variant='body1' lineHeight='32px'>
            {dateFormat(licenses[license].endDate)}
          </Typography>
        )
      })}
    </div>
  )
}
