import { Box, Typography } from '@mui/material'
import { useText } from 'modules/locale'
import React, { FC } from 'react'

export const ClassifiersUserMemo: FC = () => {
  const { text } = useText('CLASSIFIERS')
  return (
    <>
      <Box alignItems='start' display='grid' gridAutoRows='min-content' gap='40px' width={500}>
        <Typography variant='h1'>{text.classifiersDataBase}</Typography>

        <Box display='grid' gap='20px'>
          <Typography variant='h2'>{text.userMemo}</Typography>
          <Typography variant='body1' color='secondary'>{text.userMemoText}</Typography>
        </Box>
      </Box>
    </>
  )
}
