export const CLASSIFIER_TYPES = {
  WORLD: 'WORLD_TYPE',
  COUNTRY: 'COUNTRY_TYPE',
  CORPORATE: 'CORPORATE_TYPE',
  PERSONAL: 'PERSONAL_TYPE',
  SHARED: 'SHARED_TYPE',
} as const

export const ACTIONS = {
  UPDATE: 'update',
  DELETE: 'delete',
  SHARE: 'share',
  CHANGE: 'change',
  REFUSE_SHARING: 'refuse_sharing',
} as const

export const ACCESS = {
  READ_ONLY: 'read_only',
  FULL: 'full',
} as const
