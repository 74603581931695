import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Locale, TEXT } from 'modules/locale'
import { LOCALE } from 'modules/locale/constants'

export const parseJwt = (token: string) => {
  const base64 = token.split('.')[1]
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
export const parseBasic = (token: string) => {
  const arr = decodeURIComponent(atob(token)
    .split('')
    .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))
    .split(':')

  return { email: arr[0], password: arr[1] }
}

export const dateFormat = (dateTime: string) => {
  const date = new Date(dateTime)
  return new Intl.DateTimeFormat(locale).format(date)
}

export const randomInt = (min = 0, max = 100) => {
  // случайное число от min до (max+1)
  const rand = min + Math.random() * (max + 1 - min)
  return Math.floor(rand)
}

export const newArr = (length: number) => Array(length).fill(null).map((_, i) => i)

const ERR = TEXT.ERRORS[locale as Locale]

export const getError = (error: FetchBaseQueryError | SerializedError | undefined) => {
  if (!error) {
    return {
      status: '',
      message: '',
    }
  }

  const { data } = error as FetchBaseQueryError

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    status: error?.status || error?.code,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    message: error?.status === 502 ? ERR.status502 : (data?.message || error?.message),

  }
}
