import { Box, Dialog, IconButton, Link, styled, Typography } from '@mui/material'
import { IUpdateOrgInfoInput, useUpdateOrgInfoMutation } from 'api'
import { CloseIcon } from 'assets/svg'
import { useFormik } from 'formik'
import { Button, PhoneField, TextField } from 'modules/common/components'
import { POINTER } from 'modules/common/constants'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useEffect, useState } from 'react'
import { color } from 'styles/theme'
import { validationSchema } from './constants'

type Props = { initialValues: IUpdateOrgInfoInput }

export const EditButton: FC<Props> = ({ initialValues }) => {
  const { text } = useText('USER')
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [updateInfo, { isSuccess, isLoading, error }] = useUpdateOrgInfoMutation()
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: IUpdateOrgInfoInput) => {
      await updateInfo({ ...values }).unwrap()
    },
  })

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.dataSuccessUpdated, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      handleClose()
    }
  }, [closeSnackbar, enqueueSnackbar, error, isSuccess, text.dataSuccessUpdated])

  const { handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue } = formik
  const handlePhoneChange = (value: any) => {
    setFieldValue('phone', value)
  }

  return (
    <>
      <Link {...POINTER} color={color.surface} onClick={handleOpen}>{text.edit}</Link>

      <Dialog open={open} onClose={isLoading ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={isLoading} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography variant='h2'>{text.editCompanyInfo}</Typography>
        <Box component='form' display='grid' gap='5px' mt='20px' onSubmit={handleSubmit}>
          <TextField
            fullWidth
            name='name'
            value={values.name}
            label={text.organizationName}
            disabled={isLoading}
            error={touched.name && !!errors.name}
            helperText={(touched.name && errors.name) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <TextField
            fullWidth
            name='address'
            value={values.address}
            label={text.address}
            disabled={isLoading}
            error={touched.address && !!errors.address}
            helperText={(touched.address && errors.address) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <TextField
            fullWidth
            name='email'
            value={values.email}
            label={text.email}
            disabled={isLoading}
            error={touched.email && !!errors.email}
            helperText={(touched.email && errors.email) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <PhoneField
            fullWidth
            name='phone'
            value={values.phone}
            label={text.phone}
            disabled={isLoading}
            error={touched.phone && !!errors.phone}
            helperText={(touched.phone && errors.phone) ?? ' '}
            variant='outlined'
            onChange={handlePhoneChange}
            onBlur={handleBlur}
          />

          <TextField
            fullWidth
            rows={4}
            name='comment'
            value={values.comment}
            label={text.comment}
            disabled={isLoading}
            multiline
            error={touched.comment && !!errors.comment}
            helperText={(touched.comment && errors.comment) ?? ' '}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Box display='flex' gap='20px' mt='20px'>
            <Button autoFocus loading={isLoading} type='submit' fullWidth variant='contained'>{text.save}</Button>
            <Button fullWidth disabled={isLoading} variant='outlined' onClick={handleClose}>{text.cancel}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
