import { Box } from '@mui/material'
import {
  IUserFromAdmin,
  ROLE,
  useChangeUserOrgRoleMutation,
  useDeleteUserFromOrgMutation,
  useGetCompanyLicensesStatsQuery,
  useGetOrganizationContractsQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
} from 'api'
import { normalizeTableUsers } from 'modules/admin/components/Organization/utils'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { ContractsTable } from 'modules/contracts'
import { useText } from 'modules/locale'
import { EmployeesTable } from 'modules/organization/components'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const Organization: FC = () => {
  const [isSearchResults, setIsSearchResults] = useState(false)
  const [limit, setLimit] = useState(15)
  const [tableData, setTableData] = useState<IUserFromAdmin[]>()
  const { id } = useParams<{ id: string }>()
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { data: contracts, isLoading: cLoading, error: cError } = useGetOrganizationContractsQuery({ id: id ?? '' })
  const { data: users, error: uError, isLoading: uLoading } = useGetUsersQuery({ company_id: id, limit })
  const [searchUsers, { data: searchData, isFetching: searching }] = useLazyGetUsersQuery()
  const { data: statistic, error: sError } = useGetCompanyLicensesStatsQuery({ id: id ?? '' })
  const [deleteUser, { isLoading: isDeleting, isSuccess, error: delError }] = useDeleteUserFromOrgMutation()
  const [changeRole, { isLoading: isRoleChanging, error: roleChError }] = useChangeUserOrgRoleMutation()

  const options = useMemo(() => {
    return searchData?.users.map(it => ({
      value: it.user_id,
      label: `${it.contact_info.name} ${it.contact_info.surname}`,
    }))
  }, [searchData?.users])

  const handleChangeRole = useCallback(async (userId: string, role: ROLE) => {
    if (id) {
      await changeRole({ user_id: userId, role, company_id: id }).unwrap()
    }
  }, [changeRole, id])
  const handleChoose = useCallback((value: string | null) => {
    if (!value) {
      setIsSearchResults(false)
      setTableData(users?.users || [])
      return
    }
    setIsSearchResults(true)
    setTableData(searchData?.users.filter(it => it.user_id === value) || [])
  }, [searchData?.users, users?.users])
  const handleSearch = useCallback(async (value: string | null) => {
    if (value) {
      await searchUsers({ search_query: value, company_id: id }).unwrap()
    }
  }, [id, searchUsers])
  const handleFetchMore = useCallback(() => {
    if (!isSearchResults) {
      setLimit(prev => prev + 15)
    }
  }, [isSearchResults])
  const handleDeleteUser = useCallback(async (userId: string) => {
    if (id) {
      await deleteUser({ id: userId, companyId: id }).unwrap()
    }
  }, [deleteUser, id])

  useEffect(() => {
    if (cError || uError || sError) {
      console.error(cError || uError || sError)
      enqueueSnackbar('ОШИБКА', { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, cError, uError, sError])
  useEffect(() => {
    if (users) {
      setTableData(users.users)
    }
  }, [users])

  // +удаление
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.userSuccessDeleted, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, isSuccess, text.userSuccessDeleted])
  useEffect(() => {
    if (delError) {
      console.error(delError)
      enqueueSnackbar(getError(delError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, delError, enqueueSnackbar])

  // изм роли
  useEffect(() => {
    if (roleChError) {
      enqueueSnackbar(getError(roleChError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, roleChError])

  return (
    <Box p='80px 100px'>
      <ContractsTable data={contracts} isLoading={cLoading} />

      <Box height='80px' />

      <EmployeesTable
        users={normalizeTableUsers(tableData)}
        options={options}
        total={isSearchResults ? 1 : users?.users_total_number}
        isLoading={uLoading || cLoading}
        contracts={contracts}
        statistic={statistic}
        isSearching={searching}
        isDeleting={isDeleting}
        isRoleChanging={isRoleChanging}
        onChoose={handleChoose}
        onSearch={handleSearch}
        onFetchMore={handleFetchMore}
        onUserDelete={handleDeleteUser}
        onRoleChange={handleChangeRole}
      />
    </Box>
  )
}
