import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseUrl } from 'api/constants'
import { ITokenRefreshResponse } from 'api/entities/auth'
import { setTokens } from 'modules/app/actions'
import { IState as IAppState } from 'modules/app/slice'
import { logout } from 'modules/auth/actions'
import { IState as IAuthState } from 'modules/auth/slice'
import { normalizeRefreshTokenResponse } from 'modules/auth/utils'

/**
 * baseQuery для RTK query
 */
export const baseQueryBase = fetchBaseQuery({ baseUrl })

/**
 * кастомный baseQuery с запросом на обновление токена
 */

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const store = api.getState() as { app: IAppState, auth: IAuthState }
  const { access, refresh } = store.app
  const { isAuth } = store.auth
  let result = await baseQueryBase({ headers: { Authorization: `Bearer ${access}` }, ...args as FetchArgs }, api, extraOptions)

  if (isAuth && result.error?.status === 403) {
    const refreshResult = await baseQueryBase({
      url: '/user/refresh-access-token',
      headers: { Authorization: `Bearer ${refresh}` },
    }, api, extraOptions)

    if (refresh && refreshResult.data) {
      const refreshData = refreshResult.data as { scope: ITokenRefreshResponse }
      await api.dispatch(setTokens(normalizeRefreshTokenResponse(refreshData.scope, refresh)))
      result = await baseQueryBase({ headers: { Authorization: `Bearer ${refreshData.scope.access_token}` }, ...args as FetchArgs }, api, extraOptions)
    } else {
      api.dispatch(logout())
    }
  }

  return result
}

