import { Box, Typography } from '@mui/material'
import {
  IUserFromAdmin,
  ROLE,
  useChangeUserPortalRoleMutation,
  useDeleteUserMutation,
  useGetContractsQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
} from 'api'
import { CollapsibleTable, Search, SendInvitationForm, TableSkeleton } from 'modules/common/components'
import { ACTION } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { selectMe } from 'modules/me/slice'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { COLUMNS } from './constants'
import { createRows } from './utils'

export const Users: FC = () => {
  const [isSearchResults, setIsSearchResults] = useState(false)
  const [limit, setLimit] = useState(15)
  const [tableData, setTableData] = useState<IUserFromAdmin[]>()
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { data, isLoading: contractLoading, isFetching } = useGetUsersQuery({ limit })
  const [searchUsers, { data: searchData, isFetching: isSearching }] = useLazyGetUsersQuery()
  const { data: contracts, isLoading: usersLoading, error } = useGetContractsQuery()
  const [deleteUser, { isLoading: isDeleting, isSuccess, error: delError }] = useDeleteUserMutation()
  const [changeRole, {
    isLoading: isRoleChanging,
    error: roleChError,
    isSuccess: isRoleChanged,
  }] = useChangeUserPortalRoleMutation()

  const { my } = useSelector(selectMe)

  const handleFetchMore = useCallback(() => {
    if (!isSearchResults) {
      setLimit(prev => prev + 15)
    }
  }, [isSearchResults])
  const handleSearch = useCallback(async (value: string | null) => {
    if (value) {
      await searchUsers({ search_query: value }).unwrap()
    }
  }, [searchUsers])
  const handleChoose = useCallback((value: string | null) => {
    if (!value) {
      setIsSearchResults(false)
      setTableData(data?.users || [])
      return
    }
    setIsSearchResults(true)
    setTableData(searchData?.users.filter(it => it.user_id === value) || [])
  }, [data?.users, searchData?.users])
  const handleDeleteUser = useCallback(async (userId: string) => {
    await deleteUser({ id: userId }).unwrap()
  }, [deleteUser])
  const handleChangeRole = useCallback(async (userId: string, role: ROLE) => {
    await changeRole({ user_id: userId, role: role }).unwrap()
  }, [changeRole])

  const options = useMemo(() => {
    return searchData?.users.map(it => ({
      value: it.user_id,
      label: `${it.contact_info.name} ${it.contact_info.surname}`,
    }))
  }, [searchData?.users])
  const rows = useMemo(() => createRows(tableData, contracts?.contracts, my?.id, handleChangeRole, isRoleChanging, handleDeleteUser, isDeleting), [contracts?.contracts, handleChangeRole, handleDeleteUser, isDeleting, isRoleChanging, my?.id, tableData])

  useEffect(() => {
    if (error) {
      console.error(error)
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])
  useEffect(() => {
    if (data) {
      setTableData(data.users)
    }
  }, [data])

  // +удаление
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.userSuccessDeleted, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
    if (isRoleChanged) {
      enqueueSnackbar(text.successRoleChanged, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, isRoleChanged, isSuccess, text.successRoleChanged, text.userSuccessDeleted])
  useEffect(() => {
    if (delError) {
      console.error(delError)
      enqueueSnackbar(getError(delError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, delError, enqueueSnackbar])

  // изм роли
  useEffect(() => {
    if (roleChError) {
      enqueueSnackbar(getError(roleChError).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, roleChError])

  return (
    <div>
      <Typography mb='20px' variant='h1'>{text.allUsers}</Typography>
      <Box display='flex' gap='10px' alignItems='center'>
        <Typography variant='subtitle1' color='secondary'>{text.totalUsers}</Typography>
        <Typography variant='subtitle1' color='primary' fontWeight={500}>{data?.users_total_number}</Typography>
      </Box>

      <Box mt='40px' mb='24px' display='flex' justifyContent='space-between'>
        <SendInvitationForm fromAdmin />
        <Search options={options} loading={isSearching} onSearch={handleSearch} onChoose={handleChoose} />
      </Box>

      <Box mx='-20px'>
        {(usersLoading || contractLoading)
          ? <TableSkeleton columns={4} rows={4} />
          : (
            <CollapsibleTable
              rows={rows}
              columns={COLUMNS}
              iconAlign='flex-start'
              maxHeight='700px'
              fetching={isFetching}
              total={data?.users_total_number}
              fetchData={handleFetchMore}
            />
          )}
      </Box>
    </div>
  )
}
