import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { ClassifierActions } from 'modules/classifiers/components/ClassifierActions'
import { IClassifierBase } from 'modules/classifiers/models'
import { ROUTE } from 'modules/common/routes'
import { useText } from 'modules/locale'
import { SEARCH_PARAM } from 'pages/classifiers/constants'
import React, { FC, useMemo } from 'react'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'

type Props = { group: string, classifier?: IClassifierBase }
const initialActions = {
  change: true,
  delete: true,
  share: true,
  update: true,
  refuse_sharing: true,
}

export const ClassifierInfo: FC<Props> = ({ group, classifier }) => {
  const [searchParams] = useSearchParams()
  const { text } = useText('CLASSIFIERS')

  const { country, type } = useMemo(() => ({
    country: searchParams.get(SEARCH_PARAM.COUNTRY),
    type: searchParams.get(SEARCH_PARAM.TYPE) || '',
  }), [searchParams])

  const actions = useMemo(() => {
    const result = { ...initialActions }

    if (!classifier?.userCanEdit) {
      result.change = false
      result.delete = false
      result.share = false
      result.update = false
    }

    if (!classifier?.isSharedToUser) {
      result.refuse_sharing = false
    }

    return result
  }, [classifier?.isSharedToUser, classifier?.userCanEdit])

  return (

    <Box alignItems='start' display='grid' gridAutoRows='min-content' rowGap='20px' mb='20px'>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h1'>{classifier?.name || group}</Typography>
        {classifier && <ClassifierActions actions={actions} classifier={classifier} />}
      </Box>

      <Breadcrumbs aria-label='breadcrumb' sx={{ pb: '20px' }}>
        <Link component={RouterLink} underline='hover' color='inherit' to={ROUTE.CLASSIFIERS}>{text[type]}</Link>

        {country && <Typography color='secondary'>{country}</Typography>}
        <Typography color='secondary'>{group}</Typography>
      </Breadcrumbs>

      {classifier && (
        <>
          <Box display='flex' columnGap='80px'>
            <Box display='flex' columnGap='10px'>
              <Typography noWrap variant='body1' color='secondary'>{text.classifierId}</Typography>
              <Typography noWrap variant='body1'>{classifier.id}</Typography>
            </Box>

            <Box display='flex' columnGap='10px'>
              <Typography noWrap variant='body1' color='secondary'>{text.version}</Typography>
              <Typography noWrap variant='body1'>{classifier.version}</Typography>
            </Box>

            <Box display='flex' columnGap='10px'>
              <Typography noWrap variant='body1' color='secondary'>{text.updated}</Typography>
              <Typography noWrap variant='body1'>
                {DateTime.fromISO(classifier.date).toFormat('dd.MM.yyyy')}
              </Typography>
            </Box>

            {/* <Box display='flex' columnGap='10px'> */}
            {/*   <Typography noWrap variant='body1' color='secondary'>{text.recognizesClasses}</Typography> */}
            {/*   <Typography noWrap variant='body1'>{classifier.observers}</Typography> */}
            {/* </Box> */}
          </Box>
        </>
      )}
    </Box>
  )
}
