import { Link, Typography } from '@mui/material'
import { IOrganizationResponse } from 'api'
import { CompanyDeleteButton } from 'modules/admin/components/CompanyDeleteButton'
import { Row } from 'modules/common/components'
import { ROUTE } from 'modules/common/routes'
import { dateFormat } from 'modules/common/utils'
import { Locale, LOCALE, TEXT } from 'modules/locale'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { color } from 'styles/theme'

const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
const T = TEXT.USER[locale as Locale]

export const createClientLink = (name: string, id: string) => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Link underline='none' component={RouterLink} color='primary' to={`${ROUTE.CLIENTS}/${id}`} onClick={handleClick}>
      {name}
    </Link>
  )
}

export const createRows = (data: IOrganizationResponse[] | undefined): Row[] => {
  if (!data) {
    return []
  }

  return data.map(org => {
    const contract = org.license_stats && (org.license_stats['IMP-Cls-Comm'] || org.license_stats['IMP-Cls-Eval'])
    return ({
      organization: createClientLink(org.name, org._id),
      license: (
        <Typography variant='body1' color={contract ? 'primary' : color.disabled}>
          {contract?.product || T.noData}
        </Typography>
      ),
      count: (
        <Typography variant='body1' color={contract ? 'primary' : color.disabled}>
          {contract?.number || 0}
        </Typography>
      ),
      contractExpires: (
        <Typography variant='body1' color={contract ? 'primary' : color.disabled}>
          {contract?.sub_end_date ? dateFormat(contract.sub_end_date) : '-'}
        </Typography>
      ),
      organizationId: org._id,
      actions: <CompanyDeleteButton id={org._id} />,
    })
  })
}
