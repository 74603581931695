import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  styled,
} from '@mui/material'
import { ArrowDownFilled, SearchIcon } from 'assets/svg'
import { useText } from 'modules/locale'
import React, { ChangeEvent, FC, KeyboardEvent, MouseEvent, SyntheticEvent, useEffect, useState } from 'react'
import { blue } from 'styles/theme'
import { Button, TextField } from '..'

type Props =
  Partial<AutocompleteProps<Option, undefined, undefined, true>>
  & {
  onSearch?: (value: string | null, e?: SyntheticEvent, variant?: string | null) => void,
  onChoose?: (value: string | null, e?: SyntheticEvent, variant?: string | null) => void,
  minWidth?: string
  variants?: Option[]
  onVariantChoose?: (value: string) => void
  onButtonClick?: () => void
  liveSearch?: boolean
  linkItem?: boolean
}
export type Option = { value: string, label: string }

export const Search: FC<Props> = ({
  options,
  onSearch,
  minWidth,
  onButtonClick,
  onVariantChoose,
  variants,
  onChoose,
  liveSearch = true,
  linkItem,
  ...props
}) => {
  const { text } = useText('USER')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [variant, setVariant] = useState<string | null>(variants?.length ? variants[0].value : null)
  const handleOpenMenu = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)
  const handleClickVariant = (e: MouseEvent<HTMLLIElement>) => {
    const { id } = e.currentTarget
    onVariantChoose && onVariantChoose(id)
    setVariant(id)
    setAnchorEl(null)
  }

  const handleSearch = (e: SyntheticEvent, value: string | null | Option) => {
    if (onSearch) {
      onSearch(value as string | null, e, variant)
    }
  }

  const handleOtherSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value ? String(e.currentTarget.value) : null
    if (onSearch) {
      onSearch(value, e, variant)
    }
  }
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onButtonClick) {
      onButtonClick()
    }
  }
  const handleChoose = (e: SyntheticEvent, value: string | null | Option) => {
    if (onChoose) {
      const choosed = value as Option | null
      onChoose(choosed?.value ?? null, e, variant)
    }
  }
  useEffect(() => {
    if (variants && variants.length) {
      setVariant(variants[0].value)
    }
  }, [variants])

  return (
    <>
      <Wrapper width={(props.fullWidth || !liveSearch) ? '100%' : 'auto'} minWidth={minWidth ?? '285px'}>

        {!variants?.length ? null
          : (
            <StyledButton variant='contained' color='primary' endIcon={<ArrowDownFilled />} onClick={handleOpenMenu}>
              {variants?.find(it => it.value === variant)?.label}
            </StyledButton>
          )}

        {liveSearch
          ? (
            <Autocomplete
              loadingText={(
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <CircularProgress size={20} />
                </Box>
              )}
              fullWidth
              freeSolo
              disablePortal
              clearOnEscape
              autoComplete
              options={options || []}
              ListboxProps={{
                style: {
                  boxShadow: '0 4px 20px rgba(44, 51, 141, 0.1)',
                  borderRadius: '10px',
                  margin: '10px 1px 1px 1px',
                },
              }}
              renderOption={(props, option) => {
                return (
                  <MenuItem{...props} key={option.value} value={option.value}>
                    {linkItem ? <A href={`#${option.value}`}>{option.label}</A> : option.label}
                  </MenuItem>
                )
              }}
              renderInput={(params) => (
                <StyledTextField{...params} placeholder={text.search} InputProps={{ ...params.InputProps }} />
              )}
              {...props}
              onKeyPress={handleKeyPress}
              onInputChange={handleSearch}
              onChange={handleChoose}
            />
          ) : (
            <Box width='100%'>
              <StyledTextField
                value={props.value || ''}
                placeholder={text.search}
                onChange={handleOtherSearch}
                onKeyPress={handleKeyPress}
              />
            </Box>

          )
        }

        <SearchButton color='primary' onClick={onButtonClick}>
          <SearchIcon />
        </SearchButton>
      </Wrapper>

      <StyledMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -10, horizontal: 'left' }}
        onClose={handleCloseMenu}
      >
        {variants?.map(it => {
          return (
            <MenuItem
              key={it.value}
              id={it.value}
              selected={it.value === variant}
              disableRipple
              onClick={handleClickVariant}
            >
              {it.label}
            </MenuItem>
          )
        })}
      </StyledMenu>
    </>

  )
}
const imp = '!important'
const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(72, 90, 220, 0.05);
  background: #fff;
  height: min-content;
`

const StyledButton = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #0F0F0F;
  background: #29308B1A;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  white-space: nowrap;
  padding: 16px 40px;
  min-width: 190px;
`
const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    background: #fff;
    border: none;
    min-width: 190px;
    box-shadow: 0 4px 20px rgba(44, 51, 141, 0.1);
    border-radius: 10px;
  }
`

const StyledTextField = styled(TextField)`
  width: 100% ${imp};

  & fieldset {
    border: none;
  }
`

const SearchButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;

  height: 50px;
  width: 50px;
  border-radius: 0 10px 10px 0;
  background: ${blue[800]};

  &:hover {
    background: ${blue[600]};
  }
`

const A = styled('a')`
  text-decoration: none;
  color: inherit;
`
