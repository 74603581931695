import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store/models'

export const setNavItem = createAction<string[]>('setNavItem')
export const closeNavItem = createAction<string>('closeNavItem')
export const setTreeItems = createAction<string[]>('setTreeItems')
export const closeTreeItem = createAction<string>('closeTreeItem')
export const clearOpenedItems = createAction('clearOpenedItems')

export type IState = {
  openedNavItems: Record<string, boolean>
  openedTableItems: Record<string, boolean>
}
export const initialState: IState = {
  openedNavItems: {},
  openedTableItems: {},
}

export const slice = createSlice({
  name: 'classifiers',
  initialState,
  reducers: {},
  extraReducers: {
    [clearOpenedItems.type]: (state) => {
      state.openedNavItems = {}
      state.openedTableItems = {}
    },

    [setNavItem.type]: (state, { payload }: PayloadAction<string[]>) => {
      state.openedNavItems = payload.reduce((acc, id) => {
        acc[id] = true
        return acc
      }, {} as Record<string, boolean>)
    },
    [closeNavItem.type]: (state, { payload }: PayloadAction<string>) => {
      state.openedNavItems[payload] = false
    },

    [setTreeItems.type]: (state, { payload }: PayloadAction<string[]>) => {
      state.openedTableItems = payload.reduce((acc, id) => {
        acc[id] = true
        return acc
      }, {} as Record<string, boolean>)
    },

    [closeTreeItem.type]: (state, { payload }: PayloadAction<string>) => {
      state.openedTableItems[payload] = false
    },
  },
})

export const selectClassifiers = (state: RootState) => state.classifiers
export default slice.reducer
