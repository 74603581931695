import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material'
import { useGetAppLinksQuery } from 'api'
import { CloseIcon, DownloadIcon } from 'assets/svg'
import { POINTER } from 'modules/common/constants'
import { useText } from 'modules/locale'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'

export const IMPulseDownloadButton: FC = () => {
  const { text } = useText('USER')
  const { data, isLoading, error } = useGetAppLinksQuery()
  const [open, setOpen] = useState(false)
  const [link, setLink] = useState(data ? data[0].link : '')

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setLink(e.currentTarget.value)
  useEffect(() => {
    if (data) {
      setLink(data[0].link)
    }
  }, [data])
  return (
    <>
      <Link
        {...POINTER}
        variant='body1'
        fontWeight={500}
        color='secondary'
        display='flex'
        alignItems='center'
        gap='10px'
        onClick={handleOpen}
      >
        {text.downloadImpulse}
        <DownloadIcon />
      </Link>

      <Dialog open={open} onClose={handleClose}>
        <StyledIconButton size='small' onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography mb='20px' variant='h2'>{text.downloadImpulse}</Typography>
        {
          error
            ? <Typography variant='body1' color='secondary'>{text.getAppDownloadLinkError}</Typography>
            : (
              <>
                {isLoading
                  ? (
                    <Box height='100px' display='flex' justifyContent='center' alignItems='center'>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <RadioGroup value={link} onChange={handleChange}>
                        {data?.map(it => (<FormControlLabel
                          key={it.link}
                          value={it.link}
                          control={<Radio />}
                          label={it.name} />))}
                      </RadioGroup>

                      <Box display='flex' gap='20px' mt='40px'>
                        <Button
                          autoFocus
                          fullWidth
                          variant='contained'
                          href={link}
                          onClick={handleClose}
                        >
                          {text.download}
                        </Button>
                        <Button fullWidth variant='outlined' onClick={handleClose}>{text.cancel}</Button>
                      </Box>
                    </>
                  )}
              </>
            )}
      </Dialog>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
