import { Box, Checkbox, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { BackBtn } from 'modules/auth/components/BackBtn'
import { privacyTerms, validationSchema } from 'modules/auth/components/SignUp/constants'
import { SignUpValues } from 'modules/auth/components/SignUp/models'
import { Button, FormControlLabel, Password, PhoneField } from 'modules/common/components'
import { useText } from 'modules/locale'
import React, { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  initialValues: SignUpValues,
  onSubmit: (values: SignUpValues) => void,
  disabledFields?: string[]
}

export const SignUpForm: FC<Props> = ({ initialValues, onSubmit, disabledFields }) => {
  const [step, setStep] = useState(1)
  const { text } = useText('AUTH')
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (step === 1) {
        setStep(2)
        return
      }
      await onSubmit(values)
    },
  })
  const { handleSubmit, setFieldValue, handleChange, values, handleBlur, errors, touched, isSubmitting } = formik
  const handleBack = () => {
    if (step === 2) {
      setStep(1)
      return
    }
    navigate(-1)
  }
  const handlePhoneChange = (value: any) => {
    setFieldValue('phone', value)
  }

  const fieldProps = useMemo(() => ({
    onChange: handleChange,
    onBlur: handleBlur,
    disabled: isSubmitting,
    fullWidth: true,
  }), [handleBlur, handleChange, isSubmitting])

  return (
    <>
      <Box display='grid' gap='20px'>
        <Typography variant='h1'>{`${text.signUp}. ${text.step} ${step}`}</Typography>
        <Typography variant='body1' color='secondary'>{text.allFieldsRequired}</Typography>
      </Box>

      <Box mt='40px' component='form' onSubmit={handleSubmit}>

        <Box gap='10px' display={step !== 1 ? 'none' : 'grid'}>
          <TextField
            name='email'
            value={values.email}
            label={text.login}
            error={touched.email && !!errors.email}
            helperText={(touched.email && errors.email) ?? ' '}
            {...fieldProps}
            disabled={disabledFields?.includes('email') || isSubmitting}

          />

          <PhoneField
            name='phone'
            value={values.phone}
            label={text.phone}
            error={touched.phone && !!errors.phone}
            helperText={(touched.phone && errors.phone) ?? ' '}
            variant='outlined'
            {...fieldProps}
            onChange={handlePhoneChange}
          />

          <Box display='flex' gap='20px' alignItems='center'>
            <Password
              muiInput
              autoComplete='new-password'
              name='password'
              value={values.password}
              label={text.password}
              error={touched.password && !!errors.password}
              helperText={(touched.password && errors.password) ?? ' '}
              {...fieldProps}
            />

            <Password
              muiInput
              autoComplete='new-password'
              name='repeatPassword'
              value={values.repeatPassword}
              label={text.repeatPassword}
              error={touched.repeatPassword && !!errors.repeatPassword}
              helperText={(touched.repeatPassword && errors.repeatPassword) ?? ' '}
              {...fieldProps}
            />
          </Box>
        </Box>

        <Box gap='10px' display={step !== 2 ? 'none' : 'grid'}>
          <Box display='flex' gap='20px' alignItems='center'>
            <TextField
              name='name'
              value={values.name}
              label={text.name}
              error={touched.name && !!errors.name}
              helperText={(touched.name && errors.name) ?? ' '}
              {...fieldProps}
            />

            <TextField
              name='surname'
              value={values.surname}
              label={text.surname}
              error={touched.surname && !!errors.surname}
              helperText={(touched.surname && errors.surname) ?? ' '}
              {...fieldProps}
            />
          </Box>

          <TextField
            name='company'
            value={values.company}
            label={text.company}
            error={touched.company && !!errors.company}
            helperText={(touched.company && errors.company) ?? ' '}
            {...fieldProps}
            disabled={disabledFields?.includes('company') || isSubmitting}
          />

          <TextField
            name='position'
            value={values.position}
            label={text.position}
            error={touched.position && !!errors.position}
            helperText={(touched.position && errors.position) ?? ' '}
            {...fieldProps}
          />
        </Box>

        <FormControlLabel
          sx={{ display: step !== 2 ? 'none' : '' }}
          error={touched.agree && !!errors.agree}
          name='agree'
          control={<Checkbox checked={values.agree} onChange={handleChange} />}
          label={privacyTerms(text)}
          disabled={isSubmitting}
          onBlur={handleBlur}
        />

        <Box display='grid' gap='20px' mt='40px' gridTemplateColumns='1fr 1fr'>
          <Button type='submit' loading={isSubmitting} fullWidth variant='contained' color='primary'>
            {step === 2 ? text.createAccount : text.next}
          </Button>
          <div />
        </Box>

      </Box>

      <BackBtn onClick={handleBack}> {step === 2 ? text.back : text.backToMain}</BackBtn>
    </>
  )
}
