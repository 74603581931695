import { Box } from '@mui/material'
import React, { FC } from 'react'

type Props = { description: string | undefined, attributes: Record<string, string> | null }

export const TooltipDescription: FC<Props> = ({ description, attributes }) => {
  return (
    <>
      {description && description}

      {attributes && Object.keys(attributes).map((key: string) => (
        <Box key={key} m='4px 0'>
          {key} : {attributes[key]}
        </Box>
      ))}
    </>
  )
}
