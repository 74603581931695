import { Link, Menu, MenuItem, styled } from '@mui/material'
import { ArrowDownFilled } from 'assets/toggle'
import { POINTER } from 'modules/common/constants'
import React, { FC, MouseEvent, ReactText, useState } from 'react'
import { color } from 'styles/theme'

type Props = {
  value: ReactText,
  options: { value: string, label: string }[],
  onSelect: (e: MouseEvent<HTMLLIElement>) => void
  disabled?: boolean
}

export const SmallSelect: FC<Props> = ({ value, options, disabled, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleOpen = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    onSelect(e)
    handleClose()
  }

  return (
    <div>
      <Link
        {...POINTER}
        underline='none'
        variant='body1'
        color={anchorEl ? color.high : color.medium}
        display='flex'
        alignItems='center'
        gap='24px'
        onClick={disabled ? undefined : handleOpen}
      >
        {options.find(it => it.value === value)?.label || ''}
        <Icon opened={anchorEl ? 'on' : ''} />
      </Link>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -10, horizontal: 'left' }}
        onClose={handleClose}
      >
        {options.map(it => {
          return (
            <StyledItem key={it.value} selected={it.value === value} id={it.value} onClick={handleClick}>
              {it.label}
            </StyledItem>
          )
        })}
      </Menu>
    </div>
  )
}

const StyledItem = styled(MenuItem)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: ${({ selected }) => selected ? color.high : color.medium};
  padding: 5px 10px;

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 5px;
  }
`
// &:before {
//   content: '';
//   height: {({ selected }) => selected ? '4px' : 0};
//   width: {({ selected }) => selected ? '4px' : 0};
//   border-radius: 50%;
//   background: ${color.high};
//   {({ selected }) => selected ? color.high : color.medium};
// }

const Icon = styled(ArrowDownFilled)<{ opened: string }>`
  transform: ${({ opened }) => opened ? 'rotate(180deg)' : 'none'};;
`

