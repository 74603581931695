import { Menu, MenuItem } from '@mui/material'
import {
  ClassifierRefuseSharingDialog,
} from 'modules/classifiers/components/ClassifierActions/ClassifierRefuseSharingDialog'
import { ACTIONS } from 'modules/classifiers/constants'
import { Actions, IClassifierBase } from 'modules/classifiers/models'
import { Button } from 'modules/common/components'
import { ANCHOR_ORIGIN, TRANSFORM_ORIGIN } from 'modules/common/constants'
import { useText } from 'modules/locale'
import React, { FC, MouseEvent, useMemo, useState } from 'react'
import { ChangeOwnerDialog } from './ChangeOwnerDialog'
import { ClassifierDeleteDialog } from './ClassifierDeleteDialog'
import { ClassifierShareDialog } from './ClassifierShareDialog'
import { ClassifierUpdateDialog } from './ClassifierUpdateDialog'

type Props = { classifier: IClassifierBase, actions?: Partial<ActionsEnable> }
type ActionsEnable = { [key in Actions]: boolean }
const initialActions: ActionsEnable = {
  change: true,
  delete: true,
  share: true,
  update: true,
  refuse_sharing: true,
}

export const ClassifierActions: FC<Props> = ({ classifier, actions = initialActions }) => {
  const { enableActions, hidden } = useMemo(() => {
    const enableActions = { ...initialActions, ...actions }
    const hidden = !Object.values(enableActions).find((it) => it === true)

    return { enableActions, hidden }
  }, [actions])
  const { id, name } = classifier
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { text } = useText('CLASSIFIERS')
  const [dialog, setDialog] = useState<Actions | null>(null)

  const handleClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)
  const handleCloseDialog = () => setDialog(null)
  const handleChooseAction = (e: MouseEvent<HTMLLIElement>) => {
    const { id } = e.currentTarget
    setDialog(id as Actions)
    handleCloseMenu()
  }

  if (hidden) {
    return null
  }

  return (
    <>
      <Button
        variant='text'
        id={id}
        size='small'
        color='secondary'
        sx={{ height: 'min-content', mt: '9px' }}
        onClick={handleClick}
      >
        {text.actions}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
        onClose={handleCloseMenu}
      >
        {enableActions.update && (<MenuItem id={ACTIONS.UPDATE} onClick={handleChooseAction}>{text.change}</MenuItem>)}
        {enableActions.share && (<MenuItem id={ACTIONS.SHARE} onClick={handleChooseAction}>{text.share}</MenuItem>)}
        {enableActions.change && (
          <MenuItem id={ACTIONS.CHANGE} onClick={handleChooseAction}>{text.changeOwner}</MenuItem>)}
        {enableActions.delete && (<MenuItem id={ACTIONS.DELETE} onClick={handleChooseAction}>{text.delete}</MenuItem>)}
        {enableActions.refuse_sharing && (
          <MenuItem id={ACTIONS.REFUSE_SHARING} onClick={handleChooseAction}>{text.refuseSharing}</MenuItem>)}
      </Menu>

      <ClassifierUpdateDialog open={dialog === ACTIONS.UPDATE} classifier={classifier} onClose={handleCloseDialog} />
      <ClassifierDeleteDialog open={dialog === ACTIONS.DELETE} id={id} name={name} onClose={handleCloseDialog} />
      <ClassifierShareDialog open={dialog === ACTIONS.SHARE} id={id} name={name} onClose={handleCloseDialog} />
      <ChangeOwnerDialog open={dialog === ACTIONS.CHANGE} id={id} name={name} onClose={handleCloseDialog} />
      <ClassifierRefuseSharingDialog
        open={dialog === ACTIONS.REFUSE_SHARING}
        id={id}
        name={name}
        onClose={handleCloseDialog}
      />
    </>
  )
}
