import { useSignUpMutation } from 'api'
import { BackBtn } from 'modules/auth/components/BackBtn'
import { CodeNotCame } from 'modules/auth/components/CodeNotCame'
import { ResendCode } from 'modules/auth/components/ResendCode'
import { SignUpValues } from 'modules/auth/components/SignUp/models'
import { INITIAL_VALUES } from 'modules/auth/components/SignUp/SignUp/constants'
import { SignUpForm } from 'modules/auth/components/SignUp/SignUpForm'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { useText } from 'modules/locale'
import React, { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { normalizeValues } from './utils'

export const SignUp: FC = () => {
  const [step, setStep] = useState(1)
  const [submitValues, setSubmitValues] = useState<SignUpValues>({} as SignUpValues)
  const [signUp, { isLoading }] = useSignUpMutation()
  const { text } = useText('AUTH')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { text: errorText } = useText('ERRORS')
  const navigate = useNavigate()

  const handleSignUp = useCallback(async (values: SignUpValues) => {
    setSubmitValues(values)
    const response = await signUp(normalizeValues(values))
      .unwrap()
      .catch(err => {
        // пользователь еще не подтвердил почту, поэтому приходит ошибка, но письмо на почту продублировано
        if (err.status === 409) {
          setStep(prev => prev + 1)
          return
        }
        console.error(err)
        const message = err.status === 502 ? errorText.status502 : err.data.message
        enqueueSnackbar(message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
      })
    if (response) {
      setStep(prev => prev + 1)
    }
  }, [closeSnackbar, enqueueSnackbar, errorText.status502, signUp])
  const handleResendCode = useCallback(async () => {
    await handleSignUp(submitValues)
  }, [handleSignUp, submitValues])
  const handleBack = () => navigate(-1)

  if (step === 2) {
    return (
      <>
        <ResendCode
          title={text.closeToTarget}
          description={text.manualForSignUp}
          loading={isLoading}
          onResend={handleResendCode}
        />
        <BackBtn onClick={handleBack}>{text.backToMain}</BackBtn>
      </>
    )
  }

  if (step === 3) {
    return (
      <>
        <CodeNotCame
          title={text.closeToTarget}
          description={text.linkResent}
          questionText={text.emailWithLinkNotCame}
        />
        <BackBtn onClick={handleBack}>{text.backToMain}</BackBtn>
      </>

    )
  }

  return (
    <SignUpForm initialValues={INITIAL_VALUES} onSubmit={handleSignUp} />
  )
}
