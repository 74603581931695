import { Box, Typography } from '@mui/material'
import { useGetUserLicensesQuery } from 'api'
import { CollapsibleTable, IMPulseDownloadButton, TableSkeleton } from 'modules/common/components'
import { ACTION } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { useSnackbar } from 'notistack'
import React, { FC, useEffect, useMemo } from 'react'
import { COLUMNS } from './constants'
import { createRows } from './utils'

export const MyLicenses: FC = () => {
  const { text } = useText('USER')
  const { data, isLoading, error } = useGetUserLicensesQuery()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const rows = useMemo(() => createRows(data), [data])

  useEffect(() => {
    if (error) {
      console.error(error)
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  return (
    <Box p='80px 100px'>
      <Typography variant='h1'>{text.myLicenses}</Typography>
      <Box m='10px 0 20px' width='100%' display='flex' justifyContent='flex-end'>
        <IMPulseDownloadButton />
      </Box>

      <Box mx='-20px'>
        {isLoading
          ? <TableSkeleton columns={4} />
          : <CollapsibleTable rows={rows} columns={COLUMNS} />
        }
      </Box>
    </Box>
  )
}
