import { Box, Dialog, IconButton, styled, Typography } from '@mui/material'
import { useDeleteOrganizationMutation } from 'api'
import { CloseIcon, DeleteIcon } from 'assets/svg'
import { Button } from 'modules/common/components'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import React, { FC, useEffect, useState } from 'react'

type Props = { id: string }

export const CompanyDeleteButton: FC<Props> = ({ id }) => {
  const { text } = useText('USER')
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [deleteOrganization, { isLoading, isSuccess, error }] = useDeleteOrganizationMutation()

  const handleDelete = async () => await deleteOrganization({ id })
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.organizationDeleted, { variant: 'success', action: ACTION(() => closeSnackbar()) })
      handleClose()
    }
  }, [closeSnackbar, enqueueSnackbar, isSuccess, text.organizationDeleted])

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  return (
    <div>
      <IconButton size='small' onClick={handleOpen}><DeleteIcon /></IconButton>

      <Dialog open={open} onClose={isLoading ? undefined : handleClose}>
        <StyledIconButton size='small' disabled={isLoading} onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography variant='h2'>{text.deleteOrganization}</Typography>
        <Typography mt='20px' variant='body1' color='secondary'>{text.assignOrgDeleting}</Typography>

        <Box display='flex' gap='20px' mt='40px'>
          <Button
            fullWidth
            loading={isLoading}
            variant='contained'
            onClick={handleDelete}
          >
            {text.delete}
          </Button>
          <Button autoFocus fullWidth variant='outlined' onClick={handleClose}>{text.cancel}</Button>
        </Box>
      </Dialog>
    </div>
  )
}

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`
