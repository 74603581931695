import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { getError } from 'modules/common/utils'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { useText } from 'modules/locale'
import { useEffect } from 'react'

export const useShowError = (error: FetchBaseQueryError | SerializedError | undefined | string) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { text } = useText('ERRORS')

  useEffect(() => {
    if (error) {
      console.error(error)
      const message = typeof error === 'string' ? error : getError(error).message
      enqueueSnackbar(
        typeof message === 'string' ? message : text.status502,
        { variant: 'error', action: ACTION(() => closeSnackbar()) },
      )
    }
  }, [closeSnackbar, enqueueSnackbar, error, text.status502])
}
