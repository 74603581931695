import { Box, Divider, Skeleton } from '@mui/material'
import { newArr, randomInt } from 'modules/common/utils'
import React, { FC } from 'react'

type Props = { columns?: number, rows?: number, size?: 'small' }

export const TableBodySkeleton: FC<Props> = ({ columns = 2, rows = 2, size }) => {
  return (
    <Box px={size === 'small' ? '5px' : '10px'}>
      {newArr(rows).map(row => {
        return (
          <div key={row}>
            <Box
              display='grid'
              gridTemplateColumns={`repeat(${columns}, 1fr)`}
              alignItems='center'
              height={size === 'small' ? '30px' : '60px'}
            >
              {newArr(columns).map((column) => {
                return (
                  <Skeleton
                    key={column}
                    animation='wave'
                    height={size === 'small' ? 15 : 20}
                    width={randomInt(80, 160)}
                  />
                )
              })}
            </Box>
            <Divider />
          </div>
        )
      })}
    </Box>
  )
}
