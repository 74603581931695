import { IFormDataParams } from 'api/entities/files'

export type ClassifierOwner = 'World' | 'Country' | string

export enum SHARE_STATUS {
  OWNER = 'owner',
  OBSERVER = 'obsrver'
}

export interface IClassifierResponse {
  owner: ClassifierOwner
  country: string | string[],
  name: string
  _id: string
  codes_info_ref: string
  observers: string | string[] // todo Enum
  parent_group: string
  date: string // '06.01.2022',
  version: string
  code_parameter_name: string
  description_parameter_name: string
  user_can_edit: boolean,
  is_shared_to_user: boolean,
  owner_name: string
}

export interface ICreateClassifierInput {
  date: string
  country: string[],
  classifier_tree: { 'parent': string, 'name': string, 'class': string }[],
  name: string
  codes_info_ref: string
  parent_group: string
  code_parameter_name: string
  description_parameter_name: string
  version: string
}

export interface ICreateClassifierResponse extends ICreateClassifierInput {
  'classifier_id': string
}

export interface IUpdateClassifierInputData {
  date: string, // '24.11.2021',
  country: string[]
  code_parameter_name: string
  description_parameter_name: string
  name: string
  codes_info_ref: string
  version: string
  parent_group?: string
}

export interface IUpdateClassifierInput {
  id: string
  body: IUpdateClassifierInputData
}

export interface IClassifierTreeElementRow {
  synonyms: string | null
  parent: string | null
  name: string
  description: string
  attributes: { [key: string]: string } | null,
  class: string
}

export interface IClassifierTreeElement extends IClassifierTreeElementRow {
  parents: string[]
}

export interface ISharedClassifierUserObserver {
  email: string
  identifier: string
  name: string
  phone_number: string
  position: string
  surname: string
}

export interface ISharedClassifierCompanyObserver {
  // 'is_owner': boolean;
  _id: string
  name: string
  countries: string[],
  email: string
  phone: string
}

interface ISharedClassifierOwner {
  'name': string
  'email': string
  'type': 'user' | 'company'
  'identifier': string
}

export interface ISharedClassifierResponse {
  'owner': ISharedClassifierOwner
  is_owner: boolean
  observers:
    {
      'users': ISharedClassifierUserObserver[],
      'companies': ISharedClassifierCompanyObserver[]
    }
}

export interface IShareClassifierInput {
  id: string
  share_to: string
}

export interface IShareToClassifierResponse {
  'identifier': string
  'type': '\'user\' or \'company\'',
  'name': 'user name or company name',
  'email': 'email'
}

export interface IClassifierLinks {
  'download_tree_link': string
  'download_template_tree_link': string
  'upload_tree_link': {
    'url': string,
    'fields': IFormDataParams
  }
}

export interface IClassifierCopyResponse {
  'classifier_id': string
  'user_id': string
}
