import { LOCALE } from 'modules/locale/constants'
import { baseUrl, HTTP_METHODS } from './constants'
import { HttpMethodType, IResponse, RecordType } from './models'

async function request<R extends RecordType>(httpMethod: HttpMethodType, apiMethod: string, body?: string): Promise<R | undefined> {
  const jwt = localStorage.getItem('access')
  const locale = localStorage.getItem('locale') || LOCALE.RUSSIAN
  const headers = jwt
    ? { Authorization: `Bearer ${jwt}`, 'Accept-Language': locale }
    : { 'Accept-Language': locale } as Record<string, string>

  const res = await fetch(baseUrl + apiMethod, {
    method: httpMethod,
    headers: body ? { ...headers, 'Content-Type': 'application/json' } : headers,
    body,
  })

  const json: IResponse<R> | undefined = await res?.json()

  if (!res.ok) {
    const errorText = `${res.statusText}`
    throw Object.assign(new Error(errorText), {
      status: res.status,
      massage: json?.message,
    })
  }

  return json?.scope
}

export async function get<R extends RecordType>(apiMethod: string, params: RecordType = {}) {
  const searchParams = new URLSearchParams(params).toString()
  const queryString = searchParams ? `?${searchParams}` : ''

  return request<R>(HTTP_METHODS.GET, `${apiMethod}${queryString}`)
}

export async function post<R extends RecordType>(apiMethod: string, params: RecordType = {}) {
  return request<R>(HTTP_METHODS.POST, apiMethod, JSON.stringify(params))
}

export async function patch<R extends RecordType>(apiMethod: string, params: RecordType = {}) {
  return request<R>(HTTP_METHODS.PATCH, apiMethod, JSON.stringify(params))
}

export async function put<R extends RecordType>(apiMethod: string, params: RecordType = {}) {
  return request<R>(HTTP_METHODS.PUT, apiMethod, JSON.stringify(params))
}

export async function del<R extends RecordType>(apiMethod: string, contentID: string) {
  return request<R>(HTTP_METHODS.DELETE, `${apiMethod}/${contentID}`)
}

