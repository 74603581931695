import { LicenseTypeResponse } from 'api'
import { LICENSE_TAG, LicenseType } from 'modules/app/models'

export const normalizeLicenseTypes = (data: { license_types: LicenseTypeResponse[] }): LicenseType[] => {
  const tag = (code: string) => {
    if (code.slice(-4) === 'Comm') {
      return LICENSE_TAG.COMM
    }

    return LICENSE_TAG.EVAL
  }

  return data.license_types.map(it => ({
    licenseName: it.license_name,
    product: it.product,
    productCode: it.product_code,
    tag: tag(it.product_code),
  }))
}
