import { Box, Typography } from '@mui/material'
import { useSendFeedbackMutation } from 'api'
import { ACTION, useSnackbar } from 'modules/errorHandler/Notistack'
import { getError } from 'modules/common/utils'
import { useText } from 'modules/locale'
import { ContactsData } from 'pages/contacts/ContactsData'
import { ContactsForm } from 'pages/contacts/ContactsForm'
import React, { FC, useEffect, useMemo } from 'react'

export const Contacts: FC = () => {
  const { text } = useText('USER')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const contacts = useMemo(() => text.contacts ? text.contacts[0].toUpperCase() + text.contacts.slice(1) : '', [text.contacts])
  const [sendFeedback, { isLoading, error, isSuccess }] = useSendFeedbackMutation()

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getError(error).message, { variant: 'error', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, error])

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(text.feedbackSent, { variant: 'success', action: ACTION(() => closeSnackbar()) })
    }
  }, [closeSnackbar, enqueueSnackbar, isSuccess, text.feedbackSent])

  return (
    <Box p='80px 100px'>
      <Typography variant='h1'>{contacts}</Typography>
      <Typography m='20px 0 40px' variant='subtitle1'>{isSuccess ? text.feedbackSent : text.beOnContact}</Typography>

      <Box
        display='grid'
        gridTemplateColumns='590px 1fr'
        gap='120px'
        gridAutoRows='min-content'
        alignItems='flex-start'
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ContactsForm isLoading={isLoading} isSuccess={isSuccess} onSubmit={sendFeedback} />
        <ContactsData />
      </Box>

    </Box>
  )
}
