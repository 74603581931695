import {
  Box,
  ClickAwayListener,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import { IClassifierTreeElement } from 'api'
import { ArrowDownIcon, ArrowUpIcon, InfoIcon } from 'assets/svg'
import { closeTreeItem, selectClassifiers, setTreeItems } from 'modules/classifiers/slice'
import { normalizeClassifierTreeElement } from 'modules/classifiers/utils'
import { TableBodySkeleton } from 'modules/common/components'
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { TooltipDescription } from './TooltipDescription'

type Props = { data: IClassifierTreeElement[] | undefined, isLoading: boolean, id: string }

export type ElementRowProps = {
  element: IClassifierTreeElement,
  rows: ElementRowProps[] | null,
  isLast: boolean
}

export const ClassifierTreeTable: FC<Props> = ({ data, isLoading, id }) => {
  const [tree, setTree] = useState<ElementRowProps[] | null>(null)
  useEffect(() => {
    const state = data ? normalizeClassifierTreeElement(data, null, [id]) : []
    setTree(state)
  }, [data, id])

  if (isLoading || !tree) {
    return <TableBodySkeleton rows={2} columns={3} size='small' />
  }

  return (
    <Table size='small'>
      <TableBody>
        {tree.map((it, index, arr) => (
          <ElementRow
            key={it.element.name}
            element={it.element}
            rows={it.rows}
            isLast={index === arr.length - 1}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const ElementRow: FC<ElementRowProps> = memo(({ element, rows, isLast }) => {
  const [tooltip, setTooltip] = useState(false)
  const { openedTableItems } = useSelector(selectClassifiers)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const more = useMemo(() => {
    return !!element.attributes || !!element.description
  }, [element.attributes, element.description])

  const handleClick = useCallback(() => {
    if (!rows?.length) {
      return
    }
    const searchStr = searchParams.get('table_search')
    if (searchStr) {
      searchParams.delete('table_search')
      navigate({ pathname, search: searchParams.toString() })
    }

    if (openedTableItems[element.class]) {
      dispatch(closeTreeItem(element.class))
      return
    }
    dispatch(setTreeItems([element.class, ...element.parents]))
  }, [dispatch, element.class, element.parents, navigate, openedTableItems, pathname, rows?.length, searchParams])

  const handleTooltipClose = () => setTooltip(false)
  const handleTooltipOpen = () => setTooltip(true)

  const cellProps = useMemo(() => ({ sx: (!isLast && !openedTableItems[element.class]) ? { borderBottom: 'none' } : {} }), [element.class, isLast, openedTableItems])
  const selected = useMemo(() => searchParams.get('table_search') === element.class, [element.class, searchParams])

  return (
    <>
      <TableRow selected={selected} hover sx={{ cursor: 'pointer' }} id={element.class}>
        <TableCell sx={{ ...cellProps.sx, minWidth: `130px` }} onClick={handleClick}>
          {element.class}
        </TableCell>
        <TableCell sx={{ ...cellProps.sx, width: '100%' }} onClick={handleClick}>
          {element.name}
        </TableCell>

        <TableCell sx={{ ...cellProps.sx, minWidth: '150px' }}>
          <Box display='grid' gridTemplateColumns='24px 24px' gap='12px' justifyContent='flex-end'>
            {!more ? <div />
              : (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      PopperProps={{ disablePortal: true }}
                      open={tooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={<TooltipDescription description={element.description} attributes={element.attributes} />}
                      onClose={handleTooltipClose}
                    >
                      <IconButton sx={{ p: 0 }} size='small' onClick={handleTooltipOpen}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}

            {!rows?.length ? <div /> : (
              <IconButton sx={{ p: 0 }} size='small' onClick={handleClick}>
                {openedTableItems[element.class] ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </IconButton>
            )}
          </Box>

        </TableCell>
      </TableRow>

      {(!rows?.length)
        ? null
        : (
          <TableRow>
            <TableCell colSpan={4} padding='none' sx={{ borderBottom: 'none' }}>
              <Collapse in={openedTableItems[element.class]}>
                {!openedTableItems[element.class] ? null : (
                  <Table size='small'>
                    <TableBody>
                      {rows?.map((it, index, arr) => (
                        <ElementRow
                          key={it.element.class}
                          element={it.element}
                          rows={it.rows}
                          isLast={index === arr.length - 1}
                        />
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }
    </>
  )
})
