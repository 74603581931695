import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api/api-config'
import { HTTP_METHODS } from 'api/constants'
import { IContractShortResponse, IContractsListResponse, ILicensesStatisticResponse } from 'api/entities/contracts'
import { EMPTY_PARAMS } from 'modules/organization/constants'
import {
  IAddContractInput,
  IAddLicensesInput,
  IAddLicensesResponse,
  IAttachLicensesInput,
  IChangeRoleData,
  IChangeRoleInOrgData,
  ICreateOrgInput,
  IDeleteLicensesInput,
  IDeleteLicensesResponse,
  IEditContractInput,
  IOrganizationByIdResponse,
  IOrgListResponse,
  IPortalStats,
  IUpdateOrgInfoInput,
  IUsersListResponse,
  SearchParams,
  UsersSearchParams,
} from './models'

// todo удалить any
// todo надо переименовать методы, думаю с префиксом admin

export const adminQuery = createApi({
  reducerPath: 'adminQuery',
  baseQuery,
  tagTypes: ['Company', 'User', 'Contract', 'companyProfile', 'Statistic', 'companyLicenses'],
  endpoints: (build) => ({
    getCompanies: build.query<IOrgListResponse, SearchParams | void>({
      query: (params) => ({
        url: 'admin/companies',
        params: params ? { ...EMPTY_PARAMS, ...params } : { ...EMPTY_PARAMS },
      }),
      transformResponse: (response: { scope: IOrgListResponse }) => response.scope,
      providesTags: ['Company'],
    }),
    getPortalStats: build.query<IPortalStats, void>({
      query: () => ({ url: 'admin/stats/portal' }),
      transformResponse: (response: { scope: IPortalStats }) => response.scope,
      providesTags: ['Statistic'],
    }),
    getCompanyById: build.query<IOrganizationByIdResponse, { id: string }>({
      query: ({ id }) => ({ url: 'admin/company/profile', params: { company_id: id } }),
      transformResponse: (response: { scope: IOrganizationByIdResponse }) => response.scope,
      providesTags: ['companyProfile'],
    }),
    getCompanyLicensesStats: build.query<ILicensesStatisticResponse, { id: string }>({
      query: ({ id }) => ({ url: 'admin/company/licenses/stats', params: { company_id: id } }),
      transformResponse: (response: { scope: ILicensesStatisticResponse }) => response.scope,
      providesTags: ['companyLicenses'],
    }),
    getUsers: build.query<IUsersListResponse, UsersSearchParams | void>({
      query: (params) => ({
        url: 'admin/users',
        params: params ? { ...EMPTY_PARAMS, ...params } : { ...EMPTY_PARAMS },
      }),
      transformResponse: (response: { scope: IUsersListResponse }) => response.scope,
      providesTags: ['User'],
    }),

    updateOrgInfo: build.mutation<any, IUpdateOrgInfoInput>({
      query: (body) => ({ url: 'admin/company/profile', method: HTTP_METHODS.PUT, body }),
      invalidatesTags: ['companyProfile'],
    }),
    changeUserPortalRole: build.mutation<IChangeRoleData, IChangeRoleData>({
      query: (body) => ({ url: 'admin/users', method: HTTP_METHODS.PUT, body }),
      invalidatesTags: ['User'],
    }),
    changeUserOrgRole: build.mutation<IChangeRoleData, IChangeRoleInOrgData>({
      query: ({ company_id, ...body }) => ({
        url: 'admin/company/users',
        method: HTTP_METHODS.PUT,
        params: { company_id },
        body,
      }),
      invalidatesTags: ['User'],
    }),

    inviteUserFromAdmin: build.mutation<any, { email: string }>({
      query: ({ email }) => ({ url: 'admin/users', method: HTTP_METHODS.POST, body: { email_login: email } }),
    }),
    deleteOrganization: build.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: 'admin/company/profile',
        method: HTTP_METHODS.DELETE,
        params: { company_id: id },
      }),
      invalidatesTags: ['Company', 'Statistic'],
    }),
    createOrganization: build.mutation<any, ICreateOrgInput>({
      query: (body) => ({
        url: 'admin/company/profile',
        method: HTTP_METHODS.POST,
        body,
      }),
      invalidatesTags: ['Company', 'Statistic'],
    }),

    deleteUser: build.mutation<string, { id: string }>({
      query: ({ id }) => ({
        url: 'admin/users',
        method: HTTP_METHODS.DELETE,
        params: { user_id: id },
      }),
      invalidatesTags: ['User', 'Statistic'],
    }),

    deleteUserFromOrg: build.mutation<string, { id: string, companyId: string }>({
      query: ({ id, companyId }) => ({
        url: 'admin/company/users',
        method: HTTP_METHODS.DELETE,
        params: { company_id: companyId, user_id: id },
      }),
      invalidatesTags: ['User', 'Statistic'],
    }),

    // Контракты
    getContracts: build.query<IContractsListResponse, UsersSearchParams | void>({
      query: (params) => ({
        url: 'admin/contracts',
        params: params ? { ...EMPTY_PARAMS, ...params } : { ...EMPTY_PARAMS },
      }),
      transformResponse: (response: { scope: IContractsListResponse }) => response.scope,
      providesTags: ['Contract'],
      keepUnusedDataFor: 10,
    }),

    getOrganizationContracts: build.query<IContractShortResponse[], { id: string }>({
      query: ({ id }) => ({
        url: '/admin/company/licenses',
        params: { company_id: id },
      }),
      transformResponse: (response: { scope: IContractShortResponse[] }) => response.scope,
      providesTags: ['Contract'],
    }),

    deleteContract: build.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: 'admin/contracts',
        method: HTTP_METHODS.DELETE,
        params: { contract_id: id },
      }),
      invalidatesTags: ['Contract', 'Statistic', 'companyLicenses'],
    }),
    addContract: build.mutation<any, IAddContractInput>({
      query: (body) => ({
        url: 'admin/contracts',
        method: HTTP_METHODS.POST,
        body,
      }),
      invalidatesTags: ['Contract', 'Statistic', 'companyLicenses'],
    }),
    editContract: build.mutation<{ contract_id: string }, IEditContractInput>({
      query: (body) => ({
        url: 'admin/contracts',
        method: HTTP_METHODS.PUT,
        body,
      }),
      invalidatesTags: ['Contract'],
    }),

    attachLicense: build.mutation<any, IAttachLicensesInput>({
      query: ({ userId, licenseId, companyId }) => ({
        url: '/admin/company/licenses',
        method: HTTP_METHODS.PATCH,
        params: {
          user_id: userId,
          license_id: licenseId,
          company_id: companyId,
        },
      }),
      invalidatesTags: ['Contract', 'Statistic', 'companyLicenses'],
    }),
    deleteLicense: build.mutation<IDeleteLicensesResponse, IDeleteLicensesInput>({
      query: ({ contractId, ids }) => ({
        url: 'admin/contracts/licenses',
        method: HTTP_METHODS.DELETE,
        body: { license_ids: ids },
        params: { contract_id: contractId },
      }),
      invalidatesTags: ['Contract', 'Statistic', 'companyLicenses'],
    }),
    addLicense: build.mutation<IAddLicensesResponse, IAddLicensesInput>({
      query: (body) => ({
        url: 'admin/contracts/licenses',
        method: HTTP_METHODS.POST,
        body,
      }),
      invalidatesTags: ['Contract', 'Statistic', 'companyLicenses'],
    }),
  }),
})

export const {
  useGetOrganizationContractsQuery, useLazyGetOrganizationContractsQuery,
  useAddContractMutation,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useUpdateOrgInfoMutation,
  useDeleteOrganizationMutation,
  useCreateOrganizationMutation,
  useDeleteUserMutation,
  useDeleteContractMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useInviteUserFromAdminMutation,
  useGetContractsQuery,
  useLazyGetContractsQuery,
  useDeleteLicenseMutation,
  useAddLicenseMutation,
  useEditContractMutation,
  useGetCompanyByIdQuery,
  useGetCompanyLicensesStatsQuery,
  useChangeUserOrgRoleMutation, useChangeUserPortalRoleMutation, useDeleteUserFromOrgMutation, useAttachLicenseMutation,
  useGetPortalStatsQuery, useLazyGetCompanyByIdQuery, useLazyGetCompanyLicensesStatsQuery, useLazyGetPortalStatsQuery,
} = adminQuery
